import {
  organisationCreateTestUrl,
  organisationHomeUrl,
  organisationOptimalRangeCreateUrl,
  organisationOptimalRangeUrl,
  organisationOptimalRangesListUrl,
  organisationReferralsUrl,
  organisationResultUrl,
  organisationResultsUrl,
  organisationSettingsUrl,
  organisationTestBundleUrl,
  organisationTestBundlesUrl,
  routes,
} from '../../lib/routes'

import {
  AdjustmentsHorizontalIcon,
  BeakerIcon,
  ClipboardDocumentIcon,
  Cog6ToothIcon,
  HomeIcon,
  InboxArrowDownIcon,
} from '@heroicons/react/24/solid'
import { MenuItem } from './MenuItems'
import { Pill } from '../ui/Pill'
import posthog from '../../lib/posthog'

// export interface MenuItem {
//   name: string | ReactNode
//   href: string
//   target?: string
//   icon: React.ForwardRefExoticComponent<
//     Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
//       title?: string | undefined
//       titleId?: string | undefined
//     } & React.RefAttributes<SVGSVGElement>
//   >
//   current: boolean
// }

// export interface MenuItemGroup {
//   header: string | ReactNode
//   items: MenuItem[]
// }

export function organisationSettingsMenuItems(
  currentPage: string,
  urlPrefix: string
): MenuItem[] {
  return [
    {
      name: 'Tests',
      href: `${urlPrefix}/bundles`,
      icon: BeakerIcon,
      current:
        [
          routes[organisationTestBundlesUrl].name,
          routes[organisationTestBundleUrl].name,
          routes[organisationCreateTestUrl].name,
        ].indexOf(currentPage) !== -1, // TODO THis is a hack, we should fix this.
      // currentPage === (routes[testsUrl].name || routes[resultUrl].name),
    },
    ...(posthog.isFeatureEnabled('can-manage-optimal-ranges')
      ? [
          {
            name: (
              <span className="flex items-center gap-x-2">
                Optimal Ranges
                <Pill className="bg-blood/80 text-white ring-blood/80 px-2 py-0.5 text-xs font-semibold leading-none">
                  Beta
                </Pill>
              </span>
            ),
            href: `${urlPrefix}/optimal-ranges`,
            icon: AdjustmentsHorizontalIcon,
            current:
              [
                routes[organisationOptimalRangeUrl].name,
                routes[organisationOptimalRangeCreateUrl].name,
                routes[organisationOptimalRangesListUrl].name,
              ].indexOf(currentPage) !== -1,
          },
        ]
      : []),
    {
      name: 'Settings',
      href: `${urlPrefix}/settings`,
      icon: Cog6ToothIcon,
      current:
        [routes[organisationSettingsUrl].name].indexOf(currentPage) !== -1, // TODO THis is a hack, we should fix this.
      // currentPage === (routes[testsUrl].name || routes[resultUrl].name),
    },
  ]
}

export function organisationMenuItems(
  currentPage: string,
  urlPrefix: string
): MenuItem[] {
  return [
    {
      name: 'Dashboard',
      href: urlPrefix,
      icon: HomeIcon,
      current: currentPage === routes[organisationHomeUrl].name,
    },
  ]
}

export function organisationClientMenuItems(
  currentPage: string,
  urlPrefix: string
): MenuItem[] {
  return [
    {
      name: 'Results',
      href: `${urlPrefix}/results`,
      icon: ClipboardDocumentIcon,
      current:
        [
          routes[organisationResultsUrl].name,
          routes[organisationResultUrl].name,
        ].indexOf(currentPage) !== -1,
    },
    {
      name: 'Referrals',
      href: `${urlPrefix}/referrals`,
      icon: InboxArrowDownIcon,
      current:
        [
          routes[organisationReferralsUrl].name,
          routes[organisationReferralsUrl].name,
        ].indexOf(currentPage) !== -1,
    },
  ]
}
