import { useState } from 'react'
import { AIButton } from '../form/button/AIButton'
import RoundedSection from '../layout/RoundedSection'
import { ResultAISummaryConsentModal } from './ResultAISummaryConsentModal'
import { consentToResultAiSummary } from '../../lib/consent'
import { generateResultAiSummary } from '../../lib/insights'
import toast from 'react-hot-toast'
import BooleanFeedback from '../feedback/BooleanFeedback'
import { useUserFeedback } from '../../lib/feedback'

import { ThemeInterface } from '../../lib/interfaces'

const feature = 'result-ai-summary'

const ResultFeedback: React.FC = () => {
  const { hasFeedback, submitFeedback } = useUserFeedback()

  const device = {
    source: window.location.href,
    agent: navigator.userAgent,
  }

  const handleOnFeedback = async (value: boolean): Promise<void> => {
    try {
      await submitFeedback({
        ...device,
        feature,
        feedback: {
          type: 'boolean',
          value: value,
        },
      })
      toast.success('Thank you for your feedback')
    } catch (error) {
      toast.error('Failed to submit feedback')
    }
  }

  // Ask for feedback once per result regardless of device.
  const isFeedbackGiven = hasFeedback({
    feature,
    source: device.source,
  })

  return (
    <>
      {!isFeedbackGiven && (
        <div className="flex items-center justify-between w-full mt-4 md:mt-0">
          <span className="text-right flex-grow flex items-center justify-end h-full">
            Was this summary helpful?
          </span>
          <BooleanFeedback onFeedback={handleOnFeedback} />
        </div>
      )}
    </>
  )
}

interface ResultAISummarySectionProps {
  enableGenerateButton?: boolean
  resultUuid: string
  resultAiSummaryConsentUuid?: string
  resultAiSummary?: string
  theme?: ThemeInterface
}

export const ResultAISummarySection: React.FC<ResultAISummarySectionProps> = ({
  enableGenerateButton = false,
  resultUuid,
  resultAiSummaryConsentUuid,
  resultAiSummary,
  theme,
}) => {
  const [isConsentModalOpen, setIsConsentModelOpen] = useState(false)
  const [consentUuid, setConsentUuid] = useState<string | undefined>(
    resultAiSummaryConsentUuid
  )
  const [summary, setSummary] = useState<string | undefined>(resultAiSummary)
  const [isLoading, setIsLoading] = useState(false)

  const enableFeedback = enableGenerateButton

  const generateSummary = async () => {
    setIsLoading(true)
    const toastId = toast.loading('Generating AI summary')
    try {
      const aiSummary = await generateResultAiSummary(resultUuid)
      setSummary(aiSummary.summary)
    } catch (error) {
      console.error('Error generating summary:', error)
    } finally {
      setIsLoading(false)
      toast.success('Generation complete', {
        id: toastId,
        duration: 1000,
      })
    }
  }

  const handleConsent = async (signature: string) => {
    try {
      setIsLoading(true)
      const consent = await consentToResultAiSummary({
        resultUuid,
        encodedSignature: signature,
      })
      if (consent.uuid) {
        // We have consent, close modal and immediately generate summary
        setConsentUuid(consent.uuid)
        setIsConsentModelOpen(false)
        await generateSummary()
      }
    } catch (error) {
      console.error('Error signing consent:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleExplainWithAi = async () => {
    // We need consent first
    if (!consentUuid) {
      setIsConsentModelOpen(true)
      return
    }

    // Allow for generating a new summary even if we already have one
    await generateSummary()
  }

  return (
    <>
      {enableGenerateButton && (
        <div className="mb-2 print:hidden">
          <AIButton
            onClick={() => handleExplainWithAi()}
            disabled={isLoading}
            label={summary ? 'Explain again with AI' : 'Explain with AI'}
            loading={isLoading}
            theme={theme}
          />
        </div>
      )}
      {consentUuid && summary && (
        <RoundedSection size="lg" className="mb-8">
          <div>
            <p>{summary}</p>
            {enableFeedback && <ResultFeedback />}
          </div>
        </RoundedSection>
      )}

      {isConsentModalOpen && (
        <ResultAISummaryConsentModal
          onClose={() => setIsConsentModelOpen(false)}
          onConsent={(signatureData) => handleConsent(signatureData)}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default ResultAISummarySection
