import { z } from 'zod'
import { authFetch } from '../providers/AuthProvider'
import {
  BiomarkerItem,
  BiomarkerListSchema,
  PathologyProvider,
  PathologyProviderListSchema,
  ReferralSchema,
  ReferralStatusSchemaList,
  ReferralStatusType,
  UserSlim,
  UserSlimSchema,
} from './validators'
import { ReferralStatus } from './types'
import { useState, useEffect, useMemo } from 'react'

export const getIssuedReferrals = async (): Promise<ReferralStatusType[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          referralsByStatus(status:["ISSUED", "PARTIAL_RESULTS"]) {
            uuid
            status
            reference
            profileUuid
            organisationUuid
            testName
            customerName
            createdAt
            resultUuid
          }
        }`,
    }),
  })
  const data = result.data
  console.log('the data is', data.data)
  const parsedData = ReferralStatusSchemaList.parse(data.data.referralsByStatus)
  console.log(parsedData)
  return parsedData
}

export const getPathologyProviders = async (): Promise<PathologyProvider[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          pathologyProviders {
            id
            name
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = PathologyProviderListSchema.parse(
    data.data.pathologyProviders
  )
  return parsedData
}

export const usePathologyProviders = (): [PathologyProvider[], boolean] => {
  const [pathologyProviders, setPathologyProviders] = useState<
    PathologyProvider[]
  >([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      const providers = await getPathologyProviders()
      setPathologyProviders(providers)
      setLoading(false)
    }

    fetchData()
  }, [])

  const memoizedProviders = useMemo(
    () => pathologyProviders,
    [pathologyProviders]
  )

  return [memoizedProviders, loading]
}

export const biomarkerItemFields = `
            id
            name
            commonName
            suffix
            prefix
            unit
            refIntervalHigh
            refIntervalLow
            description
            refIntervalHighNotes
            refIntervalLowNotes
            refIntervalMidNotes`

export const getBiomarkersForReferralUuid = async (
  referralUuid: string
): Promise<BiomarkerItem[]> => {
  // setTimeout(async () => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          biomarkersForReferral(referralUuid:"${referralUuid}") {
            ${biomarkerItemFields}
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = BiomarkerListSchema.parse(data.data.biomarkersForReferral)
  return parsedData
}

export enum ResultStatusTypes {
  normal,
  abnormal,
}

export const getResultStatus = (
  value?: string,
  refLow?: string,
  refHigh?: string
): ResultStatusTypes => {
  if (refHigh !== undefined && refLow !== undefined && value !== undefined) {
    if (
      parseFloat(value) > parseFloat(refHigh) ||
      parseFloat(value) < parseFloat(refLow)
    ) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }

  if (refHigh !== undefined && refLow === undefined && value !== undefined) {
    if (parseFloat(value) > parseFloat(refHigh)) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }

  if (refHigh === undefined && refLow !== undefined && value !== undefined) {
    if (parseFloat(value) < parseFloat(refLow)) {
      return ResultStatusTypes.abnormal
    }
    return ResultStatusTypes.normal
  }
  return ResultStatusTypes.normal
}

export const SearchUsersResponseSchema = z.array(UserSlimSchema)

export const searchUsers = async (
  searchTerm: string
): Promise<Array<UserSlim>> => {
  if (searchTerm.length < 2) {
    // Adding a minimum length constraint to prevent overly broad searches,
    // reducing load and improving the relevance of results.
    return []
  }

  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          searchUsers(input: {
            searchTerm: "${searchTerm}"
          }) {
            uuid,
            firstName,
            lastName,
            email,
          }
        }`,
    }),
  })

  const users = SearchUsersResponseSchema.parse(response.data.data.searchUsers)

  return users
}

const ReferralForTransferSchema = ReferralSchema.pick({
  uuid: true,
  status: true,
  testName: true,
  reference: true,
  customerName: true,
  organisationUuid: true,
})
const ReferralsForTransferSchema = z.array(ReferralForTransferSchema)

export type ReferralForTransfer = z.infer<typeof ReferralForTransferSchema>

export const getReferralsForTransfer = async (
  userUuid: string,
  statuses?: string[]
): Promise<ReferralForTransfer[]> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        query referralsForTransfer($userUuid: UUID!, $statuses: [String!]) {
          adminReferrals(input: {
              userUuid: $userUuid,
              statuses: $statuses
          }) {
              uuid
              organisationUuid
              testName
              status
              customerName
              reference
          }
        }`,
      variables: {
        userUuid,
        statuses,
      },
    }),
  })

  return ReferralsForTransferSchema.parse(response.data.data.adminReferrals)
}

export const validTransferStatuses = [
  ReferralStatus.Created,
  ReferralStatus.Issued,
  ReferralStatus.PendingVerification,
]

interface TransferReferralsInput {
  recipientFirstName: string
  recipientEmail: string
  referrals: string[]
  fromUserUuid: string
}

const TransferredReferralsResponseSchema = z.object({
  transferredReferrals: z.array(
    z.object({
      uuid: z.string().uuid(),
      reference: z.string(),
    })
  ),
  totalTransferred: z.number(),
})

export type TransferredReferralsResponse = z.infer<
  typeof TransferredReferralsResponseSchema
>

export const transferReferrals = async ({
  recipientFirstName,
  recipientEmail,
  referrals,
  fromUserUuid,
}: TransferReferralsInput): Promise<TransferredReferralsResponse> => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        mutation transferReferrals($recipientFirstName: String!, $recipientEmail: String!, $referrals: [UUID!]!, $fromUserUuid: UUID!) {
          adminTransferReferrals(input: {
              recipientFirstName: $recipientFirstName,
              recipientEmail: $recipientEmail
              referrals: $referrals
              fromUserUuid: $fromUserUuid
          }) {
              transferredReferrals {
                uuid
                reference
              }
              totalTransferred
          }
        }`,
      variables: {
        recipientFirstName,
        recipientEmail,
        referrals,
        fromUserUuid,
      },
    }),
  })

  return TransferredReferralsResponseSchema.parse(
    response.data.data.adminTransferReferrals
  )
}
