import { Fragment, ReactNode } from 'react'
import TextInputField, {
  TextInputFieldProps,
  onChangeInterface,
} from './TextInputField'

interface CheckboxProps extends TextInputFieldProps {
  label?: string
  id: string
  name: string
  className?: string
  placeholder?: string
  onChange?: (data: onChangeInterface) => void
  checkboxLabel?: ReactNode
  checked?: boolean
}

export default function Checkbox({
  id,
  label,
  name,
  onChange,
  checkboxLabel,
  type = 'checkbox',
  readonly = false,
  disabled = false,
  className,
  checked = false,
}: CheckboxProps) {
  const checkboxItem = () => (
    <TextInputField
      id={id}
      label={label}
      name={name}
      type={type}
      disabled={disabled}
      handleChange={onChange}
      shouldBeFullWidth={false}
      readonly={readonly}
      defaultChecked={checked}
      className={`checked:bg-jade focus:checked:bg-jade hover:checked:bg-jade ${className} cursor-pointer`}
    ></TextInputField>
  )

  return (
    <Fragment>
      {checkboxItem()}
      {checkboxLabel && (
        <label
          htmlFor={name}
          className={`text-md  pl-2  dark:text-white ${disabled ? 'text-gray-dark' : 'text-gray-900 cursor-pointer'}`}
        >
          {checkboxLabel}
        </label>
      )}
    </Fragment>
  )
  // return (
  //   <Fragment>
  //     {checkboxItem()}
  //     {checkboxLabel && (
  //       <label
  //         htmlFor={name}
  //         className="text-md cursor-pointer text-gray-900 dark:text-white"
  //       >
  //         {checkboxLabel}
  //       </label>
  //     )}
  //   </Fragment>
  // )
}

/*

  const checkboxItem = () => (
    <TextInputField
      id={id}
      label={label}
      name={name}
      type={type}
      disabled={disabled}
      handleChange={onChange}
      shouldBeFullWidth={false}
      readonly={readonly}
      defaultChecked={checked}
      className={`checked:bg-jade focus:checked:bg-jade hover:checked:bg-jade ${className} cursor-pointer`}
    ></TextInputField>
  )
  return (
    <Fragment>
      {!checkboxLabel && <>{checkboxItem()}</>}
      {checkboxLabel && (
        <div className="flex">
          <div className="content-start pr-2 text-right">{checkboxItem()}</div>
          <div className="text-left">
            <label
              htmlFor={name}
              className="text-md cursor-pointer text-gray-900 dark:text-white"
            >
              {checkboxLabel}
            </label>
          </div>
        </div>
      )}
    </Fragment>
  )
    */
