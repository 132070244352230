import { Fragment } from 'react'

import { getResultStatus, ResultStatusTypes } from '../../../../lib/admin'
import { Biomarker, GroupedBiomarkers } from '../../../../lib/validators'
import CopyToClipboard from 'react-copy-to-clipboard'
import toast from 'react-hot-toast'
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline'
import { ThemeInterface } from '../../../../lib/interfaces'
import { Button } from '../../../form/button/Button'

interface SimpleBiomarkerViewProps {
  groupedBiomarkers: GroupedBiomarkers
  theme?: ThemeInterface
}

const isAbnormal = (biomarker: Biomarker): boolean => {
  return (
    getResultStatus(
      biomarker.value.toString(),
      biomarker.refIntervalLow?.toString(),
      biomarker.refIntervalHigh?.toString()
    ) === ResultStatusTypes.abnormal
  )
}

const getCSVData = (groupedBiomarkers: GroupedBiomarkers): string[] => {
  const csvRows: string[] = [
    [
      'Category',
      'Biomarker Name',
      'Value',
      'Abnormal',
      'Unit',
      'Reference Range Low',
      'Reference Range High',
    ].join('\t'),
  ]
  groupedBiomarkers.map((biomarkerGroup) => {
    biomarkerGroup.biomarkers?.map((biomarker) => {
      const col = []
      col.push(biomarkerGroup.category.name)
      col.push(biomarker.name)
      col.push(biomarker.value)
      col.push(isAbnormal(biomarker))
      col.push(biomarker.unit || '')
      col.push(biomarker.refIntervalLow || '')
      col.push(biomarker.refIntervalHigh || '')
      csvRows.push(col.join('\t'))
    })
  })
  return csvRows
}

const displayBiomarkerRange = (biomarker: Biomarker): string => {
  if (biomarker.refIntervalLow && biomarker.refIntervalHigh) {
    return `${biomarker.refIntervalLow} - ${biomarker.refIntervalHigh}`
  } else if (biomarker.refIntervalLow !== null) {
    return `> ${biomarker.refIntervalLow}`
  } else if (biomarker.refIntervalHigh !== null) {
    return `< ${biomarker.refIntervalHigh}`
  }
  return ''
}

export function SimpleBiomarkerView({
  groupedBiomarkers,
  theme,
}: SimpleBiomarkerViewProps) {
  return (
    <Fragment>
      <div className="text-right mb-4">
        <CopyToClipboard
          options={{ format: 'text/plain' }}
          text={getCSVData(groupedBiomarkers).join('\r\n')}
          onCopy={() =>
            toast.success('CSV Results Copied to clipboard', {
              duration: 3000,
            })
          }
        >
          <Button>
            <ClipboardDocumentListIcon
              width={20}
              title="Copy to clipboard"
              className="inline cursor-pointer ml-2 text-[--active-color] hover:text-[--active-hover-color]"
              style={
                theme
                  ? ({
                      '--active-color': theme.linkColor,
                      '--active-hover-color': theme.linkHoverColor,
                    } as React.CSSProperties)
                  : {}
              }
            ></ClipboardDocumentListIcon>{' '}
            Copy results as CSV to clipboard
          </Button>
        </CopyToClipboard>
      </div>
      <div className="grid grid-cols-6 sm:grid-cols-12">
        <div
          key={`biomarker-name-simple`}
          className={`col-span-6 sm:col-span-7 font-mono font-bold hidden sm:inline-block`}
        ></div>
        <div
          key={`biomarker-value-simple`}
          className={`col-span-2 sm:col-span-1 font-mono sm:text-center font-bold hidden sm:inline-block`}
        >
          Value
        </div>
        <div
          key={`biomarker-unit-simple`}
          className="col-span-2 sm:col-span-2 font-mono text-center font-bold hidden sm:inline-block"
        >
          Unit
        </div>
        <div
          key={`biomarker-range-simple`}
          className="col-span-2 sm:col-span-2 font-mono text-center font-bold hidden sm:inline-block"
        >
          Ranges
        </div>
        {groupedBiomarkers &&
          groupedBiomarkers.map((group) => (
            <Fragment key={group.category.id}>
              <div
                key={`${group.category.id}-category-simple`}
                className={`col-span-6 sm:col-span-12 font-mono font-bold mt-2`}
              >
                {group.category.name}
              </div>
              {group.biomarkers.map((biomarker) => (
                <Fragment key={`${biomarker.id}-container-simple`}>
                  <div
                    key={`${biomarker.id}-name-simple`}
                    className={`col-span-6 sm:col-span-7 font-mono ${isAbnormal(biomarker) ? 'text-blood font-bold' : ''}`}
                  >
                    {biomarker.name}
                  </div>
                  <div
                    key={`${biomarker.id}-value-simple`}
                    className={`col-span-2 sm:col-span-1 font-mono sm:bg-gray-light sm:dark:!bg-dark-gray-lighterer sm:text-center ${isAbnormal(biomarker) ? 'text-blood font-bold' : ''}`}
                  >
                    {biomarker.value}
                    <span className="inline-block">
                      {isAbnormal(biomarker) ? '*' : ''}
                    </span>
                  </div>

                  <div
                    key={`${biomarker.id}-unit-simple`}
                    className="col-span-2 sm:col-span-2 font-mono text-center"
                  >
                    {biomarker.prefix && biomarker.unit}
                    {biomarker.suffix && biomarker.unit}
                  </div>
                  <div
                    key={`${biomarker.id}-range-simple`}
                    className="col-span-2 sm:col-span-2 font-mono text-center"
                  >
                    {displayBiomarkerRange(biomarker)}
                  </div>
                </Fragment>
              ))}
            </Fragment>
          ))}
      </div>
    </Fragment>
  )
}
