import { Fragment, useState, useEffect } from 'react'
import { setDocumentTitle } from '../../../../lib/utils'
import PageLoader from '../../../layout/PageLoader'
import { OptimalRangeTable } from '../../../optimalRange/OptimalRangeTable'
import { OptimalRangeLite } from '../../../../lib/validators'
import {
  archiveOptimalRange,
  getOptimalRangeLiteList,
} from '../../../../lib/biomarker'
import {
  getOptimalRangeCreateUrl,
  getOptimalRangeUrl,
} from '../../../../lib/routes'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../../form/button/Button'
import OrgPage from '../../../OrgPage'
import PageSection from '../../../layout/PageSection'
import { Modal, ModalOptionsProps } from '../../../layout/Modal'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import toast from 'react-hot-toast'
import { EmptyState } from '../../../layout/EmptyState'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid'

export default function OrgOptimalRangesListPage() {
  const { org, theme: orgTheme } = useOrganisationContext()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [isArchiving, setIsArchiving] = useState(false)
  const [optimalRanges, setOptimalRanges] = useState<OptimalRangeLite[]>([])
  const [error, setError] = useState<string | null>(null)
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [selectedOptimalRange, setSelectedOptimalRange] =
    useState<OptimalRangeLite | null>(null)
  const theme = orgTheme || undefined

  useEffect(() => {
    if (!org) {
      return
    }

    setDocumentTitle(`Optimal Ranges`, 'BRANDED')

    const fetchProfiles = async () => {
      if (!org.uuid) {
        setError('Organisation UUID is missing')
        setLoading(false)
        return
      }

      try {
        const data = await getOptimalRangeLiteList({
          organisationUuid: org.uuid,
        })
        setOptimalRanges(data)
      } catch (err) {
        setError('Failed to fetch optimal ranges')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchProfiles()
  }, [org])

  const handleArchive = (optimalRange: OptimalRangeLite) => {
    setSelectedOptimalRange(optimalRange)
    setShowArchiveModal(true)
  }

  const confirmArchive = async () => {
    if (selectedOptimalRange) {
      try {
        setIsArchiving(true)
        const success = await archiveOptimalRange(selectedOptimalRange.uuid)
        if (success) {
          toast.success(`${selectedOptimalRange.name} archived`)
          setOptimalRanges((prev) =>
            prev.filter((range) => range.uuid !== selectedOptimalRange.uuid)
          )
        } else {
          toast.error('Failed to archive optimal range')
        }
      } catch (error) {
        toast.error('Failed to archive optimal range')
      } finally {
        setIsArchiving(false)
        setShowArchiveModal(false)
      }
    }
  }

  const cancelArchive = () => {
    setShowArchiveModal(false)
  }

  const handleEdit = (optimalRange: OptimalRangeLite) => {
    const url = getOptimalRangeUrl(org!.id, optimalRange.uuid)
    navigate(url)
  }

  const handleRowClick = (optimalRange: OptimalRangeLite) => {
    const url = getOptimalRangeUrl(org!.id, optimalRange.uuid)
    navigate(url)
  }

  if (!org) {
    return <PageLoader theme={theme} />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  const archiveModalOptions: ModalOptionsProps = {
    title: 'Archive',
    description: `Are you sure you want to archive ${selectedOptimalRange?.name}?`,
    mainActions: [
      {
        label: 'Archive',
        color: 'blood',
        handler: confirmArchive,
      },
    ],
    secondaryAction: {
      label: 'Cancel',
      handler: cancelArchive,
    },
    content: '',
  }

  return (
    <Fragment>
      {org && (
        <OrgPage
          theme={theme || undefined}
          org={org}
          tabs={[
            {
              name: 'Optimal Ranges',
              href: `/org/${org.id}/optimal-ranges`,
            },
          ]}
        >
          {loading && <PageLoader theme={theme} />}
          {!loading && optimalRanges.length === 0 && (
            <EmptyState
              image={
                <AdjustmentsHorizontalIcon className="h-24 inline text-blood" />
              }
              title="No optimal ranges found"
            >
              <Link
                to={getOptimalRangeCreateUrl(org.id)}
                className="mb-4 inline-block"
              >
                <Button label="Create Optimal Range" color="blood" />
              </Link>
            </EmptyState>
          )}
          {!loading && optimalRanges.length > 0 && (
            <PageSection>
              <div className="p-4">
                <Link
                  to={getOptimalRangeCreateUrl(org.id)}
                  className="mb-4 inline-block"
                >
                  <Button label="Create Optimal Range" color="blood" />
                </Link>
                <OptimalRangeTable
                  data={optimalRanges}
                  onArchive={handleArchive}
                  onEdit={handleEdit}
                  onRowClick={handleRowClick}
                />
              </div>
            </PageSection>
          )}
        </OrgPage>
      )}
      <Modal
        show={showArchiveModal}
        size="sm"
        theme={theme}
        options={archiveModalOptions}
        onClose={cancelArchive}
        canDismiss={!isArchiving}
        loading={isArchiving}
      />
    </Fragment>
  )
}
