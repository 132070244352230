// import { Fragment } from 'react'
import { ReactNode } from 'react'
import { ThemeInterface } from '../../lib/interfaces'
import { onChangeInterface } from './TextInputField'

interface RadioGroupOptionProps {
  value: string
  label: string | ReactNode
}
export interface RadioGroupProps {
  name: string
  options: RadioGroupOptionProps[]
  selected: string
  theme?: ThemeInterface
  disabled?: boolean
  handleChange?: (data: onChangeInterface) => void
}

export function RadioGroup({
  name,
  options,
  selected,
  theme,
  disabled = false,
  handleChange,
}: RadioGroupProps) {
  return (
    <div className={`group inline`}>
      {options.map((item, idx) => (
        <RadioInput
          key={`radio-option-${name}-${idx}`}
          className={`border-jade dark:border-gray-dark/30 sm:text-md text-xs border-t border-b first:border last:border first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md inline-block`}
          labelClassName="px-4 py-1.5 sm:px-8 inline-block"
          disabled={disabled || false}
          id={`radio-${name}-${idx}`}
          name={name}
          value={item.value}
          label={item.label}
          selected={item.value === selected}
          hideRadioInput={true}
          theme={theme}
          handleChange={handleChange}
        ></RadioInput>
      ))}
    </div>
  )
}

export interface RadioInputProps {
  label?: string | ReactNode
  id: string
  name: string
  style?: React.CSSProperties
  handleChange?: (data: onChangeInterface) => void
  className?: string
  // firstChildClassName?: string
  // lastChildClassName?: string
  required?: boolean
  disabled?: boolean
  value?: string
  readonly?: boolean
  hideRadioInput?: boolean
  selected?: boolean
  theme?: ThemeInterface
  labelClassName?: string
}

export function RadioInput({
  id,
  name,
  label,
  handleChange,
  className,
  // firstChildClassName,
  // lastChildClassName,
  required,
  disabled,
  selected,
  readonly,
  hideRadioInput,
  value,
  labelClassName,
}: RadioInputProps) {
  const handleValueChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const element = e.target as HTMLInputElement
    const itemValue: string | boolean = element.value
    handleChange &&
      handleChange({
        name: element.name,
        value: itemValue,
        strValue: itemValue.toString(),
      })
  }

  return (
    <span
      className={`has-[:checked]:bg-jade has-[:checked]:dark:bg-jade/40 has-[:checked]:text-white ${className || ''}`}
    >
      <input
        type="radio"
        id={id}
        name={name}
        onChange={handleValueChange}
        className={`peer ${hideRadioInput ? 'hidden' : ''}`}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        defaultChecked={selected}
        value={value}
      />
      {label && (
        <label
          htmlFor={id}
          className={`cursor-pointer ${labelClassName || ''}`}
        >
          {label}
        </label>
      )}
    </span>
  )
}
