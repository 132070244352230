import { useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { Modal } from '../../../../layout/Modal'
import { PdfViewer } from '../../../../ui/PdfViewer'

interface TermsAcceptanceModal {
  show: boolean
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
  onAccept?: () => void
  onDecline?: () => void
  termsToAcceptUrl: string
}

export function TermsAcceptanceModal({
  show,
  setShow,
  theme,
  onAccept,
  onDecline,
  termsToAcceptUrl,
}: TermsAcceptanceModal) {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(show)
  }, [show])

  return (
    <Modal
      size="max"
      show={open}
      options={{
        mainActions: [
          {
            label: 'Accept',

            handler: () => {
              setShow(false)
              onAccept && onAccept()
            },
          },
        ],
        secondaryAction: {
          label: 'Decline',
          handler: () => {
            setShow(false)
            onDecline && onDecline()
          },
        },
        title: 'Accept the terms',
        content: (
          <div>
            <PdfViewer url={termsToAcceptUrl} height="75dvh"></PdfViewer>
          </div>
        ),
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
    ></Modal>
  )
}
