export enum OrgImageTypes {
  Favicon = 'favicon',
  Banner = 'banner',
  Preview = 'preview',
}
export enum AvatarImageTypes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export function getSizedImage(
  type: OrgImageTypes | AvatarImageTypes,
  url?: string | null,
  imageCrop?: boolean
): string | undefined {
  if (!url) {
    return
  }
  if (!imageCrop) {
    return url
  }
  const urlParts: string[] = url.split('.')
  const extension = urlParts.pop()
  return [urlParts.join('.'), type, extension].join('.')
}
