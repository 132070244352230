import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import {
  AdjustmentsHorizontalIcon,
  CheckIcon,
} from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import {
  fieldColorClass,
  fieldHoverBgColorClass,
  fieldHoverTextColorClass,
  fieldShapeClass,
  fieldTextColorClass,
  inputClass,
  listBoxButtonClass,
} from '../../form/styles'
import RoundedSection from '../../layout/RoundedSection'
import { ThemeInterface } from '../../../lib/interfaces'
import { RadioGroup } from '../../form/RadioGroup'

export type OptimalRangeOption = {
  key: string
  name: string
  icon?: JSX.Element
}

export enum ViewFilterStates {
  BGT = 'bgt',
  SIMPLE = 'simple',
}

type ResultTabMenuBarProps = {
  searchTerm: string
  rangeOptions: OptimalRangeOption[]
  selectedRangeKeys: string[]
  onSearchChange: (term: string) => void
  onRangesChange: (selectedKeys: string[]) => void
  view?: string
  onViewChange: (view: string) => void
  theme?: ThemeInterface
}

export const ResultTabMenuBar: React.FC<ResultTabMenuBarProps> = ({
  rangeOptions,
  searchTerm,
  selectedRangeKeys,
  onSearchChange,
  onRangesChange,
  view,
  onViewChange,
  theme,
}) => {
  return (
    <RoundedSection size="lg" className="!px-2 !py-2 mb-8 print:hidden">
      <div className="flex items-center justify-between gap-4 flex-col sm:flex-row">
        {/* Search Bar */}
        <div className="flex-grow">
          <input
            type="search"
            placeholder="Filter biomarkers"
            className={inputClass}
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>

        {/* View Toggle */}
        <div className="flex items-center gap-2">
          <RadioGroup
            name="view"
            options={[
              {
                label: 'Bloody Good View',
                value: ViewFilterStates.BGT,
              },
              {
                label: 'Simplified View',
                value: ViewFilterStates.SIMPLE,
              },
            ]}
            selected={view || ViewFilterStates.BGT}
            handleChange={(e) => onViewChange(e.strValue)}
            theme={theme}
          />
        </div>

        {/* Multi-Select Dropdown - Render Only if Multiple Options */}
        {rangeOptions.length > 1 && (
          <div className="flex items-center gap-2">
            <OptimalRangeMultiSelect
              options={rangeOptions}
              selectedKeys={selectedRangeKeys}
              onChange={onRangesChange}
            />
          </div>
        )}
      </div>
    </RoundedSection>
  )
}

type OptimalRangeMultiSelectProps = {
  options: OptimalRangeOption[]
  selectedKeys: string[]
  onChange: (selectedKeys: string[]) => void
}

export const OptimalRangeMultiSelect: React.FC<
  OptimalRangeMultiSelectProps
> = ({ options, selectedKeys, onChange }) => {
  const toggleOption = (key: string) => {
    const updatedKeys = selectedKeys.includes(key)
      ? selectedKeys.filter((k) => k !== key)
      : [...selectedKeys, key]

    onChange(updatedKeys)
  }

  return (
    <div className="relative w-72">
      <Listbox as="div" value={selectedKeys} onChange={() => {}} multiple>
        <ListboxButton className={listBoxButtonClass}>
          <span>View Ranges</span>
          <AdjustmentsHorizontalIcon
            className="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </ListboxButton>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ListboxOptions
            className={`absolute z-10 mt-2 max-h-60 w-full overflow-auto py-1 ${fieldShapeClass} ${fieldColorClass} shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            {options.map((option) => (
              <ListboxOption key={option.key} value={option.key}>
                {({ selected }) => (
                  <div
                    onClick={() => toggleOption(option.key)}
                    className={`flex cursor-pointer items-center gap-2 px-4 py-2 ${fieldTextColorClass} ${fieldHoverBgColorClass} ${fieldHoverTextColorClass}`}
                  >
                    {option.icon && (
                      <span className="h-5 w-5 text-jade/80">
                        {option.icon}
                      </span>
                    )}
                    <span>{option.name}</span>
                    {selected && (
                      <CheckIcon className="ml-auto h-5 w-5 text-jade/80" />
                    )}
                  </div>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </Listbox>
    </div>
  )
}
