import { ReactNode, FunctionComponent } from 'react'

interface PillProps {
  children: ReactNode
  className?: string
}

export const Pill: FunctionComponent<PillProps> = ({
  children,
  className = '',
}) => {
  return (
    <span
      className={` inline-flex items-center rounded-full px-2 py-1 m-1 text-xs font-medium ring-1 ring-inset ${className}`}
    >
      {children}
    </span>
  )
}
