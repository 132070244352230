import { FunctionComponent } from 'react'

import { IDesktopSidebarProps } from '../../lib/interfaces'

import { MainMenuItem } from '../menu/MainMenuItem'
import { PoweredBy } from '../organisation/PoweredBy'

const DesktopSidebar: FunctionComponent<IDesktopSidebarProps> = ({
  menuItemGroups,
  theme,
}) => {
  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-72 lg:flex-col mt-16 z-10 border-r border-gray-medium dark:border-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-off-white pb-4 dark:bg-dark-gray-light dark:shadow-xl dark:border-0">
        <nav className="flex flex-1 flex-col pt-5 px-6">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            {menuItemGroups.map((group, idx) => (
              <li key={`menu-item-desktop-${idx}`}>
                {group.items.length > 0 && group.header && (
                  <div className="text-xs font-semibold leading-6 text-gray-semi-dark uppercase">
                    {group.header}
                  </div>
                )}
                <ul role="list" className="-mx-2 space-y-1">
                  {group.items.map((item) => (
                    <MainMenuItem
                      item={item}
                      theme={theme}
                      identifier="desktop"
                      key={`desktop-${item.name}`}
                    ></MainMenuItem>
                  ))}
                </ul>
              </li>
            ))}
            {theme && <PoweredBy></PoweredBy>}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default DesktopSidebar
