import { PropsWithChildren } from 'react'
import { Tab, ThemeInterface } from '../../lib/interfaces'
import { classNames } from '../../lib/utils'
import { Link, useNavigate } from 'react-router-dom'
import { OrganisationWithBranding } from '../context/OrganisationContext'
import SimpleSelectField from '../form/SimpleSelectField'
import { onChangeInterface } from '../form/TextInputField'

interface TabbedMenuProps extends PropsWithChildren {
  tabs: Record<string, Tab>
  currentPage: string
  org?: OrganisationWithBranding
  theme?: ThemeInterface
}

export function TabbedMenu({ tabs, org, currentPage, theme }: TabbedMenuProps) {
  const navigate = useNavigate()

  const handleElementChange = (data: onChangeInterface) => {
    navigate(data.value.toString())
  }

  return (
    <div className="print:hidden">
      <div className="sm:hidden mb-4">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <SimpleSelectField
          handleChange={handleElementChange}
          name="tabs"
          value={currentPage}
          options={Object.entries(tabs).map(([, tab]) => ({
            value: tab.href,
            label: tab.name,
          }))}
        ></SimpleSelectField>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200 dark:border-dark-gray-light mb-4">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {Object.entries(tabs).map(([key, tab]) => (
              <Link
                key={tab.name}
                to={key}
                className={classNames(
                  tab.current === true
                    ? org?.theme || theme
                      ? 'text-[--active-color] border-[--active-color]'
                      : 'border-bgt-primary text-bgt-primary'
                    : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                  'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                  !tab.current ? 'dark:text-white/80 dark:hover:text-white' : ''
                )}
                style={
                  (org?.theme
                    ? ({
                        '--active-color': org.theme.linkColor,
                      } as React.CSSProperties)
                    : {},
                  theme
                    ? ({
                        '--active-color': theme.linkColor,
                      } as React.CSSProperties)
                    : {})
                }
                aria-current={tab.current === true ? 'page' : undefined}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current
                        ? 'bg-bgt-primary/10 text-bgt-primary font-bold'
                        : 'bg-gray-100 text-gray-900',
                      'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
