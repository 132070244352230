import { classNames } from "../../lib/utils";
import { fieldTextColorClass, inputClass } from "./styles";

export interface InputAdornmentProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "prefix"> {
  className?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

export const InputAdornment: React.FC<InputAdornmentProps> = ({
  className,
  prefix,
  suffix,
  ...props
}) => {
  return (
    <div className="relative flex items-center">
      {/* Prefix */}
      {prefix && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pr-2 border-r border-gray-dark/30 z-10">
          {typeof prefix === "string" ? (
            <span className={`text-sm ${fieldTextColorClass}`}>{prefix}</span>
          ) : (
            prefix
          )}
        </div>
      )}

      {/* Input */}
      <input
        {...props}
        className={classNames(
          inputClass,
          prefix ? "pl-16" : "", // Adjust padding for prefix
          suffix ? "pr-16" : "", // Adjust padding for suffix
          className || ""
        )}
        style={{
          paddingLeft: prefix ? "4rem" : undefined, // Dynamic padding for prefix
          paddingRight: suffix ? "4rem" : undefined, // Dynamic padding for suffix
        }}
      />

      {/* Suffix */}
      {suffix && (
        <div className="absolute inset-y-0 right-0 flex items-center pl-2 pr-3 border-l border-gray-dark/30 z-10">
          {typeof suffix === "string" ? (
            <span className={`text-sm ${fieldTextColorClass}`}>{suffix}</span>
          ) : (
            suffix
          )}
        </div>
      )}
    </div>
  );
};
