interface BoundedValueBarProps {
  lowerBound?: number | null
  upperBound?: number | null
  value: number
}

type PercentageOptions = 0.2 | 0.25

export default function BoundedValueBar({
  lowerBound,
  upperBound,
  value,
}: BoundedValueBarProps) {
  const upperAndLowerLimitPercentage: PercentageOptions = 0.2

  const getClassForLimitPercentage = (amt: number): string => {
    if (amt === 0.0) {
      return ''
    } else if (amt === 0.2) {
      return 'w-1/5'
    } else if (amt === 0.25) {
      return 'w-1/4'
    } else if (amt === 0.5) {
      return 'w-1/2'
    } else if (amt === 0.6) {
      return 'w-3/5'
    } else if (amt === 0.75) {
      return 'w-3/4'
    } else if (amt === 0.8) {
      return 'w-4/5'
    } else if (amt === 1.0) {
      return 'w-full'
    }
    return 'w-full'
  }

  const calculateValuePosition = (): string => {
    // if we don't have a lower bound, assume its 0
    const lowerLimit = lowerBound ?? 0
    const upperLimit = upperBound || lowerBound! * 1.5 || 100 // this could be wrong, but set to 100 for now as a default.
    const range = upperLimit - lowerLimit

    const graphUpper = upperLimit + range * 2 * upperAndLowerLimitPercentage
    const graphLower = lowerLimit - range * 2 * upperAndLowerLimitPercentage

    const result = (value - graphLower) / (graphUpper - graphLower)

    const pos = result * 100
    const per = pos > 100 ? 100 : pos < 0 ? 0 : pos
    return `${per}%`
  }

  const shouldShowLowerLimit = lowerBound !== null && lowerBound !== undefined
  const shouldShowUpperLimit = upperBound !== null && upperBound !== undefined

  const shouldShowIndicator = (): boolean => {
    return shouldShowLowerLimit || shouldShowUpperLimit
  }

  const shouldShowSafeRange = (): boolean => {
    return shouldShowLowerLimit || shouldShowUpperLimit
  }

  const getSafeRangeWidth = (): string => {
    if (!shouldShowLowerLimit && !shouldShowUpperLimit) {
      return getClassForLimitPercentage(1.0)
    } else if (!shouldShowLowerLimit || !shouldShowUpperLimit) {
      return getClassForLimitPercentage(1.0 - upperAndLowerLimitPercentage)
    }
    return getClassForLimitPercentage(1.0 - upperAndLowerLimitPercentage * 2)
  }

  const getLimitWidth = (): string => {
    if (!shouldShowLowerLimit && !shouldShowUpperLimit) {
      return getClassForLimitPercentage(0)
    } else if (!shouldShowLowerLimit || !shouldShowUpperLimit) {
      return getClassForLimitPercentage(upperAndLowerLimitPercentage)
    }
    return getClassForLimitPercentage(upperAndLowerLimitPercentage)
  }

  const getIndicatorClass = (): string => {
    if (!shouldShowLowerLimit && !shouldShowUpperLimit) {
      return 'bg-jade'
    } else if (!shouldShowLowerLimit && shouldShowUpperLimit) {
      return value <= upperBound! ? 'bg-jade' : 'bg-mandarin'
    } else if (shouldShowLowerLimit && !shouldShowUpperLimit) {
      return value >= lowerBound! ? 'bg-jade' : 'bg-mandarin'
    } else {
      return value >= lowerBound! && value <= upperBound!
        ? 'bg-jade'
        : 'bg-mandarin'
    }
  }

  return (
    <>
      {!shouldShowLowerLimit && !shouldShowUpperLimit && ''}
      <div className="w-full relative">
        {shouldShowLowerLimit && (
          <span
            className={`inline-block rounded-l-lg ${getLimitWidth()} bg-mandarin h-2 align-middle`}
          ></span>
        )}
        {shouldShowSafeRange() && (
          <span
            className={`inline-block rounded-none ${getSafeRangeWidth()} bg-jade h-2 align-middle`}
          ></span>
        )}
        {shouldShowUpperLimit && (
          <span
            className={`inline-block rounded-r-lg ${getLimitWidth()} bg-mandarin h-2 align-middle`}
          ></span>
        )}
        {shouldShowLowerLimit && (
          <span
            className={`inline-block align-middle absolute top-6 w-10 text-center -ml-5 text-gray-semi-dark dark:text-white text-sm`}
            style={{
              left: `${upperAndLowerLimitPercentage * 100}%`,
            }}
          >
            {lowerBound}
          </span>
        )}
        {shouldShowUpperLimit && (
          <span
            className={`inline-block align-middle absolute top-6 w-10 text-center -ml-5 text-gray-semi-dark dark:text-white text-sm`}
            style={{
              left: `${100 - upperAndLowerLimitPercentage * 100}%`,
            }}
          >
            {upperBound}
          </span>
        )}

        {shouldShowIndicator() && (
          <div
            className={`inline-block absolute border-2 ${getIndicatorClass()} border-off-white dark:border-dark-gray-lighter w-3 h-6 left-0 rounded-full`}
            style={{
              left: calculateValuePosition(),
              top: '1px',
              marginLeft: '-1%',
            }}
            title={value.toString()}
          >
            <div
              className={`absolute -left-4 h-6 w-10 ${getIndicatorClass()} rounded-full text-white text-sm font-bold text-center shadow-md`}
              style={{
                top: '-32px',
              }}
            >
              {value}
            </div>
            <div
              className={`absolute h-2 w-2 ${getIndicatorClass()} rotate-45`}
              style={{
                top: '-13px',
              }}
            ></div>
          </div>
        )}
      </div>
    </>
  )
}
