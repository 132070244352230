import { Button } from '../../form/button/Button'
import { GroupSection, GroupSectionItem } from '../../layout/GroupSection'
import { ComingSoon } from '../../ui/ComingSoon'

import { SettingsAccountProps } from './SettingsTabAccount'
// import { SettingsAccountProps } from './SettingsTabAccount'

export default function SettingsTabAccountDeletion({
  theme,
}: SettingsAccountProps) {
  return (
    <GroupSection
      title="Account deletion"
      description="Delete your account, along with all associated data."
    >
      <GroupSectionItem>
        <form>
          <div className="mt-8 flex">
            <ComingSoon>
              <Button
                theme={theme}
                type="submit"
                label="Yes, I wish to delete my account"
              ></Button>
            </ComingSoon>
          </div>
        </form>
      </GroupSectionItem>
    </GroupSection>
  )
}
