import toast from 'react-hot-toast'
import { z } from 'zod'
import { GraphQLResponseSchema } from '../lib/errors'
import { authFetch, unauthedFetch } from '../providers/AuthProvider'
import {
  CreateUserAndProfile,
  UserAndProfileCreateResponse,
  UserAndProfileCreateResponseSchema,
} from './validators'

export const SentUserEmailVerificationSchema = z.object({
  success: z.boolean(),
  message: z.string(),
})

const SendUserEmailVerificationInputSchema = z.object({
  profileUuid: z.string().uuid(),
  organisationUuid: z.string().uuid().optional(),
})
type SendUserEmailVerificationInput = z.infer<
  typeof SendUserEmailVerificationInputSchema
>

export const resendVerificationEmail = async (
  input: SendUserEmailVerificationInput
) => {
  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
            mutation {
              sendUserEmailVerification(input: {
                profileUuid: "${input.profileUuid}"
                ${input.organisationUuid ? `organisationUuid: "${input.organisationUuid}"` : ''}
              }) {
                success
                message
              }
            }
          `,
    }),
  })
  const result = response.data
  try {
    const envelope = GraphQLResponseSchema.parse(result)
    if (envelope.errors && envelope.errors.length > 0) {
      throw new Error(envelope.errors[0].message)
    }
    const sentUserVerificationEmail = SentUserEmailVerificationSchema.parse(
      envelope.data?.sendUserEmailVerification
    )
    if (sentUserVerificationEmail.success) {
      toast.success('A verification email has been sent to your email address!')
      return
    }

    toast.error('Unable to send verification email to your email address.')
  } catch (error) {
    toast.error('Unable to send verification email to your email address.')
  }
}

export const createUserWithProfile = async (
  data: CreateUserAndProfile
): Promise<UserAndProfileCreateResponse> => {
  const result = await unauthedFetch(
    `${import.meta.env.VITE_API_URL}/user/create`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: data,
    }
  )
  const parsedData = UserAndProfileCreateResponseSchema.parse(result.data.data)
  return parsedData
}
