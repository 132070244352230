import { Fragment, useCallback, useEffect, useState } from 'react'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { OrganisationWithBranding } from '../../../../context/OrganisationContext'
import { useNavigate } from 'react-router-dom'
import {
  buyPackageForEmail,
  getBillingAndProductInfoForOrganisation,
} from '../../../../../lib/organisation'
import toast from 'react-hot-toast'
import { Modal } from '../../../../layout/Modal'
import { getBundleUrl } from '../../../../../lib/routes'
import TextInputField, {
  onChangeInterface,
} from '../../../../form/TextInputField'
import { Textarea } from '../../../../form/Textarea'
import { formatMoney, isEmailValid } from '../../../../../lib/utils'
import Checkbox from '../../../../form/Checkbox'
import { InfoSection } from '../../../../ui/InfoSection'

interface BuyPackageModalProps {
  show: boolean
  productId?: string
  productName?: string
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
  org: OrganisationWithBranding | null
  dismissOnSkip?: boolean
  dismissOnSend?: boolean
}

interface BillingInfo {
  price?: number
  hasDefaultPaymentMethod: boolean
}

export function BuyPackageModal({
  show,
  setShow,
  theme,
  productId,
  productName,
  org,
  dismissOnSkip = false,
  dismissOnSend = false,
}: BuyPackageModalProps) {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState<NameEmailAndNote>({})
  const [canSubmit, setCanSubmit] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const [billingInfo, setBillingInfo] = useState<BillingInfo>()
  const [canDismiss, setCanDismiss] = useState(false)
  const [acceptedAccountCreationTerms, setAcceptedAccountCreationTerms] =
    useState(false)
  const [acceptedPurchaseTerms, setAcceptedPurchaseTerms] = useState(false)

  const [loading, setLoading] = useState(true)

  // const stripe = useStripe()
  // const elements = useElements()

  const handleElementChange = (e: onChangeInterface) => {
    console.log('we got an element change', e, typeof e.value)
    if (e.name === 'purchase-terms' && typeof e.value === 'boolean') {
      setAcceptedPurchaseTerms(e.value)
    }
    if (e.name === 'account-terms' && typeof e.value === 'boolean') {
      setAcceptedAccountCreationTerms(e.value)
    }
  }

  const getBillingAndProductInfo = useCallback(
    async (prodId: string) => {
      if (!open) {
        return
      }
      if (billingInfo !== undefined) {
        return
      } else {
        console.log('set loading trueeeee')
        setLoading(true)
      }

      // setLoading(true)
      console.log('lets get the product for uuid', prodId)
      console.log(org, billingInfo)
      if (!org) {
        return
      }

      try {
        const latestBillingInfo = await getBillingAndProductInfoForOrganisation(
          org.uuid,
          prodId
        )
        setBillingInfo(latestBillingInfo)
      } catch (e) {
        if (e instanceof Error) {
          if (e.message === 'MISSING_BILLING_INFO') {
            setError(
              "Oops, it looks like there isn't any billing information on file. Head over to the settings page to Setup Default Billing."
            )
          } else if (e.message === 'INVALID_STRIPE_CUSTOMER') {
            setError(
              "There doesn't appear to be a valid stripe customer associated with this billing record. Head over to the settings page to Setup Default Billing."
            )
          } else if (e.message === 'INVALID_STRIPE_PRODUCT') {
            setError(
              'This product does not exist in the system, please contact support.'
            )
          } else if (e.message === 'INVALID_DEFAULT_PRICE') {
            setError(
              "This product doesn't have an default price associated with it. Please contact support"
            )
          } else {
            setError(e.message)
          }
        }
      }

      setLoading(false)
      setCanDismiss(true)
    },
    [billingInfo, org, open]
  )

  useEffect(() => {
    if (billingInfo) {
      console.log('Set loading to false')
      setLoading(false)
    }
  }, [billingInfo])

  useEffect(() => {
    if (
      formData.email &&
      formData.email.length > 1 &&
      !isEmailValid(formData.email)
    ) {
      setError('Invalid email address')
    } else {
      setError('')
    }
    setCanSubmit(
      formData.name &&
        formData.email &&
        isEmailValid(formData.email) &&
        acceptedAccountCreationTerms &&
        acceptedPurchaseTerms
        ? true
        : false
    )
  }, [formData, acceptedAccountCreationTerms, acceptedPurchaseTerms])

  useEffect(() => {
    setOpen(show)
    if (productId && show && !billingInfo) {
      getBillingAndProductInfo(productId)
    }
  }, [show, productId, getBillingAndProductInfo, org, billingInfo])

  const buyPackageForUser = async () => {
    // send the meail here
    // packageUrl

    if (!org) {
      setError('Missing organisation, unable to save')
      return
    }
    if (!productId) {
      setError('Missing product id, unable to save')
      return
    }
    try {
      const buyPackageEmailResponse = await buyPackageForEmail(
        org.uuid,
        productId,
        formData
      )
      if (buyPackageEmailResponse.length > 0) {
        if (buyPackageEmailResponse[0].status !== 'success') {
          setError(
            `Unable to email test to ${buyPackageEmailResponse[0].name}, please try again.`
          )

          return
        }
        setShow(false)
        toast.success(
          `Package purchased for ${buyPackageEmailResponse[0].name}`,
          {
            duration: 3000,
          }
        )
        return
      }
    } catch (e) {
      let message = ''
      if (e instanceof Error) {
        if (e.message === 'MISSING_BILLING_INFO') {
          message =
            "Oops, it looks like there isn't any billing information on file. Head over to the settings page to Setup Default Billing."
        } else if (e.message === 'INVALID_STRIPE_CUSTOMER') {
          message =
            "There doesn't appear to be a valid stripe customer associated with this billing record. Head over to the settings page to Setup Default Billing."
        } else if (e.message === 'INVALID_STRIPE_PRODUCT') {
          message =
            'This product does not exist in the system, please contact support.'
        } else if (e.message === 'INVALID_DEFAULT_PRICE') {
          message =
            "This product doesn't have an default price associated with it. Please contact support"
        } else {
          message = e.message
        }
      } else {
        message = 'Something went wrong..'
      }
      toast.error(message)
      setError(message)
      throw e
    }
    setError('')
  }

  return (
    <Modal
      show={open}
      loading={loading}
      error={error}
      options={{
        mainActions: [
          billingInfo && billingInfo.hasDefaultPaymentMethod
            ? {
                label: 'Buy',
                handler: async () => {
                  // lets submit the form now.
                  setLoading(true)
                  setCanDismiss(false)
                  try {
                    await buyPackageForUser()
                    setCanDismiss(true)
                    setLoading(false)

                    if (dismissOnSend) {
                      setShow(false)
                      return
                    }
                    if (!org || !org.uuid) {
                      navigate('/')
                    } else {
                      navigate(getBundleUrl(org?.id, productId!))
                    }
                  } catch (e) {
                    setCanDismiss(true)
                    setLoading(false)
                  }
                },
                disabled: !canSubmit,
                loading: loading,
              }
            : {
                label: 'Setup Default Billing',
                handler: async () => {
                  // lets submit the form now.
                  setOpen(false)
                  navigate(`/org/${org!.id}/settings/billing`)
                },
                disabled: false,
                loading: false,
              },
        ],
        secondaryAction: {
          label: 'Cancel',

          handler: () => {
            if (dismissOnSkip) {
              setShow(false)
              return
            }
            if (!org || !org.uuid) {
              navigate('/')
            } else {
              navigate(getBundleUrl(org?.id, productId!))
            }
            setShow(false)
          },
        },
        title: 'Buy for...',
        description: (
          <>
            Purchase this test for someone else.
            <InfoSection>
              This allows you to purchase this test on behalf of someone else.
              You must have a valid credit card on file, so we can charge you
              for the test. Upon completion of this form and the successful
              purchase, we will send an email to the recipient confirming the
              purchase, and they will also receive an account signup link to
              complete the process.
              <br />
              <br />
              Once completed, they will receive their referral electronically to
              their email.
            </InfoSection>
          </>
        ),
        content: (
          <div className="mt-2">
            {billingInfo && billingInfo.hasDefaultPaymentMethod && (
              <Fragment>
                {billingInfo.price && (
                  <div className="mb-6">
                    <b>
                      Test Price (incl. GST, excl. Markup):
                      {formatMoney(
                        Math.round(billingInfo.price * 1.1) / 100,
                        'aud'
                      )}
                    </b>
                  </div>
                )}
                <NameEmailNoteInput onChange={setFormData}></NameEmailNoteInput>
                <div className=" mt-2 text-sm text-left">
                  <Checkbox
                    id="account-terms"
                    name="account-terms"
                    type="checkbox"
                    disabled={loading}
                    checked={acceptedAccountCreationTerms}
                    onChange={handleElementChange}
                    checkboxLabel={
                      <Fragment>
                        <i>
                          I have received consent from this person to create an
                          account on their behalf, and to allow Bloody Good
                          Tests to email them.
                        </i>
                      </Fragment>
                    }
                  ></Checkbox>
                </div>
                {formData.name &&
                  formData.email &&
                  isEmailValid(formData.email) && (
                    <div className=" mt-2 text-sm text-left">
                      <Checkbox
                        id="purchase-terms"
                        name="purchase-terms"
                        type="checkbox"
                        checked={acceptedPurchaseTerms}
                        disabled={loading}
                        onChange={handleElementChange}
                        checkboxLabel={
                          <Fragment>
                            <i>
                              I agree to purchasing '{productName}'{' '}
                              {formData.name
                                ? `for ${formData.name} ${formData.email ? `(${formData.email})` : ''}`
                                : ''}
                            </i>
                          </Fragment>
                        }
                      ></Checkbox>
                    </div>
                  )}
              </Fragment>
            )}
            {billingInfo && !billingInfo.hasDefaultPaymentMethod && (
              <div>
                <b>
                  Looks like there is no card on file. Please add a card to your
                  account to unlock the ability to purchase a test for someone
                  else.
                </b>
              </div>
            )}
          </div>
        ),
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
      size="xl"
      canDismiss={canDismiss}
    ></Modal>
  )
}

export interface NameEmailAndNote {
  name?: string
  email?: string
  note?: string
}
interface NameAndEmailWithNoteInputProps {
  onChange: (data: NameEmailAndNote) => void
}

function NameEmailNoteInput({ onChange }: NameAndEmailWithNoteInputProps) {
  const [name, setName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [note, setNote] = useState<string>()

  useEffect(() => {
    console.log('NameEmailAndNote initialized...')
  }, [])

  useEffect(() => {
    onChange({
      name,
      email,
      note,
    })
  }, [name, email, note, onChange])

  return (
    <Fragment>
      <div className="grid grid-cols-4 gap-2">
        <div className="col-span-2">
          <TextInputField
            id="package-toname"
            name="package-toname"
            type="text"
            label="Recipient Name"
            value={name}
            handleChange={(d) => setName(d.strValue)}
          ></TextInputField>
        </div>
        <div className="col-span-2">
          <TextInputField
            id="package-toemail"
            name="package-tonemail"
            type="email"
            label="Recipient Email"
            value={email}
            handleChange={(d) => setEmail(d.strValue)}
          ></TextInputField>
        </div>
        <div className="col-span-4">
          <Textarea
            id="package-tonote"
            name="package-tonote"
            label="Comment (Optional)"
            value={note}
            handleChange={(d) => setNote(d.strValue)}
          ></Textarea>
        </div>
      </div>
    </Fragment>
  )
}
