import { Fragment, useEffect, useState } from 'react'
import { formatMoney } from '../../lib/utils'
import { PageHeading } from '../layout/PageHeading'
import {
  CalculatedPrice,
  TestWithBiomarkersSelected,
} from '../../lib/validators'
import { TrashIcon } from '@heroicons/react/24/outline'
import { SpinningCircles } from 'react-loading-icons'
import TextInputField, { onChangeInterface } from '../form/TextInputField'
import { useDebounce } from 'use-debounce'
import posthog from '../../lib/posthog'
// import { useDebounce } from 'use-debounce'

interface PriceSummaryProps {
  price: CalculatedPrice
  showSummary: boolean
  tests: TestWithBiomarkersSelected[]
  markup?: number
  onRemove?: (test: string) => void
  onMarkupChange?: (markup: number) => void
}

export default function PriceSummary({
  price,
  tests,
  markup,
  onRemove,
  onMarkupChange,
}: PriceSummaryProps) {
  // const markupInputReference = createRef<HTMLInputElement>()
  // const [totalPrice, setTotalPrice] = useState(price)
  const [localMarkup, setLocalMarkup] = useState<string>(
    markup?.toString() || ''
  )

  // useEffect(() => {
  //   let total = 0
  //   tests.forEach((test) =>
  //     test.checked && test.price ? (total += test.price) : 0
  //   )
  //   setTotalPrice(total)
  // }, [tests])

  const [debouncedLocalMarkup] = useDebounce(localMarkup, 300)

  const handleMarkupChange = (data: onChangeInterface): void => {
    console.log(data)
    let newVal = data.strValue
    if (data.strValue && data.strValue !== '') {
      const val = data.strValue.replace(/ /gi, '').replace(/[^0-9.]/gi, '')

      // handle the dot at the end
      if (val !== '') {
        newVal = parseFloat(val.toString()).toString()
        const parts = val.toString().split('')
        const decimalCount = parts.filter((p) => p === '.').length
        if (decimalCount === 1 && parts.pop() === '.') {
          newVal += '.'
        }
      } else {
        newVal = ''
      }
    }
    console.log('set local markup now', newVal)
    setLocalMarkup(newVal)
  }
  // const debouncedMarkupChange = useDebounce(handleMarkupChange, 300)

  useEffect(() => {
    onMarkupChange && onMarkupChange(parseFloat(debouncedLocalMarkup))
  }, [debouncedLocalMarkup, onMarkupChange])

  // useEffect(() => {
  //   console.log('markup changed, setting LocalMarkup with', markup)
  //   setLocalMarkup(markup?.toString())
  // }, [markup])

  return (
    <Fragment>
      <div
        className={`max-h-[75dvh] overflow-scroll bg-white/60 dark:bg-transparent dark:2xl:bg-dark-gray-light/60  dark:text-white 2xl:block`}
      >
        <div className="">
          <Fragment>
            <PageHeading
              title="Tests included"
              size="lg"
              className="!pb-2 mt-2"
            ></PageHeading>
            <div className="grid grid-cols-8">
              {tests
                .filter((item) => item.checked)
                .map((item) => (
                  <Fragment key={`${item.name}-cart-item`}>
                    <Fragment>
                      <div className="pb-1 text-md col-span-6">
                        <div className="inline">{item.name}</div>
                      </div>
                      <span className="block text-gray-dark text-md text-right col-span-2">
                        {item.price && formatMoney(item.price / 100, 'aud')}
                        {onRemove && (
                          <TrashIcon
                            height={16}
                            className="inline text-blood font-bold cursor-pointer transition-all hover:scale-125"
                            onClick={() => onRemove(item.id)}
                          ></TrashIcon>
                        )}
                      </span>
                    </Fragment>
                  </Fragment>
                ))}
              <div className=" font-bold text-md border-t border-gray-dark col-span-6">
                Total test price
              </div>
              <div className="font-bold text-md  border-t border-gray-dark text-right col-span-2">
                {formatMoney(price.testsTotal / 100, 'aud')}
              </div>
              {price.calculating && (
                <Fragment key={price.tests.join('-')}>
                  <div className="italic text-sm border-t border-gray-dark col-span-6">
                    Calculating fees
                  </div>
                  <div className="text-md  border-t border-gray-dark text-gray-dark text-right col-span-2">
                    <SpinningCircles
                      width={16}
                      height={16}
                      stroke={`#FF3A5F`}
                      fill={`#FF3A5F`}
                      className="inline"
                    ></SpinningCircles>
                  </div>
                </Fragment>
              )}
              {!price.calculating &&
                price.discounts.map((discount, i) => (
                  <Fragment key={`discount-${i}`}>
                    <div className="col-span-6 text-jade font-bold text-md">
                      {discount.reason}
                    </div>
                    <div className="pb-2 text-right col-span-2 text-jade font-bold text-md">
                      {formatMoney(-discount.amount / 100, 'aud')}
                    </div>
                  </Fragment>
                ))}
              {!price.calculating &&
                price.fees.map((fee) => (
                  <Fragment key={`fee-${fee.reason}`}>
                    <div className="text-md  col-span-6">{fee.reason}</div>
                    <div className="pb-2 text-md text-gray-dark text-right col-span-2">
                      {formatMoney(fee.amount / 100, 'aud')}
                    </div>
                  </Fragment>
                ))}
              {posthog.isFeatureEnabled('can-add-test-markup') && (
                <div className="col-span-8 w-full border-t border-gray-dark pb-2 pt-2 grid grid-cols-8">
                  <div className=" text-md  align-middle col-span-5">
                    BGT Checkout Markup
                    <br />
                    (excl. GST)
                    <br />
                    <a
                      className="text-blood text-sm"
                      href="https://support.bloodygoodtests.com.au/en/articles/10292658-how-to-add-a-custom-service-fee"
                      target="bgt-support"
                    >
                      What does this mean?
                    </a>
                  </div>
                  <div className="font-bold text-md text-right col-span-3 pr-2">
                    <TextInputField
                      key="markup-input"
                      id="markup"
                      name="markup"
                      type="text"
                      value={localMarkup}
                      className="text-right border-0"
                      // reference={markupInputReference}
                      handleChange={handleMarkupChange}
                      prefix={'$'}
                    ></TextInputField>
                  </div>
                </div>
              )}
              {price.calculating && (
                <Fragment>
                  <div className="italic text-sm border-t border-gray-dark col-span-6">
                    Calculating final price
                  </div>
                  <div className="text-md  border-t border-gray-dark text-gray-dark text-sm text-right col-span-2">
                    <SpinningCircles
                      width={16}
                      height={16}
                      stroke={`#FF3A5F`}
                      fill={`#FF3A5F`}
                      className="inline"
                    ></SpinningCircles>
                  </div>
                </Fragment>
              )}
            </div>

            {!price.calculating && (
              <div className="grid grid-cols-8">
                <div className="pb-2 font-bold text-md border-t border-gray-dark col-span-4">
                  Total
                </div>
                <div className="pb-2 font-bold text-md  border-t border-gray-dark text-right col-span-4">
                  {price.subTotal > price.grandTotal && (
                    <span className="line-through text-gray-dark">
                      {formatMoney(price.subTotal / 100, 'aud')}
                    </span>
                  )}{' '}
                  {formatMoney(price.grandTotal / 100, 'aud')}
                </div>
                {price.tax?.map((tax) => (
                  <Fragment key={`tax-${tax.reason}`}>
                    <div className="text-md  col-span-6">{tax.reason}</div>
                    <div className="pb-2  text-md   text-gray-dark text-md text-right col-span-2">
                      {formatMoney(tax.amount / 100, 'aud')}
                    </div>
                  </Fragment>
                ))}
                <div className="pb-2 font-bold text-md border-t border-gray-dark col-span-4">
                  Total inc. GST
                </div>
                <div className="pb-2 font-bold text-md  border-t border-gray-dark text-right col-span-4">
                  {formatMoney(price.grandTotalIncTax / 100, 'aud')}
                </div>
              </div>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  )
}
