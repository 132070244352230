import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { PropsWithChildren } from 'react'

interface InfoSectionProps extends PropsWithChildren {
  className?: string
}

export function InfoSection({ children, className = '' }: InfoSectionProps) {
  const wrapperClassName =
    'text-sm mt-2 rounded-md p-2 relative align-middle text-left sm:items-start items-center border bg-gray-light dark:!bg-dark-gray-lighter border-gray-dark/20 shadow-md sm:flex sm:items-start  max-w-full sm:max-w-5/6 sm:pr-10'

  return (
    <div className={`${wrapperClassName} ${className}`}>
      <div className="center self-center text-center content-center sm:content-normal sm:self-start p-6 pt-2">
        <InformationCircleIcon
          className="text-jade sm:text-jade/90 inline-block"
          width={40}
        ></InformationCircleIcon>
      </div>
      <div className="italic">{children}</div>
    </div>
  )
}
