import { Fragment, useCallback, useEffect, useState } from 'react'
import { TabItems, ThemeInterface } from '../../lib/interfaces'
import {
  Biomarker,
  GroupedBiomarkers,
  ResultReferral,
} from '../../lib/validators'
import { TabbedMenu } from '../layout/TabbedMenu'
import { ResultTabOverview } from '../pages/result-tabs/ResultTabOverview'
import { ResultTabBiomarkers } from '../pages/result-tabs/ResultTabBiomarkers'
import { ResultTabAttachments } from '../pages/result-tabs/ResultTabAttachments'
import { useLocation, useNavigate } from 'react-router-dom'
import { OrganisationWithBranding } from '../context/OrganisationContext'
import 'react-tooltip/dist/react-tooltip.css'
import { HistoricalBiomarkerResultMap } from '../../lib/results'
import posthog from '../../lib/posthog'
import { ResultHeader } from './ResultHeader'

interface ResultViewerProps {
  resultItem: ResultReferral
  historicalData?: HistoricalBiomarkerResultMap
  profileUuid: string
  urlRegex: string
  org?: OrganisationWithBranding
  theme?: ThemeInterface
  patientName?: string
}

export function ResultBiomarkerViewer({
  resultItem,
  historicalData,
  profileUuid,
  urlRegex,
  org,
  theme,
  patientName,
}: ResultViewerProps) {
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState<string>('')
  const [groupedBiomarkers, setGroupedBiomarkers] = useState<GroupedBiomarkers>(
    []
  )
  const [result, setResult] = useState<ResultReferral>(resultItem)
  const [historicalBiomarkerData, setHistoricalBiomarkerData] = useState<
    HistoricalBiomarkerResultMap | undefined
  >(historicalData)

  const baseTabs: TabItems = posthog.isFeatureEnabled(
    'can-view-new-results-page'
  )
    ? {
        '': {
          name: 'Blood Test Results',
          href: '',
          current: false,
        },
        attachments: {
          name: 'Attachments',
          href: 'attachments',
          current: false,
        },
      }
    : {
        '': {
          name: 'Overview',
          href: '',
          current: false,
        },
        biomarkers: {
          name: 'Biomarkers',
          href: 'biomarkers',
          current: false,
        },
        attachments: {
          name: 'Attachments',
          href: 'attachments',
          current: false,
        },
      }
  const [tabs, setTabs] = useState<TabItems>(baseTabs)

  const location = useLocation()

  const canViewNewResultsPage = posthog.isFeatureEnabled(
    'can-view-new-results-page'
  )

  const getTabs = useCallback(
    (page: string) => {
      Object.entries(tabs).map(([key, val]) => {
        val.current = false
        if (key === page) {
          val.current = true
        }

        return val
      })
      return tabs
    },
    [tabs]
  )

  useEffect(() => {
    setHistoricalBiomarkerData(historicalData)
  }, [historicalData])

  const handlePageLoad = useCallback(
    (pathname: string): void => {
      if (canViewNewResultsPage) {
        const pathSegments = pathname.split('/')
        if (pathSegments.pop() === 'biomarkers') {
          navigate(pathSegments.join('/'))
        }
      }
      const re = new RegExp(urlRegex)
      const matches = re.exec(pathname)
      if (!matches || matches.length === 0) {
        throw new Error('Tab not found')
      }

      const tab = matches.pop()?.replace('/', '')
      if (tab === undefined) {
        throw new Error('Tab not found')
      }

      setCurrentPage(tab)
      const latestTabs = getTabs(tab)
      setTabs(latestTabs)
    },
    [canViewNewResultsPage, getTabs, navigate, urlRegex]
  )

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [location, handlePageLoad])

  useEffect(() => {
    setResult(resultItem)
    if (resultItem && resultItem.biomarkers) {
      transformBiomarkersIntoCategories(resultItem.biomarkers)
    }
  }, [resultItem])

  const transformBiomarkersIntoCategories = (biomarkers: Biomarker[]): void => {
    // console.log("lets transform them here...", biomarkers);
    const categoryGroups: GroupedBiomarkers = []
    const categories = Object.fromEntries(
      new Map(
        biomarkers.map((item) => [item.category?.id, item.category])
      ).entries()
    )
    const categorySlugs = Object.keys(categories)
    categorySlugs.sort()
    // for each category
    // this way is so slow, make it faster in future
    categorySlugs.forEach((category) => {
      const biomarkersFound: Biomarker[] = []
      for (const b in biomarkers) {
        if (biomarkers[b].category?.id === category) {
          biomarkersFound.push(biomarkers[b])
        }
      }

      // pluck the biomarker out
      categoryGroups.push({
        category: categories[category],
        biomarkers: biomarkersFound,
      })
    })

    setGroupedBiomarkers(categoryGroups)
  }

  return (
    <Fragment>
      <ResultHeader patientName={patientName} result={result}></ResultHeader>

      <TabbedMenu
        tabs={tabs}
        currentPage={currentPage}
        theme={theme}
      ></TabbedMenu>
      {currentPage === '' && !canViewNewResultsPage && (
        <ResultTabOverview
          resultReferral={result}
          profile={profileUuid}
        ></ResultTabOverview>
      )}
      {currentPage === '' && canViewNewResultsPage && (
        <ResultTabBiomarkers
          groupedBiomarkers={groupedBiomarkers}
          result={result}
          historicalData={historicalBiomarkerData}
          isResultOwner={result.profileUuid === profileUuid}
          theme={theme}
        ></ResultTabBiomarkers>
      )}
      {currentPage === 'biomarkers' && (
        <ResultTabBiomarkers
          groupedBiomarkers={groupedBiomarkers}
          result={result}
          historicalData={historicalBiomarkerData}
          isResultOwner={result.profileUuid === profileUuid}
          theme={org?.theme || theme}
        ></ResultTabBiomarkers>
      )}
      {currentPage === 'attachments' && (
        <ResultTabAttachments
          resultReferral={result}
          profile={profileUuid}
          org={org}
          theme={org?.theme || theme}
        ></ResultTabAttachments>
      )}
      {currentPage === null && <div>Unknown page</div>}
    </Fragment>
  )
}
