import PKCE from 'js-pkce'
import { delay, parseLocalStorageItem } from './utils'
import { callbackUrl as callbackUrlRoute } from './routes'
import posthog from 'posthog-js'
import * as Sentry from '@sentry/react'
import { actions } from './store'

type Token = string

type Noop = () => void
type NoopTokenInterface = (isLoggedIn: boolean) => void

export interface TokenInterface {
  accessToken?: Token
  refreshToken?: Token
  accessTokenExpiresIn?: number
  refreshTokenExpiresIn?: number
  accessTokenExpiryTime?: number
  refreshTokenExpiryTime?: number
}
// interface JwtToken {
//   exp: number
//   iat: number
//   name: string
//   uuid: string
// }

class AuthStore {
  private static instance: AuthStore
  private tokens?: TokenInterface
  private pkce: PKCE
  private onRedirectCallback: Noop
  private onInitCompleteCallback: NoopTokenInterface
  private tokenCheckInterval: number

  private excludedPaths: string[]

  private authServerUrl = import.meta.env.VITE_AUTH_URL
  private tokenUrl = `${this.authServerUrl}/oauth/token`
  private callbackPath = callbackUrlRoute
  private callbackUrl = `${window.location.origin}${this.callbackPath}`
  private currentPath: string = window.location.pathname
  private clientId = 'bloody-good-tests'

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    // console.log("set the current path", window.location.pathname);
    this.urlChanged(window.location.pathname)
    this.onRedirectCallback = function () {
      // just catch the callback here
    }
    this.onInitCompleteCallback = function () {}
    this.excludedPaths = []
    this.tokenCheckInterval = -1
    this.pkce = new PKCE({
      client_id: this.clientId,
      redirect_uri: this.callbackUrl,
      authorization_endpoint: `${this.authServerUrl}/oauth/authorize`,
      token_endpoint: this.tokenUrl,
      requested_scopes: '*',
      storage: localStorage,
    })
    // enable cors here.
    this.pkce.enableCorsCredentials(true)

    // listen for when the URL changes.
    let url = location.pathname
    document.body.addEventListener(
      'click',
      () => {
        requestAnimationFrame(() => {
          url !== location.pathname && this.urlChanged(location.pathname)
          url = location.pathname
        })
      },
      true
    )
  }
  urlChanged(url: string) {
    // console.log("the url changed", url);
    this.currentPath = url
  }

  public initAuthState() {
    // console.log("excluded paths are", this.excludedPaths);
    // check if we are in the callback route
    if (window.location.pathname === this.callbackPath) {
      const url = new URL(window.location.href)
      const redirectUrl = url.searchParams.get('redirectUrl')
      if (redirectUrl) {
        localStorage.setItem('redirectUrl', redirectUrl)
      }
      this.exchangeCodeForToken()
    }
    // else if (this.excludedPaths.indexOf(window.location.pathname) !== -1) {
    //   // This could cause problems, lets monitor it.
    //   console.info("Excluded route.");
    // }
    else {
      // only hydrate the refresh token on page load.
      const tokensFromLocalStorage: TokenInterface = {
        refreshToken: parseLocalStorageItem('refresh_token') as Token,
        accessTokenExpiresIn: parseLocalStorageItem(
          'at_expires_in',
          'int'
        ) as number,
        accessTokenExpiryTime: parseLocalStorageItem(
          'at_expires_at',
          'int'
        ) as number,
      }
      // console.log("tokens from local storage", tokensFromLocalStorage);
      this.setTokens(tokensFromLocalStorage, true) // true: skip the persist
      this.setupAutomaticTokenRefresh()
      // begin syncing the state.
      this.syncTokenState()

      // setup an interval to refresh the token in the background
      // console.log("we have hydrated the tokens, and they are", this.tokens);
      this.onInitCompleteCallback(false)
    }
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AuthStore {
    if (!AuthStore.instance) {
      AuthStore.instance = new AuthStore()
    }

    return AuthStore.instance
  }

  public setOnRedirectCallback(f: Noop) {
    this.onRedirectCallback = f
  }
  public setOnInitCallback(f: NoopTokenInterface) {
    this.onInitCompleteCallback = f
  }

  public beginLogoutProcess(silent?: boolean) {
    posthog.reset()
    actions.clearUser()
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('at_expires_in')
    localStorage.removeItem('at_expires_at')
    if (!silent) {
      this.tokens = undefined
      this.syncTokenState()
    }
  }

  public async syncTokenState() {
    // console.log("==> syncTokenState");
    //we have nothing, send the user to the login page
    if (!this.tokens) {
      // we need to redirect the user to the login page.
      return this.sendUserToLoginPage()
    }
    // we have no refresh token
    if (!this.tokens.refreshToken) {
      return this.sendUserToLoginPage()
    }
    // now we process the actual access token to make sure it hasn't expired.
    // console.log("decoding ", this.tokens.accessToken);
    try {
      if (this.tokens.refreshToken && !this.tokens.accessToken) {
        // if we have a refresh token but not access token, just refresh it
        // this happens on a page refresh, or a returning user
        await this.refreshTokens()
      } else if (this.tokens.accessTokenExpiryTime) {
        if (
          this.hasTokenExpired(this.tokens.accessTokenExpiryTime || 0) ||
          this.isTokenAboutToExpire(this.tokens.accessTokenExpiryTime || 0)
        ) {
          // console.log("it has expired");
          try {
            await this.refreshTokens()
          } catch (e) {
            // console.log("failed to refresh the token, restart?", e);
            return this.sendUserToLoginPage()
          }
        }
      }
      // console.info("token looks good now..., should work fine?");
      this.onInitCompleteCallback(true)
      // do nothing, we have a valid token that hasn't expired.
    } catch (e) {
      console.log('Parsing jwt failed, send the user to the login page.', e)
      Sentry.captureException(e)

      return this.sendUserToLoginPage()
    }
  }

  setupAutomaticTokenRefresh() {
    // console.log("begin automatic token refresh");
    // console.log('clearing and setting new timeout')
    window.clearInterval(this.tokenCheckInterval)
    // this.refreshTimeout = window.setTimeout(this.syncTokenState, this.tokens.accessTokenExpiryTime - 30) // remove 30 seconds of it, so we refresh before it expires
    this.tokenCheckInterval = window.setInterval(
      this.syncTokenState.bind(this),
      30 * 1000
    ) // just check every 30 seconds if we need to refresh our token
  }

  // commented as it breaks for names with a quote
  // parseJwtToken(token: Token) {
  //   return JSON.parse(atob(token.split('.')[1])) as JwtToken
  // }
  hasTokenExpired(expiry: number): boolean {
    const exp = expiry * 1000
    return Date.now() > exp
  }
  // if its going to expire in the next minute, refresh it.
  isTokenAboutToExpire(expiry: number): boolean {
    const exp = expiry * 1000
    // console.log((exp - (60 * 1000)) / 1000, tokenPayload.exp)
    return Date.now() > exp - 60 * 3 * 1000 // see if its going to expire in the next 3 minutes
  }

  exchangeCodeForToken() {
    // console.log("exchange the token");
    this.pkce
      .exchangeForAccessToken(window.location.href)
      .then(async (resp) => {
        this.setTokens({
          accessToken: resp.access_token,
          refreshToken: resp.refresh_token,
          accessTokenExpiresIn: resp.expires_in,
          refreshTokenExpiresIn: resp.refresh_expires_in,
          accessTokenExpiryTime:
            Math.floor(Date.now() / 1000) + resp.expires_in,
          refreshTokenExpiryTime:
            Math.floor(Date.now() / 1000) + resp.refresh_expires_in,
        })
        // console.log("call the onRedirectCallback");
        this.onRedirectCallback()
        this.onInitCompleteCallback(true)
        this.setupAutomaticTokenRefresh()
      })
  }

  async refreshTokens(): Promise<void> {
    return new Promise((resolve, reject) => {
      // console.log("refresh the token");
      fetch(this.tokenUrl, {
        body: JSON.stringify({
          refresh_token: this.tokens?.refreshToken,
          grant_type: 'refresh_token',
          client_id: this.clientId,
        }),
        mode: 'cors',
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((response) => {
          return response.json()
        })
        .then((resp) => {
          if (resp.error === true) {
            return reject(resp.type)
          }
          this.setTokens({
            accessToken: resp.access_token,
            refreshToken: resp.refresh_token,
            accessTokenExpiresIn: resp.expires_in,
            refreshTokenExpiresIn: resp.refresh_expires_in,
            accessTokenExpiryTime:
              Math.floor(Date.now() / 1000) + resp.expires_in,
            refreshTokenExpiryTime:
              Math.floor(Date.now() / 1000) + resp.refresh_expires_in,
          })
          resolve()
        })
    })
  }

  public sendUserToLoginPage(force?: boolean, redirectUrl?: string) {
    // if the current path is in the exclusions, don't redirect as it would be annoying given the user is logged out
    // anyway
    // console.log(this.excludedPaths, this.currentPath);
    // this.setTokens({});

    let matchedUrl = false
    for (const i in this.excludedPaths) {
      const re = new RegExp(this.excludedPaths[i])
      const match = re.exec(this.currentPath)
      if (match) {
        matchedUrl = true
        break
      }
      // console.log('the match is => ', match, ' for ', this.excludedPaths[i])
    }
    console.log('sendUserToLoginPage', force)
    if (force) {
      window.location.href =
        this.pkce.authorizeUrl() +
        (redirectUrl ? `&redirectUrl=${redirectUrl}` : '')
      return
    }
    if (this.excludedPaths.indexOf(this.currentPath) > -1 || matchedUrl) {
      console.info(
        'We got a sendUserToLoginPage call, but we are on a excluded url, so ignore for now'
      )
      return
    }
    // console.log('redirect the user now')
    window.location.href =
      this.pkce.authorizeUrl() +
      (redirectUrl ? `&redirectUrl=${redirectUrl}` : '')
  }

  /**
   * Finally, any singleton should define some business logic, which can be
   * executed on its instance.
   */
  public setTokens(
    newTokens: TokenInterface,
    skipPersist: boolean = false
  ): void {
    this.tokens = {
      ...this.tokens,
      ...newTokens,
    }
    if (skipPersist) return
    // persist the refresh token into local storage
    if (newTokens.refreshToken) {
      localStorage.setItem('refresh_token', newTokens.refreshToken)
    }
    if (newTokens.accessTokenExpiresIn) {
      // persist when the accesstoken expires
      localStorage.setItem('at_expires_in', `${newTokens.accessTokenExpiresIn}`)
    }
    if (newTokens.accessTokenExpiryTime) {
      // persist when the accesstoken expires
      localStorage.setItem(
        'at_expires_at',
        `${newTokens.accessTokenExpiryTime}`
      )
    }
  }

  public async getAccessToken(): Promise<Token | undefined> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      if (!this.tokens?.accessToken) {
        let token = undefined
        const limit = 20
        let x = 0
        // lets just busy loop while we wait for the access token to be populated
        // lets wait a second for the token.
        while (token === undefined && x < limit) {
          await delay(500)
          token = this.tokens?.accessToken
          x += 1
        }
        resolve(this.tokens?.accessToken)
      }
      resolve(this.tokens?.accessToken)
    })
  }
  public getTokens(): TokenInterface | undefined {
    return this.tokens
  }

  public addExcludedPaths(paths: string[]): void {
    this.excludedPaths = [...this.excludedPaths, ...paths]
  }
}

AuthStore.getInstance()

export default AuthStore.getInstance()
