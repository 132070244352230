import { Menu, Transition } from '@headlessui/react'

import { Fragment, PropsWithChildren, ReactNode } from 'react'
import { ItemType, MenuItem } from '../header/ProfileHeader'
import { Link } from 'react-router-dom'
import { classNames } from '../../lib/utils'
import { buttonClasses } from '../form/button/Button'

interface DropdownMenuProps extends PropsWithChildren {
  menuItems: MenuItem[]
  disabled?: boolean
  disabledInfo?: string | ReactNode
}

export default function DropdownMenu({
  menuItems,
  children,
  disabled = false,
  disabledInfo,
}: DropdownMenuProps) {
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className={`-mt-1.5 flex items-center p-1.5 ml-auto  ${buttonClasses}`}
        disabled={disabled}
      >
        <span className="sr-only">Open menu</span>
        {children}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 left-auto sm:right-0 sm:left-auto z-10 mt-1 min-w-48 origin-top-right rounded-md bg-white py-1 shadow-xl ring-1 ring-gray-dark/10 focus:outline-none max-h-[80dvh] overflow-x-auto dark:bg-dark-gray-lighter">
          {menuItems.map((item, k) => (
            <Menu.Item key={`menu-item${k}`}>
              {({ close }) => (
                <Fragment>
                  {item.type === ItemType.Divider && (
                    <hr className="my-2 dark:border-dark-gray-light"></hr>
                  )}
                  {item.type === ItemType.GroupTitle && (
                    <small className="uppercase text-gray-dark px-3 py-1 text-xs">
                      {item.name}
                    </small>
                  )}
                  {item.type === ItemType.Item && (
                    <Fragment>
                      {!item.disabled && (
                        <Link
                          to={item.href ? item.href : window.location.href}
                          onClick={() => {
                            if (item.onClick) {
                              item.onClick()
                            }
                            close()
                          }}
                          className={classNames(
                            'block px-3 pb-1 text-sm leading-6 text-black/70 dark:text-white/70  dark:hover:text-white  hover:bg-gray-light hover:text-black hover:dark:text-white hover:dark:bg-dark-gray-light',
                            item.className ? item.className : '',
                            item.current
                              ? 'bg-gray-light text-black dark:text-white dark:bg-dark-gray-light'
                              : ''
                          )}
                        >
                          {item.image && item.image}
                          {!item.image && item.icon && (
                            <item.icon
                              className="h-4 w-4 shrink-0 inline-block mr-2"
                              aria-hidden="true"
                            ></item.icon>
                          )}
                          {item.name}
                        </Link>
                      )}
                      {item.disabled && (
                        <div
                          className={classNames(
                            'block px-3 pb-1 text-sm leading-6 text-black/50 dark:text-white/60',
                            item.className ? item.className : ''
                          )}
                        >
                          {item.image && item.image}
                          {!item.image && item.icon && (
                            <item.icon
                              className="h-4 w-4 shrink-0 inline-block mr-2"
                              aria-hidden="true"
                            ></item.icon>
                          )}
                          {item.name}
                        </div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
      {disabled && disabledInfo && <Fragment>{disabledInfo}</Fragment>}
    </Menu>
  )
}
