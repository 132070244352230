import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react'
import { classNames, formatMoney, getNiceDate, months } from '../../lib/utils'

import PageLoader from '../layout/PageLoader'

import { getPayoutHistory } from '../../lib/organisation'
import { Payout, PayoutHistoryResponse } from '../../lib/validators'
import { PageHeading } from '../layout/PageHeading'
import { BasicTableGroupProps, BasicTableProps } from '../layout/BasicTable'
import { StatusTagColors } from '../../lib/interfaces'
import { StatusTag } from '../layout/StatusTag'
import { useOnceCall } from '../../lib/hooks'
import { OrganisationTheme } from '../../lib/validators'
import { InfoSection } from '../ui/InfoSection'
import { getCreateTestBundleUrl } from '../../lib/routes'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

const colClasses = [
  'text-left border-b-1 border-gray-dark content-center pt-4 col-span-2 sm:col-span-2', //product name
  'text-left text-left sm:text-center text-gray-semi-dark col-span-2 sm:col-span-2 content-center', //customer name
  'text-left text-left sm:text-center text-gray-semi-dark col-span-2 sm:col-span-2 content-center', //
  'text-left text-left sm:text-center text-gray-semi-dark col-span-2 sm:col-span-2 content-center', //price
  'text-left text-left sm:text-center text-gray-semi-dark col-span-2 sm:col-span-2 content-center', //purchase date
  'text-left sm:text-right text-gray-semi-dark col-span-2 sm:col-span-2 content-center pb-4', // status
  // "sm:text-right text-gray-semi-dark col-span-3 sm:col-span-3", // status
]

const DEFAULT_FETCH_COUNT = 20

interface PayoutViewerProps {
  organisationUuid: string
  organisationName: string
  organisationId: string
  organisationTheme?: OrganisationTheme
  unpaidCallback?: (items: Payout[]) => void
  pageSize?: number
  actions?: ReactNode
  displayHelpInformation?: boolean
}

export function PayoutViewer({
  organisationUuid,
  organisationTheme,
  organisationId,
  organisationName,
  unpaidCallback,
  actions,
  pageSize = DEFAULT_FETCH_COUNT,
  displayHelpInformation = true,
}: PayoutViewerProps) {
  const intercom = useIntercom()
  const [loading, setLoading] = useState(true)
  const [payouts, setPayouts] = useState<Payout[]>([])
  const [unpaidPayout, setUnpaidPayout] = useState<number>()
  const [estimatedPayoutDate, setEstimatedPayoutDate] = useState<string | null>(
    null
  )
  const [cursor, setCursor] = useState<string | null | undefined>(undefined)
  const [tableData, setTableData] = useState<BasicTableProps | undefined>()

  const fetchPayouts = useCallback(() => {
    getPayoutHistory(organisationUuid, pageSize, cursor).then(handleResults)
  }, [cursor, organisationUuid, pageSize])

  // run only once
  useOnceCall(() => {
    if (cursor === undefined) {
      setLoading(true)
      fetchPayouts()
    }
  })

  const handleResults = (d: PayoutHistoryResponse) => {
    setPayouts((prev) => [...prev, ...d.payouts])

    setCursor(d.cursor.nextCursor)
    if (d.unpaidTotal !== 0) {
      setUnpaidPayout(d.unpaidTotal)
      setEstimatedPayoutDate(d.estimatedPaidOutAt)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (payouts) {
      massageTestDataIntoTable(payouts)
      unpaidCallback &&
        unpaidCallback(payouts.filter((item) => item.status === 'UNPAID'))
    }
  }, [payouts, unpaidCallback])

  const massageTestDataIntoTable = (payouts: Payout[]) => {
    const result = []
    const resultsByYear: Record<string, BasicTableGroupProps> = {}
    // console.log("referrals", referrals);
    for (const payout of payouts) {
      const year = payout.createdAt.getFullYear()

      // if (year !== currentYear) {
      if (!resultsByYear[year]) {
        resultsByYear[year] = {
          groupLabel: year.toString(),
          rows: [],
        }
      }
      // console.log("files", referrals[i].fileUuids);

      resultsByYear[year].rows.push([
        `${payout.productName}`,
        `${payout.purchasedBy?.firstName} ${payout.purchasedBy?.lastName}`,
        `${getNiceDate(payout.createdAt.getDate())} ${months[payout.createdAt.getMonth()]} ${payout.createdAt.getFullYear()}`,
        formatMoney(payout.payoutAmount / 100, 'aud'),
        <StatusTag
          label={payout.status}
          color={
            payout.status === 'PAID'
              ? StatusTagColors.JADE
              : payout.status === 'PENDING'
                ? StatusTagColors.GRAY
                : StatusTagColors.YELLOW
          }
        ></StatusTag>,
        payout.paidOutAt === null ? payout.estimatedPaidOutAt : '',
      ])
    }
    const keys = Object.keys(resultsByYear)
    keys.sort().reverse()
    for (const y in keys) {
      result.push(resultsByYear[keys[y]])
    }
    console.log('the results are', result)
    if (result.length > 0) {
      setTableData({
        columns: [
          'Product Name',
          'Customer Name',
          'Date',
          'Total',
          'Status',
          'Scheduled Payout Date',
        ],
        data: result,
      })
    }
  }

  const loadMore = () => {
    fetchPayouts()
  }

  const openIntercomWithMessage = () => {
    intercom.showNewMessage(
      `Organisation Name: ${organisationName}\nOrganisationId:${organisationId}\n\nMy bank details are:\nAccount Name:\nAccount Number:\nBSB:\n`
    )
  }

  return (
    <Fragment>
      {loading && (
        <PageLoader theme={organisationTheme || undefined}></PageLoader>
      )}
      {!loading && (
        <div>
          {displayHelpInformation && (
            <div>
              <InfoSection className="mb-6">
                <p className="mb-2">
                  <b>Welcome to your payouts page</b>
                </p>
                <p className="mb-2">
                  Here, you'll find all the earnings you're set to receive.
                </p>
                <div className="mb-2">
                  <b>How to get paid:</b>
                  <br />
                  <ul className="pl-4">
                    <li className="list-decimal">
                      <b>
                        <Link
                          to={getCreateTestBundleUrl(organisationId)}
                          className={classNames(
                            'hover:underline',
                            organisationTheme
                              ? `text-[--link-color]`
                              : `text-blood`
                          )}
                          style={
                            organisationTheme
                              ? ({
                                  '--link-color': organisationTheme.linkColor,
                                } as React.CSSProperties)
                              : {}
                          }
                        >
                          Create a Test Bundle
                        </Link>
                        :
                      </b>{' '}
                      Start by putting together a test bundle.
                    </li>
                    <li className="list-decimal">
                      <b>
                        <a
                          href="https://support.bloodygoodtests.com.au/en/articles/10292658-how-to-add-a-custom-service-fee"
                          target="bgt-support"
                          className={classNames(
                            'hover:underline',
                            organisationTheme
                              ? `text-[--link-color]`
                              : `text-blood`
                          )}
                          style={
                            organisationTheme
                              ? ({
                                  '--link-color': organisationTheme.linkColor,
                                } as React.CSSProperties)
                              : {}
                          }
                        >
                          Add a Custom Service Fee
                        </a>
                        :{' '}
                      </b>
                      During the test-building process, add a custom service
                      fee. The fee you set will be added to the total price of
                      your test package.
                    </li>
                    <li className="list-decimal">
                      <b
                        onClick={() => openIntercomWithMessage()}
                        className={classNames(
                          'hover:underline cursor-pointer',
                          organisationTheme
                            ? `text-[--link-color]`
                            : `text-blood`
                        )}
                        style={
                          organisationTheme
                            ? ({
                                '--link-color': organisationTheme.linkColor,
                              } as React.CSSProperties)
                            : {}
                        }
                      >
                        Notify us of your bank details
                      </b>
                    </li>
                  </ul>
                </div>

                <div className="mb-2">
                  <b>Key things to remember:</b>
                  <ul className="pl-4">
                    <li className="list-disc">
                      <b>Weekly Payouts:</b> We send out payouts every Friday.
                      If Friday falls on a Queensland public holiday, no
                      worries—we’ll get your payout to you the next business
                      day.
                    </li>
                    <li className="list-disc">
                      <b>Service Fee:</b> Bloody Good Tests takes a small 6% fee
                      from the custom service fee you add to each test bundle.
                      This helps us keep delivering top-notch service.
                    </li>
                    <li className="list-disc">
                      <b>Eligibility:</b> Payouts are only processed for test
                      bundles that have received at least one completed result.
                      This ensures secure payouts and prevents refunds after
                      we've already paid out your earnings.
                    </li>
                  </ul>
                </div>
                <p className="mb-2">
                  That's it! Now go on and make those bundles earn their keep.
                </p>
              </InfoSection>
            </div>
          )}
          {unpaidPayout !== undefined && unpaidPayout > 0 && (
            <div>
              {actions && <div>{actions}</div>}
              <PageHeading
                title="Next Payout"
                className="text-black/70 dark:text-white"
              >
                <Fragment>
                  <div className="text-2xl font-bold text-black/70 dark:text-white">
                    {formatMoney(unpaidPayout / 100, 'aud')}
                  </div>
                  <span className="text-black/70 dark:text-white">
                    Scheduled for {estimatedPayoutDate}
                  </span>
                </Fragment>
              </PageHeading>
            </div>
          )}
          <div>
            <Fragment>
              <PageHeading title="History"></PageHeading>
              {tableData && tableData.data && (
                <Fragment>
                  <div
                    key={`test-table`}
                    className="grid grid-cols-3 sm:grid-cols-12 mt-10"
                  >
                    {/* <GridTableHeader className="col-span-3 sm:col-span-8">
                Pathology Report
              </GridTableHeader> */}
                    <div
                      className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 hidden sm:block ${colClasses[0]}`}
                    >
                      {tableData.columns[0]}
                    </div>
                    <div
                      className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[1]}`}
                    >
                      {tableData.columns[1]}
                    </div>
                    <div
                      className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[2]}`}
                    >
                      {tableData.columns[2]}
                    </div>
                    <div
                      className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm px-2 py-1 sm:p-3 hidden sm:block ${colClasses[3]}`}
                    >
                      {tableData.columns[3]}
                    </div>
                    <div
                      className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[4]}`}
                    >
                      {tableData.columns[4]}
                    </div>
                    <div
                      className={`bg-gray-light text-gray-semi-dark border-b border-gray-medium font-semibold text-sm  px-2 py-1 sm:p-3 hidden sm:block ${colClasses[5]}`}
                    >
                      {tableData.columns[5]}
                    </div>

                    {tableData.data.map((group, g) => (
                      <Fragment key={`group-fragment-${g}`}>
                        {tableData.data.length > 1 && (
                          <div
                            key={`group-label-${g}`}
                            className={`px-2 py-1 sm:p-3 sm:px-4 col-span-3 sm:col-span-12 text-sm text-gray-semi-dark bg-gray-light font-bold`}
                          >
                            {group.groupLabel}
                          </div>
                        )}
                        {group.rows.map((row, k) =>
                          row.map((item, i) => (
                            <div
                              key={`item-row-${k}-${i}`}
                              className={`py-1 sm:p-3 sm:px-4 text-md text-black ${
                                k % 2 === 1 ? 'bg-gray-light' : ''
                              } rounded-tl-lg rounded-bl-lg ${colClasses[i]} ${
                                i !== row.length - 1
                                  ? ''
                                  : 'last-row sm:border-none border-b border-gray !rounded-none'
                              } ${k === group.rows.length - 1 ? 'border-none' : ''}`}
                            >
                              <div className="inline-block align-middle">
                                {item}
                              </div>
                            </div>
                          ))
                        )}
                      </Fragment>
                    ))}
                  </div>
                  {cursor && (
                    <div
                      className="rounded-md border border-gray-light px-4 py-2 text-blood cursor-pointer hover:text-blood/80 inline-block ml-auto mr-auto"
                      onClick={() => loadMore()}
                    >
                      Load more
                    </div>
                  )}
                </Fragment>
              )}
              {!tableData && <div>No payout records found</div>}
            </Fragment>
          </div>
        </div>
      )}
    </Fragment>
  )
}
