import { setDocumentTitle } from '../../../lib/utils'

import AdminPage from './AdminPage'
import { PageHeading } from '../../layout/PageHeading'
import PageSection from '../../layout/PageSection'
import { Fragment, useEffect, useState } from 'react'
import { Organisation } from '../../../lib/validators'
import { getOrganisations } from '../../../lib/organisation'
import PageLoader from '../../layout/PageLoader'
import { Link } from 'react-router-dom'
import { adminOrgsUrl } from '../../../lib/routes'
import { getSizedImage, OrgImageTypes } from '../../../lib/image'

export function AdminOrgsPage() {
  const [organisations, setOrganisations] = useState<Organisation[]>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setDocumentTitle('Admin Orgs', 'CLEAN')
    // load the orgs on page load
    if (!organisations) {
      getOrganisations().then((orgs) => {
        setOrganisations(orgs)
        setLoading(false)
      })
    }
  }, [])

  return (
    <AdminPage>
      <PageSection>
        <PageHeading title={'Admin Orgs Page'}></PageHeading>
        {loading && <PageLoader></PageLoader>}
        {!loading && (!organisations || organisations.length === 0) && (
          <div>no orgs found.</div>
        )}
        {!loading && organisations && (
          <div>
            {organisations &&
              organisations.map((org) => (
                <div key={org.uuid}>
                  <Link
                    to={`${adminOrgsUrl}/${org.uuid}`}
                    className="text-blood mb-4 block hover:bg-blood/5 rounded-lg p-4"
                  >
                    <div className="flex min-h-16 content-center">
                      <div className="mr-4 min-w-16 content-center text-gray-dark">
                        {org.theme?.logoSquare && (
                          <img
                            className="max-h-16 max-w-32"
                            src={getSizedImage(
                              OrgImageTypes.Favicon,
                              org.theme.logoSquare
                            )}
                          />
                        )}
                        {!org.theme?.logoSquare && <Fragment>-</Fragment>}
                      </div>
                      <div className="align-middle content-center font-bold">
                        {org.name}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        )}
      </PageSection>
    </AdminPage>
  )
}
