import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import FullPageLoader from '../layout/FullPageLoader'
import HealiusMarker from '../../../public/img/map/healius-map-marker.png?url'
import LavertyLogo from '../../../public/img/map/laverty-logo.svg?url'
import TMLLogo from '../../../public/img/map/tml-logo.svg?url'
import QMLLogo from '../../../public/img/map/qml-logo.svg?url'
import DorevitchLogo from '../../../public/img/map/dorevitch-logo.svg?url'
import AbbottLogo from '../../../public/img/map/abbott-logo.jpg?url'
import WDPLogo from '../../../public/img/map/wdp-logo.svg?url'
import ACLMarker from '../../../public/img/map/acl-map-marker.png?url'
import ACLLogo from '../../../public/img/map/acl-logo.png?url'
import cyte4Marker from '../../../public/img/map/4cyte-map-marker.png?url'
import cyte4Logo from '../../../public/img/map/4cyte-logo.jpg?url'
import wiseMarker from '../../../public/img/map/wise-map-marker.png?url'
import wiseLogo from '../../../public/img/map/wise-logo.png?url'
import BgtBloodDropSmall from '../../../public/img/bgt-drop-small.png?url'
import Page from '../Page'
import { useDebouncedCallback } from 'use-debounce'
import { APIProvider, Map, useMap } from '@vis.gl/react-google-maps'
import { useLocation } from 'react-router-dom'
import { authFetch } from '../../providers/AuthProvider'
import { z } from 'zod'
// import { onChangeInterface } from "../form/TextInputField";
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Transition } from '@headlessui/react'
import { LinkButton } from '../form/button/LinkButton'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import toast from 'react-hot-toast'
import { classNames, setDocumentTitle } from '../../lib/utils'
import { useIntercom } from 'react-use-intercom'
import TextInputField, { onChangeInterface } from '../form/TextInputField'
import posthog from '../../lib/posthog'
import { useBranding } from '../../lib/store'

const bgtLocationSchema = z.object({
  id: z.string().optional(),
  name: z.string(),
  lat: z.coerce.number(),
  lng: z.coerce.number(),
  address: z.string(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  region: z.union([z.string(), z.any()]),
  postcode: z.coerce.number(),
  phone: z.string().nullable(),
  phoneLink: z.string().nullable(),
  provider: z.union([
    z.literal('4cyte'),
    z.literal('wise'),
    z.literal('acl'),
    z.literal('healius'),
  ]),
  providerName: z.string(),
  subProvider: z.string(),
  subProviderName: z.string(),
  notes: z.string().nullable(),
  hours: z.object({
    mon: z.string(),
    tue: z.string(),
    wed: z.string(),
    thu: z.string(),
    fri: z.string(),
    sat: z.string(),
    sun: z.string(),
  }),
  lunchHours: z.string().nullable(),
  specialHours: z.string().nullable(),
  url: z.string().url().optional(),
})
const bgtLocationListSchema = z.array(bgtLocationSchema)
type BGTLocation = z.infer<typeof bgtLocationSchema>
type BGTLocationList = z.infer<typeof bgtLocationListSchema>

const positionCacheSchema = z.object({
  lat: z.number(),
  lng: z.number(),
  zoom: z.number(),
})
type PositionCache = z.infer<typeof positionCacheSchema>

// load out of local storage;
let positionCache: PositionCache
if (window.localStorage) {
  const positionCacheItem = localStorage.getItem('mapPositionCache')
  try {
    if (positionCacheItem) {
      positionCache = positionCacheSchema.parse(JSON.parse(positionCacheItem))
    }
  } catch (e) {
    console.info('no position cache, ignore.', e)
  }
}

export default function CollectionCentresPage() {
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const [isChromeless, setChromeless] = useState(false)
  const [locations, setLocations] = useState<BGTLocationList | null>(null)
  const intercom = useIntercom()
  const [currentAddress, setCurrentAddress] = useState(
    'Enter postcode or suburb'
  )

  const [mapCenter, setMapCenter] = useState({
    lat: positionCache ? positionCache.lat : -25.562265,
    lng: positionCache ? positionCache.lng : 134.342319,
  })
  const [zoomLevel, setZoomLevel] = useState(
    positionCache ? positionCache.zoom : 4
  )

  // this page is just a placeholder so we have a route for /callback,
  // all of the logic is actually happening in the AppWrapper
  useEffect(() => {
    console.log('collection centre page loaded')

    setDocumentTitle('Collection Centres')

    // try to get the location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setMapCenterPosition)
    }

    getCollectionCentres()
  }, [])

  const setMapCenterPosition = (position: GeolocationPosition): void => {
    setMapCenter({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    })
    setZoomLevel(12)

    if (window.localStorage) {
      localStorage.setItem(
        'mapPositionCache',
        JSON.stringify({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          zoom: 12,
        })
      )
    }
  }

  const getCollectionCentres = async () => {
    // `${import.meta.env.VITE_API_URL}/collection-centres`,
    const result = await authFetch(
      import.meta.env.VITE_ENV === 'development'
        ? '/public/collection-centres.json'
        : `/data/collection-centres.json`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      }
    )
    const data = result.data
    try {
      const centreLocations = bgtLocationListSchema.parse(data)
      const filteredLocations: BGTLocation[] = []
      for (let i = 0; i < centreLocations.length; i += 1) {
        centreLocations[i].id = `idx-${i}-${centreLocations[i].provider}`
        if (['4cyte', 'wise'].includes(centreLocations[i].provider)) {
          filteredLocations.push(centreLocations[i])
        } else if (
          centreLocations[i].provider === 'healius' &&
          posthog.isFeatureEnabled('can-view-healius-locations')
        ) {
          filteredLocations.push(centreLocations[i])
        } else if (
          centreLocations[i].provider === 'acl' &&
          posthog.isFeatureEnabled('can-view-acl-locations')
        ) {
          filteredLocations.push(centreLocations[i])
        }
      }
      setLocations(filteredLocations)
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    } catch (e) {
      setLocations([])
      setLoading(false)
      toast.error('Unable to retrieve collection centre locations.', {
        duration: 5000,
      })
    }
  }
  useEffect(() => {
    if (isChromeless) {
      intercom.update({
        hideDefaultLauncher: true,
      })
    } else {
      intercom.update({
        horizontalPadding: 55,
      })
    }
  }, [isChromeless, intercom])

  useEffect(() => {
    console.log('map center is', mapCenter)
  }, [mapCenter])

  // test for chromeless
  const handlePageLoad = useCallback(
    (pathname: string): void => {
      // console.log("++++ handle page load");
      // handle chromeless browser
      console.log('+++++ the page loaded', pathname, location)
      const baseSearchStr = location.search.substring(1, location.search.length)
      console.log('search is:', baseSearchStr)
      const searchParts = baseSearchStr.split('&')
      for (let i = 0; i < searchParts.length; i += 1) {
        const keyval = searchParts[i].split('=')
        if (keyval[0] === 'chromeless') {
          console.log('we are chromeless')
          setChromeless(true)
          break
        }
      }
    },
    [location]
  )

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [handlePageLoad, location])

  const handleElementChange = (e: onChangeInterface) => {
    // console.log('element changed', e)
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ address: e.strValue }, function (results, status) {
      console.log(results)
      if (
        status == google.maps.GeocoderStatus.OK &&
        results &&
        results.length > 0
      ) {
        setMapCenter({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        })
        setTimeout(() => setZoomLevel(16), 500)
      }
    })
    setCurrentAddress(e.strValue)
  }

  return (
    <Page fullViewport={true}>
      <div
        className={`flex ${isChromeless ? 'h-dvh' : 'h-[calc(100dvh-4rem)]'}`}
      >
        <APIProvider version="beta" apiKey={import.meta.env.VITE_MAPS_API_KEY}>
          <Map
            reuseMaps={true}
            className="h-full w-full"
            defaultCenter={mapCenter}
            // center={mapCenter}
            defaultZoom={12}
            gestureHandling={'greedy'}
            disableDefaultUI={false}
            fullscreenControl={false}
            streetViewControl={false}
            styles={[
              {
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#f5f5f5',
                  },
                ],
              },
              {
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#616161',
                  },
                ],
              },
              {
                elementType: 'labels.text.stroke',
                stylers: [
                  {
                    color: '#f5f5f5',
                  },
                ],
              },
              {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#bdbdbd',
                  },
                ],
              },
              {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#eeeeee',
                  },
                ],
              },
              {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#757575',
                  },
                ],
              },
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#e5e5e5',
                  },
                ],
              },
              {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#9e9e9e',
                  },
                ],
              },
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#ffffff',
                  },
                ],
              },
              {
                featureType: 'road.arterial',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#757575',
                  },
                ],
              },
              {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#dadada',
                  },
                ],
              },
              {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#616161',
                  },
                ],
              },
              {
                featureType: 'road.local',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#9e9e9e',
                  },
                ],
              },
              {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#e5e5e5',
                  },
                ],
              },
              {
                featureType: 'transit.station',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#eeeeee',
                  },
                ],
              },
              {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                  {
                    color: '#c9c9c9',
                  },
                ],
              },
              {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [
                  {
                    color: '#9e9e9e',
                  },
                ],
              },
            ]}
            // mapId={"my-map-id"}
          />
          <div className="absolute min-w-[60dvw] center ml-[10px] mr-4 mt-16 sm:ml-[220px] sm:min-w-[40%] sm:mt-3">
            <TextInputField
              name="address"
              id="address"
              type="address"
              placeholder="Enter postcode or suburb"
              handleChange={handleElementChange}
              addressAutocompleteTypes={['(regions)']}
              value={currentAddress}
              className="shadow-md text-md"
            ></TextInputField>
          </div>
          <CollectionCentreMapComponent
            center={mapCenter}
            zoom={zoomLevel}
            locations={locations}
            chromeless={isChromeless}
          ></CollectionCentreMapComponent>
        </APIProvider>
      </div>
      {loading && <FullPageLoader></FullPageLoader>}
    </Page>
  )
}

interface CollectionCentreMapComponentProps extends PropsWithChildren {
  center: { lat: number; lng: number }
  zoom: number
  locations: BGTLocationList | null
  chromeless: boolean
}

interface MapPoint {
  lat: number | undefined
  lng: number | undefined
}

interface Bounds {
  tr: MapPoint
  bl: MapPoint
}

const CollectionCentreMapComponent = ({
  center,
  zoom,
  children,
  locations,
  chromeless,
}: CollectionCentreMapComponentProps) => {
  const map = useMap()
  const [isMapSetup, setMapSetupComplete] = useState(false)
  const [bounds, setBounds] = useState<Bounds | undefined>(undefined)
  const [markers, setMarkers] = useState<google.maps.Marker[]>([])
  const [homeMarker, setHomeMarker] = useState<google.maps.Marker>()
  const [selectedMarker, setSelectedMarker] = useState<BGTLocation>()
  const [userDragged, setUserDragged] = useState(false)
  const [markerCluster, setMarkerCluster] = useState<unknown>()
  const [pointsInViewport, setPointsInViewport] = useState<
    BGTLocationList | undefined
  >(undefined)
  // const [locationsInSidebar, setLocationsInSidebar] = useState<
  //   BGTLocationList | undefined
  // >(undefined);
  // const [showLocationList, setShowLocationList] = useState(false);

  const mapMoved = () => {
    // filter the locations by the position

    if (!map) {
      return
    }
    if (!map.getBounds) {
      return
    }
    setBounds({
      tr: {
        lat: map!.getBounds()?.getNorthEast().lat(),
        lng: map!.getBounds()?.getNorthEast().lng(),
      },
      bl: {
        lat: map!.getBounds()?.getSouthWest().lat(),
        lng: map!.getBounds()?.getSouthWest().lng(),
      },
    })
  }

  useEffect(() => {
    map?.setZoom(zoom)
  }, [map, zoom])

  const zoomChanged = () => {
    // filter the locations by the position

    if (!map) {
      return
    }
    console.log('zoom changed.', map.getZoom())
    setUserDragged(true)
    // setShowLocationList((map.getZoom() || 16) >= 10);
  }
  const userDidDrag = () => {
    // filter the locations by the position

    setUserDragged(true)
  }

  const debouncedMapMove = useDebouncedCallback(mapMoved, 300)
  const debouncedZoomChanged = useDebouncedCallback(zoomChanged, 50)
  const debouncedDragChanged = useDebouncedCallback(userDidDrag, 50)

  const drawPinAtPos = useCallback(
    (
      item: BGTLocation | null,
      position: google.maps.LatLng,
      icon?: string,
      map?: google.maps.Map
    ): google.maps.Marker => {
      const newMarker = new google.maps.Marker({
        map: map ? map : undefined,
        position: position,
        icon: icon || '',
        // shadow: icon.shadow,
      })
      newMarker.addListener('click', clickedMarkerFactory(item))
      // newMarker.ad
      return newMarker
    },
    [map]
  )

  type MarkerClickEvent = () => void

  const clickedMarkerFactory = (data: BGTLocation | null): MarkerClickEvent => {
    // just curry the function here so we can capture the state later.
    return function () {
      clickedMarker(data)
    }
  }
  const clickedMarker = (point: BGTLocation | null) => {
    console.log('the zoom is', map?.getZoom())
    if (map && (map?.getZoom() as number) < 8) {
      map?.setZoom(11)
    }
    setSelectedMarker(point || undefined)
  }

  useEffect(() => {
    if (!selectedMarker) return
    if (!map) return

    // setTimeout(() => {
    if (selectedMarker) {
      document.getElementById(selectedMarker.id!)?.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      })
    }
    // }, 500);
    if (selectedMarker) {
      map?.panTo({
        lat: selectedMarker.lat,
        lng: selectedMarker.lng,
      })
    }
  }, [selectedMarker, map])

  // const clearMarkers = (): void => {
  //   if (!markers) return;
  //   for (let i = 0; i < markers.length; i += 1) {
  //     markers[i].setMap(null);
  //   }
  // };
  const clearHomeMarker = (): void => {
    if (!homeMarker) return
    homeMarker.setMap(null)
  }

  useEffect(() => {
    map?.panTo(center)
  }, [center])

  const getMarkerForSubProvider = (provider: string) => {
    switch (provider) {
      case '4cyte':
        return cyte4Marker
      case 'wise':
        return wiseMarker
      case 'acl':
        return ACLMarker
      case 'laverty':
      case 'dorevitch':
      case 'qml':
      case 'tml':
      case 'wdp':
      case 'abbott':
        return HealiusMarker
      default:
        return BgtBloodDropSmall
    }
  }

  useEffect(() => {
    if (!map) return
    if (!locations) {
      return
    }
    if (!bounds) {
      return
    }
    // if (pointsInViewport && pointsInViewport?.length > 0) {
    //   console.log("skipping recalc");
    //   return;
    // }
    const pointsInRect: BGTLocationList = []
    const allPoints: BGTLocationList = []
    for (let i = 0; i < locations.length; i += 1) {
      const location = locations[i]
      allPoints.push(location)
      if (map?.getBounds()?.contains(location)) {
        pointsInRect.push(location)
      }
    }
    console.log('Points in viewport:', pointsInRect.length)
    // clearMarkers();
    if (pointsInViewport === undefined) {
      console.log('++++ set pointinviewport')
      setPointsInViewport(allPoints)
    }
    // setLocationsInSidebar(pointsInRect);
  }, [bounds, locations, map])

  useEffect(() => {
    if (pointsInViewport && pointsInViewport.length > 0) {
      const newMarkers: google.maps.Marker[] = []
      if (markers.length > 0) {
        console.log('skipping redraw')
        return
      }
      for (let i = 0; i < pointsInViewport.length; i += 1) {
        newMarkers.push(
          drawPinAtPos(
            pointsInViewport[i],
            new google.maps.LatLng(
              pointsInViewport[i].lat,
              pointsInViewport[i].lng
            ),
            getMarkerForSubProvider(pointsInViewport[i].subProvider),
            map!
          )
        )
      }
      setMarkers(newMarkers)
    }
  }, [pointsInViewport, drawPinAtPos])

  useEffect(() => {
    if (!map) return
    if (!markers) return
    if (markerCluster) {
      // eslint-disable-next-line no-extra-semi
      ;(markerCluster as MarkerClusterer).clearMarkers()
    }
    setMarkerCluster(new MarkerClusterer({ markers, map }))
  }, [markers, map])

  useEffect(() => {
    if (!map) return

    clearHomeMarker()

    setHomeMarker(
      drawPinAtPos(
        null,
        new google.maps.LatLng(center.lat, center.lng),
        undefined,
        map
      )
    )
  }, [map, center])

  useEffect(() => {
    if (!map) return

    setMapSetupComplete(true)

    // do something with the map instance
    if (!userDragged) {
      map.setCenter(center)
      map.setZoom(zoom)
    }
    console.log('bounds', map.getBounds())

    if (!isMapSetup) {
      // add some map handlers
      map.addListener('bounds_changed', debouncedMapMove)
      map.addListener('zoom_changed', debouncedZoomChanged)
      map.addListener('drag', debouncedDragChanged)
    }
  }, [map, center, zoom])

  const closeClicked = () => {
    setSelectedMarker(undefined)
  }

  const baseTransitionClasses =
    'transition-all duration-500 overflow-hidden transform '

  // clustering icons https://developers.google.com/maps/documentation/javascript/marker-clustering
  return (
    <Fragment>
      {children}

      {/* <div
        className={`absolute shadow-lg hidden lg:block bg-off-white ${
          !chromeless
            ? "top-16 h-[calc(100vh-4rem)] p-6"
            : "h-[calc(100vh)] p-4"
        } right-0 w-80  z-50 overflow-x-auto`}
      >
        {showLocationList &&
          (locationsInSidebar === null ||
            locationsInSidebar === undefined ||
            locationsInSidebar.length === 0) && (
            <div>No collection centres found in this area.</div>
          )}
        {showLocationList === false && (
          <div>Zoom in to show location details</div>
        )}
        {showLocationList === true &&
          locationsInSidebar !== null &&
          locationsInSidebar !== undefined &&
          locationsInSidebar.map((item) => (
            <CollectionLocation
              key={`${item.id}-collection-id`}
              item={item}
              isSelected={
                selectedMarker !== undefined &&
                selectedMarker.id !== undefined &&
                selectedMarker.id === item.id
              }
            ></CollectionLocation>
          ))}
      </div> */}
      {selectedMarker && (
        <Transition
          show={selectedMarker !== undefined}
          as={Fragment}
          appear={true}
          enterFrom={`${baseTransitionClasses} scale-90 opacity-0 max-h-dvh`}
          enterTo={`${baseTransitionClasses} scale-100 opacity-100 max-h-dvh`}
          leaveFrom={`${baseTransitionClasses} scale-100 opacity-100 max-h-dvh`}
          leaveTo={`${baseTransitionClasses} scale-90 opacity-0 max-h-dvh`}
        >
          <div
            className={`block absolute bg-off-white dark:bg-dark-gray-light w-full min-h-96 sm:w-96 sm:max-h-[70%] sm:rounded-xl sm:shadow-xl sm:m-auto sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 ${
              !chromeless
                ? 'top-16 h-[calc(100dvh-4rem)] p-6 md:!ml-40'
                : 'h-dvh p-4'
            } `}
          >
            <XCircleIcon
              className="absolute right-0 top-0 sm:-right-4 sm:-top-4 cursor-pointer mt-4 mr-4 sm:rounded-full sm:bg-white dark:sm:bg-dark-gray-light sm:shadow-lg"
              width={34}
              onClick={() => closeClicked()}
            ></XCircleIcon>
            <div className="overflow-auto h-full w-full">
              <CollectionLocation
                item={selectedMarker}
                isSelected={false}
                singular={true}
              ></CollectionLocation>
            </div>
          </div>
        </Transition>
      )}
    </Fragment>
  )
}

interface CollectionItemProps {
  item: BGTLocation
  isSelected: boolean
  singular?: boolean
}

function CollectionLocation({
  item,
  isSelected,
  singular,
}: CollectionItemProps) {
  const theme = useBranding()?.theme || undefined
  const getSubProviderLogo = () => {
    switch (item.subProvider) {
      case '4cyte':
        return cyte4Logo
      case 'wise':
        return wiseLogo
      case 'acl':
        return ACLLogo
      case 'laverty':
        return LavertyLogo
      case 'dorevitch':
        return DorevitchLogo
      case 'qml':
        return QMLLogo
      case 'tml':
        return TMLLogo
      case 'abbott':
        return AbbottLogo
      case 'wdp':
        return WDPLogo
      default:
        return BgtBloodDropSmall
    }
  }

  return (
    <div
      id={item.id}
      className={` ${!singular ? 'border-b border-gray-dark/50' : ''} p-4  ${
        isSelected ? 'bg-gray-dark/20 rounded-md' : ''
      }`}
      key={item.id}
    >
      <div className="text-md font-bold">{item.name}</div>
      {item.url && (
        <div className="text-md">
          <a
            className={classNames(
              'hover:underline',
              theme ? `text-[--link-color]` : `text-blood`
            )}
            style={
              theme
                ? ({
                    '--link-color': theme.linkColor,
                  } as React.CSSProperties)
                : {}
            }
            href={item.url}
            target="_blank"
          >
            More information
          </a>
        </div>
      )}
      <div className="text-sm mb-2 mt-2 justify-center items-center">
        <img
          className="rounded-md max-w-40 max-h-10 inline mr-2"
          src={getSubProviderLogo()}
        />
        <span className="text-left align-middle inline-block">
          {item.provider !== 'healius' && item.subProviderName}
        </span>
      </div>
      <div className="text-sm font-bold">Address:</div>
      <div className="text-sm">
        <a
          href={`https://www.google.com/maps?q=${item.lat},${item.lng}`}
          target="_blank"
          className={classNames(theme ? `text-[--link-color]` : `text-blood`)}
          style={
            theme
              ? ({
                  '--link-color': theme.linkColor,
                } as React.CSSProperties)
              : {}
          }
        >
          {item.address}
          <br />
          {item.city} {item.state} {item.postcode}
        </a>
      </div>
      <div className="text-sm mt-4 mb-4">
        <LinkButton
          label="Get Directions"
          url={`https://www.google.com/maps/dir/?api=1&destination=${item.lat},${item.lng}`}
          target="_blank"
          internal={false}
          theme={useBranding()?.theme || undefined}
        ></LinkButton>
      </div>
      <div className="text-sm font-bold">Phone:</div>
      <div className="text-sm mb-2">
        <a
          className={classNames(theme ? `text-[--link-color]` : `text-blood`)}
          style={
            theme
              ? ({
                  '--link-color': theme.linkColor,
                } as React.CSSProperties)
              : {}
          }
          href={`tel: ${item.phone
            ?.replace(/\s/gi, '')
            .replace('(MC)', '')
            .trim()}`}
        >
          {item.phone}
        </a>
      </div>

      <div className="text-sm font-bold">Opening hours:</div>
      <div className="grid grid-cols-8 text-sm mb-2">
        <span className="col-span-3">Monday:</span>
        <span className="col-span-5">{item.hours.mon.trim() || 'Closed'}</span>
        <span className="col-span-3">Tuesday:</span>
        <span className="col-span-5">{item.hours.tue.trim() || 'Closed'}</span>
        <span className="col-span-3">Wednesday:</span>
        <span className="col-span-5">{item.hours.wed.trim() || 'Closed'}</span>
        <span className="col-span-3">Thursday:</span>
        <span className="col-span-5">{item.hours.thu.trim() || 'Closed'}</span>
        <span className="col-span-3">Friday:</span>
        <span className="col-span-5">{item.hours.fri.trim() || 'Closed'}</span>
        <span className="col-span-3">Saturday:</span>
        <span className="col-span-5">{item.hours.sat.trim() || 'Closed'}</span>
        <span className="col-span-3">Sunday:</span>
        <span className="col-span-5">{item.hours.sun.trim() || 'Closed'}</span>
      </div>
      {item.lunchHours && (
        <Fragment>
          <div className="text-sm font-bold">Lunch hours:</div>
          <div className="text-sm">{item.lunchHours}</div>
        </Fragment>
      )}
      {item.specialHours && (
        <Fragment>
          <div className="text-sm font-bold">Special hours:</div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: item.specialHours,
            }}
          ></div>
        </Fragment>
      )}
      {item.notes && (
        <Fragment>
          <div className="text-sm font-bold mt-4">Notes:</div>
          <div
            className="text-sm"
            dangerouslySetInnerHTML={{
              __html: item.notes,
            }}
          ></div>
        </Fragment>
      )}
    </div>
  )
}
