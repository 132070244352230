import { useEffect, useRef, useState } from 'react'

import PasswordStength from '../../form/PasswordStrength'
import { Modal } from '../../layout/Modal'
import { ThemeInterface } from '../../../lib/interfaces'
import toast from 'react-hot-toast'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

interface UpdateEmailConfirmationModalProps {
  show: boolean
  theme?: ThemeInterface
  setShow: (openState: boolean) => void
  onPassword: (password: string) => void
}

export function UpdateEmailConfirmationModal({
  show,
  setShow,
  theme,
  onPassword,
}: UpdateEmailConfirmationModalProps) {
  const [open, setOpen] = useState(false)
  const passwordConfirmationRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setOpen(show)
  }, [show])

  const handleConfirmationDialog = async (): Promise<void> => {
    if (
      passwordConfirmationRef.current &&
      passwordConfirmationRef.current.value
    ) {
      console.log('do the thing?')
      onPassword && onPassword(passwordConfirmationRef.current.value)
    } else {
      toast.error('Please enter your password', {
        duration: 3000,
      })
      return
    }
  }

  return (
    <Modal
      show={open}
      options={{
        icon: (
          <ExclamationTriangleIcon
            className="h-6 w-6 text-orange-500"
            aria-hidden="true"
          />
        ),
        mainActions: [
          {
            label: 'Save personal information',
            handler: () => handleConfirmationDialog(),
          },
        ],
        secondaryAction: {
          label: 'Cancel',
          handler: () => setShow(false),
        },
        title: 'Wait a minute...',
        content: (
          <div>
            <div className="mt-2">
              <p className="text-sm text-gray-500 mb-2">
                <b>We take privacy and security seriously. </b>You are updating
                your personal information, and we just want to make sure its
                you.
              </p>
              <p className="text-sm text-gray-500">
                Please enter your password below to continue.
              </p>
            </div>
            <div className="mt-2">
              <PasswordStength
                id="password"
                name="password"
                placeholder="Enter your current password"
                hideBars={true}
                segments={5}
                required={true}
                reference={passwordConfirmationRef}
                segmentColours={[
                  'bg-bgt-primary',
                  'bg-bgt-primary/80',
                  'bg-bgt-primary/60',
                  'bg-jade/60',
                  'bg-jade',
                ]}
              ></PasswordStength>
            </div>
          </div>
        ),
      }}
      onClose={() => setShow(false)}
      theme={theme || undefined}
      size="lg"
    ></Modal>
  )
}
