import { Fragment, PropsWithChildren, ReactNode } from 'react'
import { classNames } from '../../lib/utils'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { Option } from 'react-google-places-autocomplete/build/types'
import { SingleValue } from 'react-select'
import { ProfileAddress } from '../../lib/validators'

export interface onChangeInterface {
  name: string
  value: string | boolean | number | Record<string, unknown>
  label?: string
  strValue: string
}

export interface TextInputFieldProps extends PropsWithChildren {
  label?: string | ReactNode
  id: string
  name: string
  type:
    | 'email'
    | 'text'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'search'
    | 'password'
    | 'checkbox'
    | 'number'
    | 'hidden'
    | 'tel'
    | 'address'
  unit?: string | null
  prefix?: string | null
  tooltip?: string
  value?: string
  placeholder?: string
  className?: string
  min?: string
  max?: string
  disabled?: boolean
  handleChange?: (data: onChangeInterface) => void
  shouldBeFullWidth?: boolean
  required?: boolean
  pattern?: string
  reference?: React.RefObject<HTMLInputElement>
  readonly?: boolean
  inputmode?: 'numeric'
  step?: number | string
  defaultChecked?: boolean
  autocomplete?: boolean
  addressAutocompleteTypes?: string[]
}

const baseClasses =
  'relative outline-none block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-dark/30 focus:ring-2 sm:text-sm sm:leading-6 shadow-sm focus:ring-gray-dark/30 dark:bg-dark-gray-light dark:text-white'

export const defaultInputClasses = baseClasses

export default function TextInputField({
  label,
  name,
  id,
  type,
  unit,
  prefix,
  tooltip,
  value,
  placeholder,
  className,
  min,
  max,
  disabled,
  shouldBeFullWidth,
  required,
  pattern,
  inputmode,
  reference,
  readonly,
  handleChange,
  children,
  step,
  defaultChecked,
  autocomplete,
  addressAutocompleteTypes,
}: TextInputFieldProps) {
  const handleValueChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const element = e.target as HTMLInputElement
    let itemValue: string | boolean = element.value
    if (
      element.nodeName.toLowerCase() === 'input' &&
      element.getAttribute('type')?.toLowerCase() === 'checkbox'
    ) {
      itemValue = element.checked
    }
    handleChange &&
      handleChange({
        name: element.name,
        value: itemValue,
        strValue: itemValue.toString(),
      })
  }
  const handleAddressChange = (e: SingleValue<Option>): void => {
    if (!e) {
      return
    }
    console.log(e)
    const streetAddress = e.value.structured_formatting.main_text
    const cityStateCountry = e.value.structured_formatting.secondary_text

    const localityParts = cityStateCountry.split(', ')
    const country = localityParts.pop()

    const cityState = localityParts.pop().split(' ')
    const state = cityState.pop()
    const city = cityState.join(' ')

    const address: ProfileAddress = {
      addressLine1: streetAddress,
      city,
      state,
      country,
    }

    handleChange &&
      handleChange({
        name: name,
        value: address,
        strValue: e.label,
      })
  }

  // const idError = `${name}-error`;
  const error = false

  let currentClasses = `${baseClasses}`
  if (error) {
    currentClasses = `${baseClasses} focus:ring-red/30`
  }

  if (shouldBeFullWidth !== undefined && shouldBeFullWidth === false) {
    currentClasses = currentClasses
      .replace('block', '')
      .replace('w-full', '')
      .replace('mt-2', '')
  }
  return (
    <Fragment>
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-dark mt-4"
        >
          {label && label}{' '}
          {required && <span className="text-blood font-bold">*</span>}
          {label && tooltip && tooltip !== label && (
            <small>
              <br />
              {tooltip}
            </small>
          )}
        </label>
      )}
      {!label && tooltip && (
        <small className="block text-xs font-medium leading-6 text-gray-dark">
          {tooltip}
        </small>
      )}
      {type === 'address' && (
        <div
          className={`relative flex items-center react-select-container ${className}`}
        >
          <GooglePlacesAutocomplete
            // apiKey={import.meta.env.VITE_MAPS_API_KEY}

            selectProps={{
              name: name,
              id: id,
              value: {
                value: value || '',
                label: value || '',
              },
              className: 'block w-full',
              isDisabled: disabled ? disabled : false,
              placeholder: placeholder || '',
              required: required,
              classNames: {
                control: (state) =>
                  state.isFocused
                    ? '!ring-2 !ring-gray-dark/30 !border-0 !shadow-none dark:bg-dark-gray-light '
                    : '!border-gray-dark/30 !rounded-md !shadow-sm !border-0 !ring-1 !ring-gray-dark/30 dark:bg-dark-gray-light dark:text-white',
                valueContainer: () => 'border-0 !text-sm dark:text-white',
                input: () => 'dark:text-white',
                singleValue: () => 'dark:text-white',
                menu: () => `dark:text-white dark:bg-dark-gray-light`,
                option: ({ isFocused }) =>
                  `${isFocused ? 'dark:!bg-dark-gray-lighter/80 !bg-gray-medium' : ''} `,
              },
              onChange: (e) => handleAddressChange && handleAddressChange(e),
              // styles: {
              //   input: (provided) => ({
              //     ...provided,
              //     border: 0,
              //     outline: "none",
              //   }),
              //   control: (provided, state) => ({
              //     ...provided,
              //     borderColor: state.isFocused ? "pink" : "blue",
              //     // borderWidth: state.isFocused ? 2 : 1,
              //   }),
              //   valueContainer: (provided) => ({
              //     ...provided,
              //     borderColor: "bgt-primary",
              //     border: 0,
              //   }),
              // },
              // value: value ?
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['au'],
              },
              types: addressAutocompleteTypes
                ? addressAutocompleteTypes
                : ['address'],
            }}
          />
        </div>
      )}
      {type !== 'checkbox' && type !== 'address' && (
        <div className="relative flex items-center">
          {prefix && (
            <span
              className="pointer-events-none z-10 absolute inset-y-0 left-0 flex items-center pl-3 border-r border-gray-dark/30 pr-2"
              style={{
                minWidth: `${prefix.length * 7.2}px`, //"260px",
                overflow: 'hidden',
              }}
            >
              {prefix}
            </span>
          )}
          <input
            ref={reference}
            type={type}
            name={name}
            id={id}
            aria-autocomplete={autocomplete === false ? 'none' : 'both'}
            min={min ? min : undefined}
            max={max ? max : undefined}
            disabled={disabled ? disabled : false}
            required={required ? required : false}
            pattern={pattern ? pattern : undefined}
            inputMode={inputmode ? inputmode : undefined}
            value={value}
            readOnly={readonly ? readonly : false}
            step={step ? step : undefined}
            autoComplete="false"
            className={`${classNames(currentClasses, className || '')} `}
            placeholder={placeholder}
            onChange={(e) => handleValueChange && handleValueChange(e)}
            style={{
              paddingRight: unit !== undefined ? '70px' : '',
              paddingLeft:
                prefix !== undefined && prefix !== null
                  ? `${prefix.length * 7.2}px`
                  : '',
            }}
            // aria-invalid="true"
          />
          {unit && (
            <div
              className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 border-l border-gray-dark/30 pl-2"
              style={{
                maxWidth: '60px',
                overflow: 'hidden',
              }}
            >
              <span className="text-gray-500 sm:text-sm">{unit}</span>
            </div>
          )}
          {children}
        </div>
      )}
      {type === 'checkbox' && (
        <Fragment>
          <input
            type={type}
            name={name}
            id={id}
            disabled={disabled ? disabled : false}
            readOnly={readonly ? readonly : false}
            defaultValue={value}
            checked={defaultChecked}
            className={classNames(currentClasses, className || '')}
            onChange={(e) => handleValueChange && handleValueChange(e)}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

{
  /* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div> */
}
{
  /* </div> */
}
{
  /* <p className="mt-2 text-sm text-red-600" id={idError}>
        Input is invalid
      </p> */
}
