import { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  upsertOptimalRange as upsertOptimalRange,
  useOptimalRange as useOptimalRange,
  useBiomarkers,
} from '../../../../lib/biomarker'
import {
  OptimalRangeForm,
  OptimalRangeSubmitData,
} from '../../../optimalRange/OptimalRangeForm'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import PageLoader from '../../../layout/PageLoader'
import PageSection from '../../../layout/PageSection'
import OrgPage from '../../../OrgPage'
import { getOptimalRangeUrl } from '../../../../lib/routes'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'

export function OptimalRangeEditPage() {
  const { org, theme } = useOrganisationContext()
  const biomarkers = useBiomarkers()
  const loadingBiomarkers = biomarkers === null

  const { uuid } = useParams<{ uuid: string }>()
  const {
    optimalRange: loadedOptimalRange,
    loading,
    error,
  } = useOptimalRange(uuid)

  const [optimalRange, setOptimalRange] = useState(loadedOptimalRange)

  // Update local state when loadedOptimalRange changes
  useEffect(() => {
    if (loadedOptimalRange) {
      setOptimalRange(loadedOptimalRange)
    }
  }, [loadedOptimalRange])

  const onSubmit = async (formData: OptimalRangeSubmitData) => {
    try {
      const input = {
        uuid: optimalRange?.uuid,
        organisationUuid: org!.uuid,
        name: formData.optimalRange.name,
        criteria: formData.optimalRange.criteria,
        specs: formData.optimalRange.specs,
      }
      const updatedOptimalRange = await upsertOptimalRange(input)
      setOptimalRange(updatedOptimalRange)
      toast.success('Optimal range updated.', {
        duration: 3000,
      })
    } catch (error) {
      const errorMessage =
        error instanceof Error &&
        error.message.includes(
          'An optimal range already exists with the same criteria'
        )
          ? error.message
          : 'Unable to update optimal range'

      toast.error(errorMessage, {
        duration: 3000,
      })
    }
  }

  if (loading || loadingBiomarkers) {
    return <PageLoader />
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <Fragment>
      {org && optimalRange && (
        <OrgPage
          org={org}
          theme={theme || undefined}
          tabs={[
            { name: 'Optimal Ranges', href: `/org/${org.id}/optimal-ranges` },
            { name: optimalRange.name || 'Optimal Range' },
          ]}
        >
          <PageSection>
            <OptimalRangeForm
              biomarkers={biomarkers}
              optimalRange={optimalRange}
              onSubmit={onSubmit}
            />
          </PageSection>
        </OrgPage>
      )}
    </Fragment>
  )
}

export function OptimalRangeCreatePage() {
  const navigate = useNavigate()
  const { org, theme } = useOrganisationContext()
  const biomarkers = useBiomarkers()
  const loadingBiomarkers = biomarkers === null

  const onSubmit = async (formData: OptimalRangeSubmitData) => {
    try {
      const input = {
        organisationUuid: org!.uuid,
        name: formData.optimalRange.name,
        criteria: formData.optimalRange.criteria,
        specs: formData.optimalRange.specs,
      }
      const updatedProfile = await upsertOptimalRange(input)
      const url = getOptimalRangeUrl(org!.id, updatedProfile.uuid)
      navigate(url)
      toast.success('Optimal range created.', {
        duration: 3000,
      })
    } catch (error) {
      const errorMessage =
        error instanceof Error &&
        error.message.includes(
          'An optimal range already exists with the same criteria'
        )
          ? error.message
          : 'Unable to create optimal range'

      toast.error(errorMessage, {
        duration: 3000,
      })
    }
  }

  if (loadingBiomarkers) {
    return <PageLoader />
  }

  return (
    <Fragment>
      {org && (
        <OrgPage
          org={org}
          theme={theme || undefined}
          tabs={[
            { name: 'Optimal Ranges', href: `/org/${org.id}/optimal-ranges` },
            { name: 'New' },
          ]}
        >
          <PageSection>
            <OptimalRangeForm
              biomarkers={biomarkers}
              optimalRange={{
                name: 'New Optimal Range',
                organisationUuid: org.uuid,
                criteria: {
                  gender: null,
                },
                specs: [],
              }}
              onSubmit={onSubmit}
            />
          </PageSection>
        </OrgPage>
      )}
    </Fragment>
  )
}
