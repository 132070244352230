import { PropsWithChildren, ReactNode } from 'react'
import { OrganisationWithBranding } from '../components/context/OrganisationContext'
import { MenuItemGroup } from '../components/sidebar/MenuItems'
import { ResultReferral } from './validators'

export interface ITeam {
  id: number
  name: string
  href: string
  initial: string
  current: boolean
}

export interface SidebarProps {
  setSidebarOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
  sidebarOpen: boolean
  theme?: ThemeInterface
  urlPrefix: string
}
export interface HeaderProps {
  setSidebarOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
  theme?: ThemeInterface
}

export interface PageHeadingProps extends PropsWithChildren {
  title: string
  description?: string | ReactNode
  size?: 'xl' | 'lg' | 'md'
  className?: string
  action?: ReactNode
}

export enum StatusTagColors {
  RED = 'red',
  GREEN = 'green',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  BLUE = 'blue',
  BGT = 'bgt',
  BLOOD = 'blood',
  JADE = 'jade',
  MANDARIN = 'mandarin',
  GRAY = 'gray',
}

export interface StatusTagProps {
  className?: string
  label: string
  color: StatusTagColors
  filled?: boolean
}

interface SidebarMenuProps {
  menuItemGroups: MenuItemGroup[]
  theme?: ThemeInterface
  urlPrefix: string
}

export interface IMobileSidebarProps extends SidebarMenuProps {
  setSidebarOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
  sidebarOpen: boolean
}
export interface IDesktopSidebarProps extends SidebarMenuProps {}

export interface Tab {
  name: string
  count?: number
  href: string
  current: boolean
}
export type TabItems = Record<string, Tab>

export interface ResultsTabProps extends PropsWithChildren {
  resultReferral?: ResultReferral
  files?: string[]
  profile: string
  org?: OrganisationWithBranding
  theme?: ThemeInterface
}

export interface Crop {
  x: number
  y: number
  width: number
  height: number
  unit: 'px' | '%'
}

export interface ImageWithCrop {
  url?: string | null
  crop?: Crop | null
  filename?: string | null
}

export interface ThemeInterface {
  primaryColor?: string | null
  secondaryColor?: string | null
  logo?: string | null
  logoOriginal?: ImageWithCrop | null
  logoSquare?: string | null
  logoSquareOriginal?: ImageWithCrop | null
  headerBarBackgroundColor?: string | null
  headerBarTextColor?: string | null
  buttonColor?: string | null
  buttonHoverColor?: string | null
  buttonTextColor?: string | null
  linkColor?: string | null
  linkHoverColor?: string | null
}

export interface SaveProductForOrganisationInput {
  name?: string
  tests: string[]
  gender?: string
  internal: boolean
  description?: string
  price: number
  markupAmount?: number
}

export interface ImageUploadResult {
  name: string
  url: string
}
export interface ImageUploadResultWithBlob extends ImageUploadResult {
  blob: Blob
  filename: string
}

export interface ImageUpload {
  url?: string
  crop?: Crop
  originalFile: File | Blob
  originalFilename: string
  croppedFile: Blob
  name: string
  filename: string
}

// export interface OrganisationPayload {
//   resultsEmail?: string
// }
