import { authFetch, unauthedFetch } from '../providers/AuthProvider'
import {
  PaymentIntentSecret,
  PaymentIntentSecretSchema,
  ProductCheckout,
  ProductCheckoutSchema,
  ProductCheckoutSession,
  ProductCheckoutSessionSchema,
  ProductWithPrice,
  ProductWithPriceSchema,
} from './validators'

export const createProductCheckout = async (
  productId: string,
  returnUrl: string
): Promise<ProductCheckout> => {
  const result = await authFetch(
    `${import.meta.env.VITE_API_URL}/stripe/checkout`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        productId,
        returnUrl,
      }),
    }
  )

  const parsedData = ProductCheckoutSchema.parse(result.data)
  return parsedData
}

export const getStripeCheckoutSession = async (
  sessionId: string
): Promise<ProductCheckoutSession> => {
  const result = await authFetch(
    `${import.meta.env.VITE_API_URL}/stripe/checkout/session`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        sessionId,
      }),
    }
  )
  console.log('we got the session back', result.data)
  const parsedData = ProductCheckoutSessionSchema.parse(result.data)
  return parsedData
}

export const getProductWithPrice = async (
  productId: string
): Promise<ProductWithPrice> => {
  const result = await unauthedFetch(
    `${import.meta.env.VITE_API_URL}/product/${productId}`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    }
  )
  const parsedData = ProductWithPriceSchema.parse(result.data.data)
  console.log('got the product', parsedData)
  return parsedData
}

interface GetPaymentIntentSecretProps {
  amount: number
  productName: string
  currency: string
  userUuid: string
  email: string
  orgUuid: string
  orgName: string
  productId: string
  origin: string
  stripeProductId: string
}

export const getPaymentIntentSecret = async ({
  productName,
  amount,
  currency,
  userUuid,
  orgName,
  orgUuid,
  productId,
  origin,
  email,
  stripeProductId,
}: GetPaymentIntentSecretProps): Promise<PaymentIntentSecret> => {
  const result = await unauthedFetch(
    `${import.meta.env.VITE_API_URL}/stripe/payment/create`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: {
        amount: amount,
        currency: currency,
        productName: productName,
        productId: productId,
        userUuid: userUuid,
        orgName: orgName,
        orgUuid: orgUuid,
        origin: origin,
        email: email,
        stripeProductId: stripeProductId,
      },
    }
  )
  const parsedData = PaymentIntentSecretSchema.parse(result.data.data)
  return parsedData
}
