import { createRef, useCallback, useEffect, useState } from 'react'
import { Button } from '../../form/button/Button'
import PasswordStength, { OnChangeProps } from '../../form/PasswordStrength'
import { SettingsAccountProps } from './SettingsTabAccount'
import toast from 'react-hot-toast'
import { Errors } from '../../../lib/verifyErrors'
import { authFetch } from '../../../providers/AuthProvider'
import { GraphQLResponseSchema } from '../../../lib/errors'
import { GroupSection, GroupSectionItem } from '../../layout/GroupSection'
import { classNames } from '../../../lib/utils'

interface Password {
  isValid: boolean
  value: string
}
interface SettingsPasswordFormData {
  oldPassword?: Password
  newPassword?: Password
  confirmPassword?: Password
}

export default function SettingsTabAccountPassword({
  user,
  profile,
  theme,
}: SettingsAccountProps) {
  const [formData, setFormData] = useState<SettingsPasswordFormData>({})
  const [canSubmit, setCanSubmit] = useState(false)
  const [formErrorState, setFormErrorState] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const formRef = createRef<HTMLFormElement>()

  // console.log(user, profile);
  const handlePasswordChange = (props: OnChangeProps) => {
    setFormData((prev: SettingsPasswordFormData) => ({
      ...prev,
      [props.name]: {
        isValid: props.isValid,
        value: props.value,
      },
    }))
  }

  const canSubmitForm = useCallback((): boolean => {
    let hasChanged = false
    if (
      formData.oldPassword?.value &&
      formData.oldPassword?.isValid &&
      formData.newPassword?.value &&
      formData.newPassword?.isValid &&
      formData.confirmPassword?.value &&
      formData.confirmPassword?.isValid
    ) {
      hasChanged = true
    }

    return hasChanged
  }, [formData])

  useEffect(() => {}, [user, profile])

  useEffect(() => {
    const hasChanged = canSubmitForm()
    setCanSubmit(hasChanged)
  }, [formData, canSubmitForm])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (formData.newPassword?.value !== formData.confirmPassword?.value) {
      // toast.error("Passwords do not match, please fix.", {
      //   duration: 3000,
      // });
      setFormErrorState(Errors.PasswordMismatch)
      return
    }
    setFormErrorState('')
    await updatePassword()
  }

  const updatePassword = async (): Promise<boolean> => {
    const toastId = toast.loading('Updating password', {
      duration: 3000,
    })
    setIsSaving(true)
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `
          mutation update($passwordData:UpdatePasswordInput!) {
            updatePassword(data: $passwordData) {
              uuid
              firstName
              lastName
              email
            }
          }
        `,
        variables: {
          passwordData: {
            oldPassword: formData.oldPassword?.value,
            newPassword: formData.newPassword?.value,
            confirmPassword: formData.confirmPassword?.value,
          },
          profileUuid: profile.uuid,
        },
      }),
    })

    setIsSaving(false)

    const response = result.data
    try {
      // parse the base object first
      const envelope = GraphQLResponseSchema.parse(response)
      if (envelope.errors && envelope.errors.length > 0) {
        throw new Error(envelope.errors[0].message)
      }
      // setDefaultEmail(parsedData.)
      toast.success('Password updated', {
        duration: 3000,
        id: toastId,
      })
      resetForm()
      return true
    } catch (e) {
      let message = 'Failed to update password'
      if (e instanceof Error) {
        message = e.message
      }
      toast.error(message, {
        duration: 3000,
        id: toastId,
      })
      return false
    }
  }

  const resetForm = () => {
    setFormData({})
    formRef.current?.reset()
  }

  return (
    <GroupSection
      title="Change password"
      description="Update your password associated with your account."
    >
      <GroupSectionItem>
        <form onSubmit={handleSubmit} ref={formRef}>
          {formErrorState && (
            <div
              className="bg-bgt-primary/10 border-t-4 border-bgt-primary rounded-b text-teal-900 px-4 py-3 shadow-md mb-4"
              role="alert"
            >
              <div className="flex">
                <div className="py-1">
                  <svg
                    className="fill-current h-6 w-6 text-bgt-primary mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div>
                  <p className="font-bold">Error</p>
                  {formErrorState === Errors.PasswordMismatch && (
                    <p className="text-sm">Passwords do not match</p>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
            <div className="col-span-full relative">
              <PasswordStength
                id="oldPassword"
                name="oldPassword"
                label="Current Password"
                hideBars={true}
                onChange={handlePasswordChange}
                value={formData.oldPassword?.value}
                segments={5}
                segmentColours={[
                  'bg-bgt-primary',
                  'bg-bgt-primary/80',
                  'bg-bgt-primary/60',
                  'bg-jade/60',
                  'bg-jade',
                ]}
              ></PasswordStength>
            </div>
            <div className="col-span-full relative">
              <PasswordStength
                id="newPassword"
                name="newPassword"
                label="New Password"
                onChange={handlePasswordChange}
                value={formData.newPassword?.value}
                segments={5}
                segmentColours={[
                  'bg-bgt-primary',
                  'bg-bgt-primary/80',
                  'bg-bgt-primary/60',
                  'bg-jade/60',
                  'bg-jade',
                ]}
              ></PasswordStength>
            </div>

            <div className="col-span-full relative">
              <PasswordStength
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                onChange={handlePasswordChange}
                value={formData.confirmPassword?.value}
                segments={5}
                segmentColours={[
                  'bg-bgt-primary',
                  'bg-bgt-primary/80',
                  'bg-bgt-primary/60',
                  'bg-jade/60',
                  'bg-jade',
                ]}
              ></PasswordStength>
            </div>
          </div>

          <div className="mt-8 flex">
            <Button
              type="submit"
              disabled={isSaving || !canSubmit}
              label="Update Password"
              theme={theme}
            ></Button>
            <span className="text-sm pl-6 pt-1">
              Forgotten password?{' '}
              <a
                href={`${import.meta.env.VITE_AUTH_URL}/reset/password?redirectUrl=${encodeURI(`https://${window.location.hostname}`)}`}
                className={classNames(
                  'hover:underline font-sm ',
                  theme ? `text-[--link-color]` : `text-blood`
                )}
                style={
                  theme
                    ? ({
                        '--link-color': theme.linkColor,
                      } as React.CSSProperties)
                    : {}
                }
                target="_blank"
              >
                Reset it here
              </a>
            </span>
          </div>
        </form>
      </GroupSectionItem>
    </GroupSection>
  )
}
