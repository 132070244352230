import { Fragment, useState } from 'react'
import { Button } from '../../../form/button/Button'
import { PayoutViewer } from '../../../organisation/PayoutViewer'
import { AdminOrgTabProps } from '../AdminOrgPage'
import { markUnpaidPayoutsAsPaid } from '../../../../lib/organisation'
import toast from 'react-hot-toast'

export const AdminOrgTabPayouts: React.FC<AdminOrgTabProps> = ({
  organisation,
}) => {
  const [unpaidCount, setUnpaidCount] = useState<number>(0)

  const markUnpaidAsPaid = async () => {
    console.log('mark them as paid')
    const toastId = toast.loading('Marking UNPAID payouts as PAID', {
      duration: 3000,
    })
    await markUnpaidPayoutsAsPaid(organisation.uuid)
    toast.success('Done', {
      duration: 3000,
      id: toastId,
    })
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  return (
    <Fragment>
      <PayoutViewer
        displayHelpInformation={false}
        organisationUuid={organisation.uuid}
        organisationId={organisation.id}
        organisationName={organisation.name}
        organisationTheme={organisation.theme || undefined}
        unpaidCallback={(unpaid) => {
          console.log('the unpaid items are', unpaid)
          setUnpaidCount(unpaid.length)
        }}
        actions={
          <Button
            disabled={unpaidCount <= 0}
            onClick={() => markUnpaidAsPaid()}
            classNames="float-right"
          >
            Mark All UNPAID As Paid out
          </Button>
        }
      ></PayoutViewer>
    </Fragment>
  )
}
