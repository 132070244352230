import { Link } from 'react-router-dom'
import { classNames } from '../../lib/utils'
// import { MenuItem } from '../sidebar/OrganisationMenuItems'
import { ThemeInterface } from '../../lib/interfaces'
import { MenuItem } from '../sidebar/MenuItems'

interface MenuItemProps {
  item: MenuItem
  theme?: ThemeInterface
  identifier?: string
}

export function MainMenuItem({
  item,
  theme,
  identifier = 'desktop',
}: MenuItemProps) {
  return (
    <li key={`main-${identifier}-nav-item-${item.name}`}>
      {item.href && (
        <Link
          to={item.href}
          className={classNames(
            item.current
              ? `bg-gray-light dark:bg-dark-gray-lighter ${
                  theme ? `text-[--link-color]` : `text-bgt-primary`
                }`
              : 'text-black/70 dark:text-white/80 hover:text-black dark:hover:text-white  hover:bg-gray-light dark:hover:bg-dark-gray-lighter ',
            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
          )}
          style={
            theme
              ? ({
                  '--link-color': theme.linkColor,
                } as React.CSSProperties)
              : {}
          }
        >
          <item.icon
            className={classNames('h-6 w-6 shrink-0')}
            aria-hidden="true"
          />
          {item.name}
          {/* <span className="inline-block w-5 h-5 text-center rounded-xl bg-bgt-primary/30 text-black absolute right-6 text-sm">
                                  1
        </span> */}
        </Link>
      )}
      {!item.href && item.handler && (
        <span
          onClick={() => item.handler && item.handler()}
          className={classNames(
            item.current
              ? `bg-gray-light dark:bg-dark-gray-lighter ${
                  theme ? `text-[--link-color]` : `text-bgt-primary`
                }`
              : 'text-black/70 dark:text-white/80 hover:text-black dark:hover:text-white  hover:bg-gray-light dark:hover:bg-dark-gray-lighter ',
            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer items-center'
          )}
          style={
            theme
              ? ({
                  '--link-color': theme.linkColor,
                } as React.CSSProperties)
              : {}
          }
        >
          <item.icon
            className={classNames('h-6 w-6 shrink-0')}
            aria-hidden="true"
          />
          {item.name}
          {/* <span className="inline-block w-5 h-5 text-center rounded-xl bg-bgt-primary/30 text-black absolute right-6 text-sm">
                                  1
        </span> */}
        </span>
      )}
    </li>
  )
}
