import React, {
  Fragment,
  ReactNode,
  createRef,
  useEffect,
  useState,
} from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { ThemeInterface } from '../../lib/interfaces'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

interface SwitchProps {
  checked: boolean
  label?: string | ReactNode
  tooltip?: string
  onChange?: (value: boolean) => void
  name?: string
  theme?: ThemeInterface
  disabled?: boolean
}

export default function Switch({
  checked,
  label,
  tooltip,
  onChange,
  name,
  theme,
  disabled,
}: SwitchProps) {
  const [enabled, setEnabled] = useState<boolean>(checked)
  const groupRef = createRef<HTMLButtonElement>()

  // useEffect(() => {
  //   if (checked !== undefined) {
  //     console.log(
  //       'setting enabled to be',
  //       checked,
  //       'what is it currently?',
  //       enabled
  //     )
  //     setEnabled(checked)
  //   }
  // }, [checked, enabled])

  useEffect(() => {
    console.log('we got a new button state', name, enabled)
    if (onChange) onChange(enabled)
  }, [enabled, onChange, name])

  // useEffect(() => {
  //   console.log('the incoming state changed to', checked, name, enabled)
  //   setEnabled(checked)
  // }, [checked, name, enabled])

  return (
    <Fragment>
      {label && (
        <label
          className="inline-block text-sm font-medium leading-6 text-gray-dark"
          data-tooltip-id={`switch-tooltip-${name}`}
        >
          {label && label}
          {label && tooltip && tooltip !== label && (
            <Fragment>
              <QuestionMarkCircleIcon
                className="inline cursor-help"
                width={20}
              ></QuestionMarkCircleIcon>
              <Tooltip
                id={`switch-tooltip-${name}`}
                place="top"
                content={tooltip}
                className="rounded-lg"
              />
            </Fragment>
          )}
        </label>
      )}
      <div>
        <HeadlessSwitch
          ref={groupRef}
          checked={enabled}
          onChange={setEnabled}
          as={Fragment}
          name={name}
        >
          {({ checked }) => (
            <div
              {...(checked !== undefined && {
                'data-checked': checked ? 'true' : undefined,
              })}
              {...(disabled !== undefined && {
                'data-disabled': disabled ? 'true' : undefined,
              })}
              className={`group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out  data-[checked]:bg-jade ${theme ? 'data-[checked]:bg-[--background-color]' : ''}`}
              style={
                theme
                  ? ({
                      '--background-color': theme.secondaryColor,
                    } as React.CSSProperties)
                  : {}
              }
            >
              <span className="sr-only">Use setting</span>
              <span
                className={`pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5`}
              >
                <span
                  aria-hidden="true"
                  className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
                >
                  <svg
                    fill="none"
                    viewBox="0 0 12 12"
                    className="h-3 w-3 text-gray-400"
                  >
                    <path
                      d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span
                  aria-hidden="true"
                  className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                >
                  <svg
                    fill="currentColor"
                    viewBox="0 0 12 12"
                    className="h-3 w-3 text-indigo-600"
                  >
                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                  </svg>
                </span>
              </span>
            </div>
          )}
        </HeadlessSwitch>
      </div>
    </Fragment>
  )
}
