import { setDocumentTitle } from '../../../lib/utils'

import AdminPage from './AdminPage'
import { PageHeading } from '../../layout/PageHeading'
import PageSection from '../../layout/PageSection'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Organisation } from '../../../lib/validators'
import PageLoader from '../../layout/PageLoader'
import { Tab } from '../../../lib/interfaces'
import { adminOrgRegex } from '../../../lib/routes'
import { useLocation, useParams } from 'react-router-dom'
import { TabbedMenu } from '../../layout/TabbedMenu'
import { getOrganisations } from '../../../lib/organisation'
import { AdminOrgTabPayouts } from './organisation-tabs/AdminOrgTabPayouts'

const baseTabs: Record<string, Tab> = {
  '': {
    name: 'Payouts',
    href: '',
    current: false,
  },
}

export interface AdminOrgTabProps extends PropsWithChildren {
  organisationUuid: string
  organisation: Organisation
}

export function AdminOrgPage() {
  const location = useLocation()
  const [organisation, setOrganisation] = useState<Organisation>()
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState<string>('')
  const [tabs, setTabs] = useState<Record<string, Tab>>(baseTabs)
  const [tabsUpdated, setTabsUpdated] = useState(0)
  const params = useParams()

  const getTabs = useCallback(
    (page: string) => {
      Object.entries(baseTabs).map(([key, val]) => {
        val.current = false
        if (key === page) {
          val.current = true
        }

        return val
      })
      return tabs
    },
    [tabs]
  )
  const handlePageLoad = useCallback(
    (pathname: string): void => {
      // console.log("++++ handle page load");
      const re = new RegExp(adminOrgRegex)
      const matches = re.exec(pathname)
      // console.log("matches", matches, re.test(location.pathname));
      if (!matches || matches.length === 0) {
        console.log(pathname, adminOrgRegex)
        throw new Error(`Tab not found: ${pathname}`)
      }

      const tab = matches.pop()?.replace('/', '')
      if (tab === undefined) {
        throw new Error('Tab not found')
      }

      // const currentTab = Object.entries(tabs).filter(([key]) => {
      //   return key === tab;
      // });
      // console.log("the current tab is", currentTab[0][0], currentTab[0][1]);
      // setCurrentPage(currentTab[0][1].component);
      setCurrentPage(tab)

      const latestTabs = getTabs(tab)
      setTabs(latestTabs)
      setTabsUpdated(Date.now())
    },
    [getTabs]
  )
  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [location, handlePageLoad])

  useEffect(() => {
    handlePageLoad(location.pathname)
  }, [handlePageLoad, location.pathname])

  useEffect(() => {
    setDocumentTitle(`Admin: ${organisation?.name || ''} / Payouts`, 'CLEAN')
    // load the orgs on page load
    if (!organisation) {
      getOrganisations(params.uuid).then((orgs) => {
        if (orgs && orgs.length === 1) {
          setOrganisation(orgs[0])
        }
        setLoading(false)
      })
    }
  }, [])

  return (
    <AdminPage>
      <PageSection>
        <PageHeading title={`Admin: ${organisation?.name || ''}`}></PageHeading>
        {loading && <PageLoader></PageLoader>}
        {!loading && !organisation && <div>no orgs found.</div>}
        {!loading && organisation && (
          <div>
            {tabsUpdated && (
              <TabbedMenu tabs={tabs} currentPage={currentPage}></TabbedMenu>
            )}

            {currentPage !== null && currentPage === '' && (
              // <SettingsTabGeneral></SettingsTabGeneral>
              <AdminOrgTabPayouts
                organisation={organisation}
                organisationUuid={organisation.uuid}
              ></AdminOrgTabPayouts>
            )}
          </div>
        )}
      </PageSection>
    </AdminPage>
  )
}
