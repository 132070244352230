import { Fragment, createRef } from 'react'
import TextInputField, {
  TextInputFieldProps,
  onChangeInterface,
} from './TextInputField'

interface PhoneNumberInputProps extends TextInputFieldProps {
  type: 'tel'
}

export default function PhoneNumberInput({
  id,
  label,
  name,
  disabled,
  handleChange,
  value,
  required,
}: PhoneNumberInputProps) {
  const inputReference = createRef<HTMLInputElement>()
  const handleTelephoneChange = (data: onChangeInterface): void => {
    // console.log("phone changed", inputReference, data);
    handlePhoneElementChange()
    handleChange && handleChange(data)
  }

  const handlePhoneElementChange = () => {
    const element = inputReference.current
    if (element) {
      const val = element.value.replace(/ /gi, '')
      if (val.startsWith('04')) {
        if (val.length >= 8) {
          element.value = `${val.substring(0, 4)} ${val.substring(
            4,
            7
          )} ${val.substring(7, 20)}`
        } else if (val.length > 4) {
          element.value = `${val.substring(0, 4)} ${val.substring(4, 20)}`
        } else {
          element.value = `${val.substring(0, 4)}`
        }
      }
      // handleElementChange(e);
    }
  }

  return (
    <Fragment>
      <TextInputField
        reference={inputReference}
        id={id}
        label={label}
        name={name}
        type="tel"
        value={value}
        required={required}
        disabled={disabled ? disabled : false}
        handleChange={handleTelephoneChange}
      ></TextInputField>
    </Fragment>
  )
}
