import { Fragment, useEffect, useState } from 'react'
import TextInputField, { onChangeInterface } from './TextInputField'
import { getClassForCols } from '../layout/GridTable'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'

export interface OnChangeProps {
  value: string
  name: string
  isValid: boolean
  message: string
}

interface PasswordStrengthProps {
  label?: string
  id: string
  name: string
  className?: string
  placeholder?: string
  onChange?: (props: OnChangeProps) => void
  segments?: number
  segmentColours?: string[]
  disabled?: boolean
  hideBars?: boolean
  reference?: React.RefObject<HTMLInputElement>
  required?: boolean
  value?: string
}

// interface Segment {
//   divRef: React.RefObject<HTMLDivElement>;
//   active: boolean;
// }

export default function PasswordStength({
  id,
  label,
  name,
  placeholder,
  onChange,
  segments,
  segmentColours,
  disabled,
  hideBars,
  reference,
  required,
  value,
}: PasswordStrengthProps) {
  // const [segs, setSegs] = useState<Segment[]>([]);
  const [visible, setVisible] = useState(false)
  const [password, setPassword] = useState('')
  const [type, setType] = useState<'password' | 'text'>('password')
  const [validate, setValidate] = useState({
    hasLow: {
      msg: 'Try adding a lowercase character',
      value: false,
      required: false,
    },
    hasCap: {
      msg: 'Try adding a capital letter',
      value: false,
      required: false,
    },
    hasNumber: {
      msg: 'Try adding a number',
      value: false,
      required: false,
    },
    meetsMinimumLength: {
      msg: 'Your password is not long enough',
      value: false,
      required: true,
    },
    hasSymbol: {
      msg: 'Try adding a symbol',
      value: false,
      required: false,
    },
  })
  const feedback = [
    'Yikes, my postcode is longer than that!',
    'Blimey, try to add a few more characters.',
    'You can do better than that, mate!',
    'Could do with some improvement.',
    'Ripper! Bloody secure!',
  ]
  const [strength, setStrength] = useState<number>(-1)
  const [strengthStatus, setStrengthStatus] = useState('')

  if (segments !== undefined && segmentColours?.length !== segments) {
    throw new Error('PasswordStrength: colours do not match segment count.')
  }

  const minimumLength = 10
  const recommendedLength = 14
  const segmentCount = segments || 6
  const colClass = getClassForCols(segmentCount)

  const handleElementChange = (data: onChangeInterface) => {
    // console.log("user typed", data.value.toString());
    // we need to check the password at each point to see if we have enough strength
    setPassword(data.value.toString())
  }

  // useEffect(() => {
  //   const initSegments: Segment[] = [];
  //   for (let i = 0; i < segmentCount; i += 1) {
  //     initSegments.push({
  //       active: false,
  //       divRef: createRef<HTMLDivElement>(),
  //     });
  //   }
  //   setSegs(initSegments);
  // }, []);
  const validatePassword = (password: string): void => {
    if (password.match(/\d+/g)) {
      setValidate((p) => ({
        ...p,
        hasNumber: { ...p.hasNumber, value: true },
      }))
    } else {
      setValidate((p) => ({
        ...p,
        hasNumber: { ...p.hasNumber, value: false },
      }))
    }
    if (password.match(/[A-Z]+/g)) {
      setValidate((p) => ({
        ...p,
        hasCap: { ...p.hasCap, value: true },
      }))
    } else {
      setValidate((p) => ({
        ...p,
        hasCap: { ...p.hasCap, value: false },
      }))
    }
    if (password.match(/[a-z]+/g)) {
      setValidate((p) => ({
        ...p,
        hasLow: { ...p.hasLow, value: true },
      }))
    } else {
      setValidate((p) => ({
        ...p,
        hasLow: { ...p.hasLow, value: false },
      }))
    }
    if (password.match(/[^\w ]+/g)) {
      setValidate((p) => ({
        ...p,
        hasSymbol: { ...p.hasSymbol, value: true },
      }))
    } else {
      setValidate((p) => ({
        ...p,
        hasSymbol: { ...p.hasSymbol, value: false },
      }))
    }
    if (password.length >= minimumLength) {
      setValidate((p) => ({
        ...p,
        meetsMinimumLength: { ...p.meetsMinimumLength, value: true },
      }))
    } else {
      setValidate((p) => ({
        ...p,
        meetsMinimumLength: { ...p.meetsMinimumLength, value: false },
      }))
    }
  }
  useEffect(() => {
    if (hideBars) {
      onChange &&
        onChange({
          name,
          isValid: true,
          value: password,
          message: 'OK',
        })
      return
    }
    if (password.length === 0) {
      setStrengthStatus('')
      onChange &&
        onChange({
          name,
          isValid: false,
          value: password,
          message: 'Invalid length',
        })
      return
    }
    const requirementsMet = Object.values(validate).filter((value) => {
      return value.value
    })
    const requirementsNotMet = Object.values(validate).filter((value) => {
      return !value.value
    })
    const requiredRequirementsNotMet = Object.values(validate).filter(
      (value) => {
        return value.required && !value.value
      }
    )

    // if we have met the length requirement, we consider that strong.
    if (password.length >= recommendedLength) {
      setStrength(Object.keys(validate).length - 1)
      setStrengthStatus(feedback[Object.keys(validate).length - 1])
      onChange &&
        onChange({
          name,
          isValid: true,
          value: password,
          message: 'OK',
        })
    } else if (password.length >= minimumLength) {
      setStrength(Object.keys(validate).length - 2)
      setStrengthStatus(feedback[Object.keys(validate).length - 2])
      onChange &&
        onChange({
          name,
          isValid: true,
          value: password,
          message: 'OK',
        })
    } else {
      setStrength(requirementsMet.length - 1)
      setStrengthStatus(
        `${feedback[requirementsMet.length - 1] || ''} ${
          requirementsNotMet[0] ? requirementsNotMet[0].msg : ''
        }`
      )
    }
    if (requiredRequirementsNotMet.length > 0) {
      // we haven't met the basic requirements
      // setStrengthStatus(requiredRequirementsNotMet[0].msg);
      onChange &&
        onChange({
          name,
          isValid: false,
          value: password,
          message: requiredRequirementsNotMet[0].msg,
        })
    } else {
      onChange &&
        onChange({
          name,
          isValid: true,
          value: password,
          message: 'OK',
        })
    }
  }, [validate])

  useEffect(() => {
    validatePassword(password)

    setVisible(!hideBars && password.length > 0)
  }, [password])

  const getColorForSegment = (index: number) => {
    if (!segmentColours) {
      return 'bg-jade'
    }
    if (strength === segmentColours.length - 1) {
      return segmentColours[segmentColours.length - 1]
    }
    return segmentColours && segmentColours[index]
  }

  useEffect(() => {
    setPassword(value || '')
  }, [value])

  const handleShowPasswordBtn = () => {
    setType((prev) => (prev === 'password' ? 'text' : 'password'))
  }

  return (
    <Fragment>
      <TextInputField
        id={id}
        label={label}
        name={name}
        required={required}
        type={type}
        value={password}
        disabled={disabled === undefined ? false : disabled}
        placeholder={placeholder || ''}
        handleChange={handleElementChange}
        reference={reference}
      >
        {visible && segments && segmentColours && segmentColours.length > 0 && (
          <Fragment>
            <div
              className={`absolute grid ${colClass} gap-1 rounded-b-lg h-1 bottom-0 overflow-hidden w-full z-20 pointer-events-none`}
              style={{
                bottom: '-2px',
              }}
            >
              {[...Array(segmentCount)].map((_e, idx) => (
                <div
                  key={`password-strength-${id}-${idx}`}
                  className={`h-1 ${
                    idx <= strength ? getColorForSegment(idx) : 'bg-gray-dark'
                  }`}
                ></div>
              ))}
            </div>
          </Fragment>
        )}
      </TextInputField>
      <span
        className="flex justify-around items-center relative"
        onClick={handleShowPasswordBtn}
      >
        {type === 'password' && (
          <EyeIcon
            className="absolute right-2 mr-10 -top-7 cursor-pointer"
            width={20}
          />
        )}
        {type === 'text' && (
          <EyeSlashIcon
            className="absolute right-2 mr-10 -top-7 cursor-pointer"
            width={20}
          />
        )}
      </span>

      {!hideBars && (
        <div
          className={`text-sm pt-1 italic  ${
            password.length > 0
              ? 'text-black/80 dark:text-white'
              : 'text-gray-dark'
          }`}
        >
          {strengthStatus ||
            `Minimum length of ${minimumLength} characters required.`}
        </div>
      )}
    </Fragment>
  )
}
