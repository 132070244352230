import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { FunctionComponent } from 'react'
import { OptimalRangeLite } from '../../lib/validators'
import { getNiceDate, months } from '../../lib/utils'

interface OptimalRangeTableProps {
  data: OptimalRangeLite[]
  onArchive: (optimalRange: OptimalRangeLite) => void
  onEdit: (optimalRange: OptimalRangeLite) => void
  onRowClick: (optimalRange: OptimalRangeLite) => void
}

const columnHelper = createColumnHelper<OptimalRangeLite>()

export const OptimalRangeTable: FunctionComponent<
  OptimalRangeTableProps
> = ({ data, onArchive, onEdit, onRowClick }) => {
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: () => 'Title',
      size: 1,
    }),
    columnHelper.accessor('totalBiomarkers', {
      cell: (info) => (
        <div className="flex items-center">
          {info.getValue()}
          <img
            src="https://cdn.prod.website-files.com/660b49a63d2aa9315e77cf0b/671b1b4171607bb0e0cd44ae_Biomarker%20icon.svg"
            alt="Biomarker icon"
            className="inline ml-2 w-4 h-4"
          />
        </div>
      ),
      header: () => 'Biomarkers',
      size: 1,
    }),
    columnHelper.accessor('updatedAt', {
      cell: (info) => {
        const d = new Date(info.getValue())
        return `${getNiceDate(d.getDate())} ${months[d.getMonth()]} ${d.getFullYear()}`
      },
      header: () => 'Last Modified',
      size: 1,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => 'Actions',
      cell: ({ row }) => {
        const optimalRange = row.original

        return (
          <div className="flex gap-4 justify-end text-right">
            <button
              className="hover:underline hover:text-bgt-primary"
              onClick={(e) => {
                e.stopPropagation()
                onEdit(optimalRange)
              }}
            >
              Edit
            </button>
            <button
              className="text-red-500 hover:underline"
              onClick={(e) => {
                e.stopPropagation()
                onArchive(optimalRange)
              }}
            >
              Archive
            </button>
          </div>
        )
      },
      size: 1,
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const gridTemplateColumns = columns
    .map((col) => `${col.size || 1}fr`)
    .join(' ')

  const tableHead =
    'bg-gray-light dark:shadow-lg dark:bg-dark-gray-light text-gray-semi-dark border-b border-gray-medium dark:border-dark-gray-light/50 font-semibold text-sm px-2 py-1 sm:p-3 sm:px-4 sm:block'

  return (
    <div className="overflow-x-auto p-4">
      <div className="shadow-md rounded-lg overflow-hidden min-w-[600px]">
        <div className="grid" style={{ gridTemplateColumns }}>
          {table.getHeaderGroups().map((headerGroup) =>
            headerGroup.headers.map((header, index) => (
              <div
                key={header.id}
                className={`${tableHead} ${index === columns.length - 1 ? 'text-right' : ''}`}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </div>
            ))
          )}
        </div>

        {table.getRowModel().rows.map((row, rowIndex) => (
          <div
            key={row.id}
            className={`grid py-1 sm:p-3 sm:px-4 text-md text-black dark:text-white cursor-pointer ${
              rowIndex % 2 === 1 ? 'bg-gray-light dark:bg-dark-gray-light' : ''
            } hover:bg-gray-100 dark:hover:bg-dark-gray-lighterer`}
            style={{ gridTemplateColumns }}
            onClick={() => onRowClick(row.original)}
          >
            {row.getVisibleCells().map((cell) => (
              <div key={cell.id} className={`px-4 py-2`}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
