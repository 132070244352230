export const fieldBgClass = 'bg-white dark:bg-dark-gray-light'
export const fieldTextColorClass = 'text-gray-900 dark:text-white'
export const ringClass = 'ring-1 ring-inset ring-gray-dark/30'
export const focusRingClass = 'focus:ring-2 focus:ring-gray-dark/30'
export const fieldBaseClass = 'outline-none border-0'
export const fieldShapeClass = 'rounded-md shadow-sm'
export const fieldFocusClass = `${ringClass} ${focusRingClass}`
export const fieldColorClass = `${fieldBgClass} ${fieldTextColorClass}`
export const fieldHoverTextColorClass = 'hover:text-black dark:hover:text-white'
export const fieldHoverBgColorClass =
  'hover:bg-gray-light dark:hover:bg-dark-gray-lighter'
export const fieldSelectedBgColorClass =
  'bg-gray-lighter dark:bg-dark-gray-lighterer'

export const selectClass = [
  'block w-full py-1.5 pl-3 sm:text-sm sm:leading-6',
  fieldBaseClass,
  fieldShapeClass,
  fieldFocusClass,
  fieldColorClass,
].join(' ')

export const inputClass = [
  'block w-full py-1.5 pl-3 text-sm sm:leading-6',
  fieldBaseClass,
  fieldShapeClass,
  fieldFocusClass,
  fieldColorClass,
].join(' ')

export const listBoxButtonClass = [
  'flex items-center justify-between w-full py-1.5 pl-3 pr-3 sm:text-sm sm:leading-6',
  fieldBaseClass,
  fieldShapeClass,
  fieldFocusClass,
  fieldColorClass,
].join(' ')
