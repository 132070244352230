import { Bars3Icon } from '@heroicons/react/24/outline'
import { FunctionComponent } from 'react'
import { HeaderProps } from '../lib/interfaces'
import ProfileHeader from './header/ProfileHeader'
import BgtBloodDropSmall from '../../public/img/bgt-drop-small.png?url'
// import HeaderSearchInput from "./form/HeaderSearchInput";
// import { BellIcon } from "@heroicons/react/24/solid";
import { Link } from 'react-router-dom'
import { BuyTestButton } from './form/button/BuyTestButton'
import { useOrganisationContext } from './context/OrganisationContext'
import { orgHomePrefix } from '../lib/routes'

import posthog from '../lib/posthog'
import { ThemeToggle } from './layout/ThemeToggle'
import { useCurrentPage } from '../lib/store'
import { getSizedImage, OrgImageTypes } from '../lib/image'
// import NotificationHeader from "./header/NotificationHeader";

const Header: FunctionComponent<HeaderProps> = ({ setSidebarOpen, theme }) => {
  const { org } = useOrganisationContext()

  const currentPage = useCurrentPage()
  const isAdminSection = currentPage.indexOf('admin') === 0
  return (
    <div
      className={`fixed w-full top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-medium shadow-sm dark:!bg-dark-gray-lighter dark:border-0 ${
        theme?.headerBarBackgroundColor || isAdminSection
          ? `!bg-[--header-bg-color] dark:!bg-[--header-bg-color]`
          : 'bg-white dark:bg-dark-gray-light dark:shadow-xl dark:border-0'
      } px-4 sm:gap-x-6 sm:px-6 lg:px-8`}
      style={
        theme?.headerBarBackgroundColor
          ? ({
              '--header-bg-color': theme.headerBarBackgroundColor,
            } as React.CSSProperties)
          : isAdminSection
            ? ({
                '--header-bg-color': '#EF4444',
              } as React.CSSProperties)
            : {}
      }
    >
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden !text-[--button-color] print:hidden"
        style={
          theme?.buttonColor
            ? ({
                '--button-color': theme.buttonColor,
              } as React.CSSProperties)
            : {}
        }
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      {/* Separator */}
      <div
        className="h-6 w-px bg-gray-900/10 lg:hidden print:hidden"
        aria-hidden="true"
      />
      {/* <div className="-m-2.5 w-4 text-center sm:w-8"> */}
      <div
        className={`-m-2.5 text-center sm:w-60 max-h-16 -mt-3 overflow-hidden ${
          org ? 'w-40 sm:w-60' : 'w-20 sm:w-40'
        }`}
      >
        <Link to={org ? `${orgHomePrefix}/${org.id}` : `/`}>
          {isAdminSection && (
            <div className="text-left font-bold text-white">
              BLOODY GOOD ADMIN AREA
            </div>
          )}
          {!isAdminSection && (
            <img
              className="max-h-16"
              src={
                theme && theme.logo
                  ? getSizedImage(OrgImageTypes.Banner, theme.logo)
                  : BgtBloodDropSmall
              }
              alt={`${org?.name || 'Bloody Good Tests'} Logo`}
            />
          )}
        </Link>
      </div>
      <div
        className="h-6 w-px bg-gray-900/10 lg:hidden print:hidden"
        aria-hidden="true"
      />

      <div className="flex flex-1 gap-x-2 self-stretch lg:gap-x-6">
        <form className="relative flex flex-1" action="#" method="GET">
          {/* <HeaderSearchInput
            name="search-field"
            placeholder="Search"
          ></HeaderSearchInput> */}
        </form>

        <div className="flex items-center gap-x-2 lg:gap-x-6 print:hidden">
          {/* <NotificationHeader></NotificationHeader> */}
          {!org && !theme && !isAdminSection && (
            <div className="text-xs font-semibold leading-6 text-indigo-200">
              <BuyTestButton theme={theme}></BuyTestButton>
            </div>
          )}
          {posthog.isFeatureEnabled('dark-mode-toggle') && (
            <ThemeToggle></ThemeToggle>
          )}
          <ProfileHeader />
        </div>
      </div>
    </div>
  )
}

export default Header
