import { useEffect } from 'react'
import { setDocumentTitle } from '../../../../../lib/utils'
import { SettingsTabProps } from '../Settings'

import { useOrganisation } from '../../../../../lib/store'

import { PayoutViewer } from '../../../../organisation/PayoutViewer'

export const SettingsTabRevenue: React.FC<SettingsTabProps> = () => {
  // const { theme } = useOrganisationContext()
  const org = useOrganisation()
  useEffect(() => {
    setDocumentTitle(`Revenue Payouts`, 'BRANDED')
  }, [])

  return (
    <PayoutViewer
      displayHelpInformation={true}
      organisationUuid={org!.uuid}
      organisationTheme={org!.theme || undefined}
      organisationName={org!.name}
      organisationId={org!.id}
    ></PayoutViewer>
  )
}
