export enum Errors {
  UnknownError = 'UnknownError',
  ServerError = 'ServerError',
  InvalidGender = 'InvalidGender',
  InvalidDoB = 'InvalidDoB',
  VerificationFailed = 'VerificationFailed',
  PasswordMismatch = 'PasswordMismatch',
}

export enum PaymentErrors {
  StripeFailure = 'StripeFailure',
  MissingUser = 'MissingUser',
  UnknownError = 'UnknownError',
  MissingProduct = 'MissingProduct',
  PurchaseFailed = 'PurchaseFailed',
}
