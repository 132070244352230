import { Transition } from '@headlessui/react'
import { resolveValue, Toaster, ToastIcon } from 'react-hot-toast'

export const TailwindToaster = () => {
  return (
    <Toaster position="top-center" containerClassName="mt-[70px]">
      {(t) => (
        <Transition
          appear
          as={'div'}
          show={t.visible}
          className="transform p-2 flex bg-white rounded-lg shadow-lg text-dark-gray-light dark:bg-dark-gray-light dark:text-white"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <ToastIcon toast={t} />
          <p className="px-2">{resolveValue(t.message, t)}</p>
        </Transition>
      )}
    </Toaster>
  )
}
