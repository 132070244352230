import { ReactNode } from "react";
import { Biomarker, BiomarkerRange } from "../../lib/validators";

// In future will support notes and ranges with labels
export interface BiomarkerResultOptimalRange {
  name: string
  icon: JSX.Element | undefined
  ranges: BiomarkerRange[]
  unit?: string | null
  isSourceProvider?: boolean
}

interface ReferenceSection {
  title: string;
  body: ReactNode | ReactNode[];
}
enum RefInteral {
  Low,
  Mid,
  High,
}

export const getReferenceRange = (
  item: Biomarker,
  excludeUnit?: boolean
): string => {
  if (item.refIntervalLow !== null && item.refIntervalHigh === null) {
    return `> ${item.refIntervalLow} ${
      item.unit && !excludeUnit ? item.unit : ""
    }`;
  } else if (item.refIntervalLow === null && item.refIntervalHigh !== null) {
    return `< ${item.refIntervalHigh} ${
      item.unit && !excludeUnit ? item.unit : ""
    }`;
  } else if (item.refIntervalLow !== null && item.refIntervalHigh !== null) {
    return `${item.refIntervalLow}-${item.refIntervalHigh} ${
      item.unit && !excludeUnit ? item.unit : ""
    }`;
  }
  return "";
};

const getBiomarkerReferencePosition = (biomarker: Biomarker): RefInteral => {
  if (biomarker.refIntervalLow === null && biomarker.refIntervalHigh !== null) {
    if (biomarker.value < biomarker.refIntervalHigh) {
      return RefInteral.Mid;
    } else {
      return RefInteral.High;
    }
  } else if (
    biomarker.refIntervalLow !== null &&
    biomarker.refIntervalHigh === null
  ) {
    if (biomarker.value > biomarker.refIntervalLow) {
      return RefInteral.Mid;
    } else {
      return RefInteral.Low;
    }
  } else if (
    biomarker.refIntervalLow !== null &&
    biomarker.refIntervalHigh !== null
  ) {
    if (
      biomarker.value >= biomarker.refIntervalLow &&
      biomarker.value <= biomarker.refIntervalLow
    ) {
      return RefInteral.Mid;
    } else if (biomarker.value < biomarker.refIntervalLow) {
      return RefInteral.Low;
    } else if (biomarker.value > biomarker.refIntervalHigh) {
      return RefInteral.High;
    }
  }
  return RefInteral.Mid;
};

export const getTextForSections = (
  biomarker: Biomarker
): ReferenceSection[] => {
  const ref = getBiomarkerReferencePosition(biomarker);

  const users: ReferenceSection = {
    title: "What does my result mean?",
    body: "",
  };
  const low: ReferenceSection = {
    title: "What does a low result mean?",
    body: biomarker.refIntervalLowNotes,
  };
  const mid: ReferenceSection = {
    title: "What does a good result mean?",
    body: biomarker.refIntervalMidNotes,
  };
  const high: ReferenceSection = {
    title: "What does a high result mean?",
    body: biomarker.refIntervalHighNotes,
  };
  if (ref === RefInteral.Mid) {
    users.body = biomarker.refIntervalMidNotes;
    return [users, high, low];
  } else if (ref === RefInteral.Low) {
    users.body = biomarker.refIntervalLowNotes;
    return [users, mid, high];
  } else if (ref === RefInteral.High) {
    users.body = biomarker.refIntervalHighNotes;
    return [users, mid, low];
  }

  return [];
};

export const getTextForUsersReference = (biomarker: Biomarker): string => {
  const ref = getBiomarkerReferencePosition(biomarker);

  if (ref === RefInteral.Mid) {
    return biomarker.refIntervalMidNotes || "";
  } else if (ref === RefInteral.Low) {
    return biomarker.refIntervalLowNotes || "";
  }
  return biomarker.refIntervalHighNotes || "";
};
