import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import { z } from 'zod'
import { authFetch } from '../providers/AuthProvider'
import { biomarkerItemFields } from './admin'
import { store } from './store'
import {
  BiomarkerItem,
  BiomarkerListSchema,
  OptimalRange,
  OptimalRangeLite,
  OptimalRangeLiteSchema,
  OptimalRangeSchema,
} from './validators'

export const getBiomarkers = async (): Promise<BiomarkerItem[]> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          biomarkers {
            ${biomarkerItemFields}
          }
        }`,
    }),
  })
  const data = result.data
  const parsedData = BiomarkerListSchema.parse(data.data.biomarkers)
  return parsedData
}

export function useBiomarkers(): readonly BiomarkerItem[] | null {
  const snapshot = useSnapshot(store)

  useEffect(() => {
    const fetchBiomarkers = async () => {
      try {
        const biomarkers = await getBiomarkers()
        store.biomarkers = biomarkers
      } catch (error) {
        console.error('Failed to fetch biomarkers:', error)
      }
    }

    if (snapshot.biomarkers === null) {
      fetchBiomarkers()
    }
  }, [snapshot.biomarkers])

  return snapshot.biomarkers
}

export const getOptimalRange = async (uuid: string): Promise<OptimalRange> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        query OptimalRange($uuid: UUID!) {
          optimalRange(uuid: $uuid) {
            uuid
            name
            organisationUuid
            criteria {
              gender
            }
            specs {
              biomarkerId
              unit
              ranges {
                low
                high
              }
            }
            totalBiomarkers
            createdAt
            updatedAt
          }
        }
      `,
      variables: {
        uuid,
      },
    }),
  })

  const data = result.data
  const parsedData = OptimalRangeSchema.parse(data.data.optimalRange)
  return parsedData
}

interface OptimalRangeFilterInput {
  organisationUuid: string
}

export const getOptimalRangeLiteList = async (
  filter: OptimalRangeFilterInput
): Promise<OptimalRangeLite[]> => {
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        query OptimalRangesLite($filter: OptimalRangeFilterInput!) {
          optimalRangesLite(filter: $filter) {
            uuid
            organisationUuid
            name
            totalBiomarkers
            createdAt
            updatedAt
          }
        }
      `,
      variables: {
        filter,
      },
    }),
  })

  const data = result.data
  const parsedData = OptimalRangeLiteSchema.array().parse(
    data.data.optimalRangesLite
  )
  return parsedData
}

export function useOptimalRange(uuid: string | undefined) {
  const [optimalRange, setOptimalRange] = useState<OptimalRange | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!uuid) return

    const fetchOptimalRange = async () => {
      setLoading(true)
      setError(null)
      try {
        const fetchedOptimalRange = await getOptimalRange(uuid)
        setOptimalRange(fetchedOptimalRange)
      } catch (error) {
        setError('Failed to fetch optimal range')
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchOptimalRange()
  }, [uuid])

  return { optimalRange, loading, error }
}

const UpsertOptimalRangeInputSchema = OptimalRangeSchema.extend({
  uuid: z.string().uuid().optional(), // Optional to allow for create or edit
}).omit({
  createdAt: true,
  updatedAt: true,
  totalBiomarkers: true,
})

export type UpsertOptimalRangeInput = z.infer<
  typeof UpsertOptimalRangeInputSchema
>

export const upsertOptimalRange = async (
  input: UpsertOptimalRangeInput
): Promise<OptimalRange> => {
  const parsedInput = UpsertOptimalRangeInputSchema.parse(input)

  // Perform the GraphQL mutation
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        mutation UpsertOptimalRange($input: UpsertOptimalRangeInput!) {
          upsertOptimalRange(input: $input) {
            uuid
            name
            organisationUuid
            criteria {
              gender
            }
            specs {
              biomarkerId
              unit
              ranges {
                low
                high
              }
            }
            totalBiomarkers
            createdAt
            updatedAt
          }
        }
      `,
      variables: {
        input: parsedInput,
      },
    }),
  })

  // Parse and validate the response
  const data = result.data
  if (!data?.data?.upsertOptimalRange) {
    const errorForUpsert = data?.errors?.find((error: { path: string[] }) =>
      error.path?.includes('upsertOptimalRange')
    )
    const errorMessage =
      errorForUpsert?.message || 'Failed to upsert optimal range'
    throw new Error(errorMessage)
  }

  const parsedResponse = OptimalRangeSchema.parse(data.data.upsertOptimalRange)
  return parsedResponse
}

export const archiveOptimalRange = async (uuid: string): Promise<boolean> => {
  const parsedUuid = z.string().uuid().parse(uuid)

  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `
        mutation ArchiveOptimalRange($uuid: UUID!) {
          archiveOptimalRange(uuid: $uuid)
        }
      `,
      variables: {
        uuid: parsedUuid,
      },
    }),
  })

  const data = result.data
  if (data.errors) {
    throw new Error(
      data.errors[0]?.message || 'Failed to archive optimal range'
    )
  }

  const isArchived = z.boolean().parse(data.data.archiveOptimalRange)
  return isArchived
}
