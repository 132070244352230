import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { BeakerIcon } from '@heroicons/react/24/outline'
import { usePathologyProviders } from '../../lib/admin'
import { OptimalRangeOption } from '../pages/result-tabs/ResultTabMenuBar'
import { BasicOrganisation, ResultReferral } from '../../lib/validators'
import { fetchBranding } from '../../lib/utils'
import { BiomarkerResultOptimalRange } from '../biomarker/utils'
import cyte4Logo from '../../../public/img/map/4cyte-logo.jpg?url'
import ACLLogo from '../../../public/img/map/acl-logo.png?url'
import wiseLogo from '../../../public/img/map/wise-logo.png?url'

function getProviderLogoSrc(providerId: string): undefined | string {
  switch (providerId) {
    case '4cyte':
      return cyte4Logo
    case 'wise':
      return wiseLogo
    case 'acl':
      return ACLLogo
    default:
      return undefined
  }
}

export function useOptimalRangeOptions(result: ResultReferral) {
  const [optimalRangeOptions, setOptimalRangeOptions] = useState<OptimalRangeOption[]>([]);
  const [loading, setLoading] = useState(true);

  // Memoize the branding fetch cache with proper typing
  const brandingCache = useRef<Map<string, BasicOrganisation | null>>(new Map());

  const fetchOptimalRanges = useCallback(async () => {
    setLoading(true);

    try {
      const rangeOptions: OptimalRangeOption[] = [];

      if (result.optimalRanges) {
        for (const range of result.optimalRanges) {
          if (range.organisationUuid) {
            // Check cache first
            let branding = brandingCache.current.get(range.organisationUuid);

            // If not cached, fetch branding and cache it
            if (branding === undefined) {
              branding = await fetchBranding({ organisationUuid: range.organisationUuid });
              brandingCache.current.set(range.organisationUuid, branding);
            }

            rangeOptions.push({
              key: range.uuid,
              name: branding ? `${branding.name} - ${range.name}` : range.name,
              icon: branding?.theme?.logoSquare ? (
                <img src={branding.theme.logoSquare} alt={branding.name} />
              ) : undefined,
            });
          }
        }
      }

      setOptimalRangeOptions(rangeOptions);
    } catch (error) {
      console.error('Error fetching optimal ranges:', error);
    } finally {
      setLoading(false);
    }
  }, [result.optimalRanges]);

  useEffect(() => {
    fetchOptimalRanges();
  }, [fetchOptimalRanges]);

  return { options: optimalRangeOptions, loading };
}

export function usePathologyProviderOptions(result: ResultReferral) {
  const [pathologyProviders, pathologyProvidersLoading] =
    usePathologyProviders()

  const options = useMemo(() => {
    if (pathologyProvidersLoading || pathologyProviders.length === 0) return []

    return pathologyProviders
      .filter((provider) => provider.id === result.pathologyProviderId)
      .map((provider) => {
        const logoSrc = getProviderLogoSrc(provider.id)
        return {
          key: provider.id,
          name: provider.name,
          icon: logoSrc ? (
            <img src={logoSrc} alt={provider.name} />
          ) : (
            <BeakerIcon />
          ),
        }
      })
  }, [
    pathologyProviders,
    pathologyProvidersLoading,
    result.pathologyProviderId,
  ])

  return { options, loading: pathologyProvidersLoading }
}

interface UseSelectedRangesProps {
  optimalRangeOptions: OptimalRangeOption[]
  selectedRangeKeys: string[]
  result: ResultReferral
}

export function useSelectedRanges({
  optimalRangeOptions,
  selectedRangeKeys,
  result,
}: UseSelectedRangesProps) {
  // Filter the selected ranges based on selected keys
  const selectedOptimalRanges = useMemo(() => {
    return optimalRangeOptions.filter((range) =>
      selectedRangeKeys.includes(range.key)
    )
  }, [optimalRangeOptions, selectedRangeKeys])

  // Map the selected ranges by biomarker ID
  const optimalRangesById = useMemo(() => {
    const lookup: Record<string, BiomarkerResultOptimalRange[]> = {}

    selectedOptimalRanges.forEach((option) => {
      if (option.key === result.pathologyProviderId) {
        // We don't actually have explicit optimal ranges for the pathology provider.
        // We will create them from the biomarkers on the result.
        result.biomarkers?.forEach((biomarker) => {
          if (!lookup[biomarker.id]) {
            lookup[biomarker.id] = []
          }
          lookup[biomarker.id].push({
            name: option.name,
            icon: option.icon,
            ranges: [
              {
                low: biomarker.refIntervalLow,
                high: biomarker.refIntervalHigh,
              },
            ],
            unit: biomarker.unit,
            isSourceProvider: true,
          })
        })
      } else {
        // Add the explicit optimal ranges
        const range = result.optimalRanges?.find((r) => r.uuid === option.key)

        if (!range) return

        range.specs.forEach((spec) => {
          if (!lookup[spec.biomarkerId]) {
            lookup[spec.biomarkerId] = []
          }

          lookup[spec.biomarkerId].push({
            name: option.name,
            icon: option.icon,
            ranges: spec.ranges,
            unit: spec.unit,
            isSourceProvider: false,
          })
        })
      }
    })

    return lookup
  }, [
    selectedOptimalRanges,
    result.biomarkers,
    result.optimalRanges,
    result.pathologyProviderId,
  ])

  // Return the list of all range options for use in the UI
  const allRangeOptions = useMemo(
    () => optimalRangeOptions,
    [optimalRangeOptions]
  )

  return {
    selectedOptimalRanges,
    optimalRangesById,
    allRangeOptions,
  }
}
