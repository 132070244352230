import { Fragment, useCallback, useEffect, useState } from 'react'
import FullPageLoader from '../../layout/FullPageLoader'
import toast from 'react-hot-toast'
import {
  createProductCheckout,
  getStripeCheckoutSession,
} from '../../../lib/payment'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { stripePromise } from '../../../lib/billing'
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js'
import RoundedSection from '../../layout/RoundedSection'
import { ProductCheckoutSession } from '../../../lib/validators'
import { FaCircleCheck } from 'react-icons/fa6'

export function StandalonePurchasePage() {
  const [loading, setLoading] = useState(false)
  const [clientSecret, setClientSecret] = useState<string | undefined>()
  const [sessionId, setSessionId] = useState<string | undefined | null>()
  const [session, setSession] = useState<ProductCheckoutSession>()
  const [initComplete, setInitComplete] = useState(false)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    console.log('loaded the standalone purchase page')
  }, [])

  useEffect(() => {
    console.log('we loaded the page for standalone checkout')
    setSessionId(searchParams.get('session_id') || null)
  }, [location, searchParams])

  const getSession = useCallback(async () => {
    if (!sessionId) {
      throw new Error('Cant do much without a session id.')
    }
    const checkoutSession = await getStripeCheckoutSession(sessionId)
    setLoading(false)
    if (checkoutSession.status === 'complete') {
      setSession(checkoutSession)
      setInitComplete(true)
    }
    // if its not complete, revert the state
    else {
      toast.error('Purchase failed, please try again.', {
        duration: 3000,
      })
      setSession(undefined)
      setSessionId(null)
      setInitComplete(false)
    }
  }, [sessionId])

  const beginCheckout = useCallback(async () => {
    const checkoutResponse = await createProductCheckout(
      params.id!,
      window.location.href
    )
    if (checkoutResponse.clientSecret) {
      setClientSecret(checkoutResponse.clientSecret)
    }
    setInitComplete(true)
    setLoading(false)
  }, [params.id])

  useEffect(() => {
    if (!params.id) {
      setLoading(false)
      navigate('/')

      return
    }
  }, [params.id, navigate])

  useEffect(() => {
    if (!loading && sessionId !== undefined && !initComplete) {
      setLoading(true)
      if (sessionId) {
        getSession()
      } else {
        beginCheckout()
      }
    }
  }, [sessionId, loading, getSession, beginCheckout, initComplete])

  return (
    <Fragment>
      {loading && <FullPageLoader></FullPageLoader>}
      {!loading && (
        <div
          className={`min-h-full flex items-center justify-center ${session ? 'h-[100dvh] content-center' : 'py-6 px-4 sm:px-6 lg:px-8'}`}
        >
          <div
            className={`w-full ${session ? 'md:max-w-screen-md' : 'lg:max-w-screen-xl xl:max-w-screen-xl'} space-y-8`}
          >
            <RoundedSection
              size="xl"
              className={`${session ? 'flex content-center align-middle justify-center' : ''}`}
            >
              {sessionId && session && (
                <div className="p-10 text-center flex flex-col  content-center ">
                  <div className="justify-center mr-auto ml-auto pb-5">
                    <FaCircleCheck size={70} color="#00edc0" />
                  </div>
                  <h2 className="text-2xl font-bold">Purchase completed</h2>
                  <div className="mt-2">
                    Please check your inbox for an email from Bloody Good Tests
                  </div>
                </div>
              )}
              {!sessionId && clientSecret && (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{
                    clientSecret: clientSecret,
                  }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              )}
            </RoundedSection>
          </div>
        </div>
      )}
    </Fragment>
  )
}
