import { Fragment, useEffect, useState } from 'react'
import { ResultReferral } from '../../../lib/validators'
import { ResultsTabProps } from '../../../lib/interfaces'
import { PdfViewer } from '../../ui/PdfViewer'

export const ResultTabDocument: React.FC<ResultsTabProps> = ({
  resultReferral,
  files,
}) => {
  const [result, setResult] = useState<ResultReferral>()

  useEffect(() => {
    setResult(resultReferral)
  }, [resultReferral])

  return (
    <Fragment>
      {!result && <div>Loading...</div>}
      {result && (
        <Fragment>
          {files &&
            files.map((url) => (
              <PdfViewer url={url} height="1000px"></PdfViewer>
            ))}
        </Fragment>
      )}
    </Fragment>
  )
}
