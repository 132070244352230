import { Fragment, useEffect, useState } from 'react'

interface PdfViewerProps {
  url: string
  height?: string
}

export function PdfViewer({ url, height = '1000px' }: PdfViewerProps) {
  const [resultBlob, setResultBlob] = useState<Blob>()

  async function fetchBlob(url: string): Promise<Blob> {
    // Use Promise.all to fetch all URLs in parallel
    const blobResponse = await fetch(url)

    if (!blobResponse.ok) {
      throw new Error(`Failed to fetch ${url}: ${blobResponse.statusText}`)
    }

    return await blobResponse.blob() // Convert the response to a Blob
  }

  useEffect(() => {
    if (resultBlob === undefined) {
      fetchBlob(url).then((blobsData) => {
        setResultBlob(blobsData)
      })
    }
  }, [url, resultBlob])

  return (
    <Fragment>
      {resultBlob && (
        <embed
          key={`blob-${url}`}
          src={URL.createObjectURL(resultBlob)}
          type="application/pdf"
          width="100%"
          height={height}
          style={{ height: height, width: '100%' }}
          className="mb-6 touch-auto"
        />
      )}
    </Fragment>
  )
}
