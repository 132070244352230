import toast from 'react-hot-toast'
import { ItemType, MenuItem } from '../../../../header/ProfileHeader'
import DropdownMenu from '../../../../menu/DropdownMenu'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import {
  // ArchiveBoxIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  PaperAirplaneIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline'
import { useState } from 'react'
import { SendPackageModal } from '../modals/SendPackageModal'
import { OrganisationWithBranding } from '../../../../context/OrganisationContext'
import { stripeOptions, stripePromise } from '../../../../../lib/billing'
import { ThemeInterface } from '../../../../../lib/interfaces'
import { Elements } from '@stripe/react-stripe-js'
import { BuyPackageModal } from '../modals/BuyPackageModal'
import { getBundleUrl } from '../../../../../lib/routes'

interface BundleActionsDropdownProps {
  bundleName: string
  bundleId: string
  disabled?: boolean
  org: OrganisationWithBranding
  theme?: ThemeInterface
  buttonLabel?: string
  editControls?: boolean
}
export function BundleActionsDropdown({
  bundleId,
  bundleName,
  disabled,
  org,
  theme,
  buttonLabel = 'Actions',
  editControls = false,
}: BundleActionsDropdownProps) {
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [openBuyModal, setOpenBuyModal] = useState(false)

  const getActionMenuItems = (): MenuItem[] => {
    const enableEditControls = editControls
      ? [
          {
            type: ItemType.GroupTitle,
            name: 'Edit',
            current: false,
          },
          {
            current: false,
            name: 'Edit Bundle',
            type: ItemType.Item,
            icon: PencilSquareIcon,
            className: '',
            href: getBundleUrl(org!.id, bundleId),
          },
          {
            type: ItemType.Divider,
            name: 'Divider',
            current: false,
          },
        ]
      : []
    return [
      ...enableEditControls,
      {
        type: ItemType.GroupTitle,
        name: 'Share',
        current: false,
      },
      {
        current: false,
        name: 'Send to...',
        type: ItemType.Item,
        icon: PaperAirplaneIcon,
        className: '',
        onClick: () => {
          setOpenInviteModal(true)
        },
      },
      {
        current: false,
        name: 'Buy for...',
        type: ItemType.Item,
        icon: CreditCardIcon,
        className: '',
        disabled: false,
        onClick: () => {
          setOpenBuyModal(true)
        },
      },
      {
        type: ItemType.Divider,
        name: 'Diviver',
        current: false,
      },
      {
        current: false,
        name: 'Copy Bundle URL',
        type: ItemType.Item,
        icon: ClipboardDocumentListIcon,
        className: '',
        onClick: () => {
          navigator.clipboard.writeText(
            `${import.meta.env.VITE_APP_URL}/buy/${bundleId}`
          )
          toast.success(`${bundleName} URL copied`, {
            duration: 3000,
          })
        },
      },
    ]
  }

  return (
    <div>
      <DropdownMenu
        menuItems={getActionMenuItems()}
        disabled={disabled}
        disabledInfo={
          <span className="absolute right-0 w-64 text-sm mt-2 text-right text-blood">
            <ExclamationTriangleIcon
              width={16}
              className="inline-block"
            ></ExclamationTriangleIcon>
            Please save your changes first
          </span>
        }
      >
        {buttonLabel}
        <ChevronDownIcon className="size-4 fill-white/60 inline" />
      </DropdownMenu>
      <SendPackageModal
        org={org}
        productId={bundleId}
        productName={bundleName}
        theme={theme || undefined}
        show={openInviteModal}
        setShow={setOpenInviteModal}
        dismissOnSkip={true}
        dismissOnSend={true}
      ></SendPackageModal>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <BuyPackageModal
          org={org}
          productId={bundleId}
          productName={bundleName}
          theme={theme || undefined}
          show={openBuyModal}
          setShow={setOpenBuyModal}
          dismissOnSkip={true}
          dismissOnSend={true}
        ></BuyPackageModal>
      </Elements>
    </div>
  )
}
